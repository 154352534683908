import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import cookie, { select } from 'react-cookies';
import Breadcrumbs from '../components/Breadcrumbs';
import FormErrors from '../components/FormErrors';
import { TailSpin	 } from  'react-loader-spinner'
import Input from '../components/input/Input';
import SingleInput from '../components/input/SingleInput';
import { element, object } from 'prop-types';
import moment from 'moment';
import Spacer from '../components/Spacer';
import SectionDivider from '../components/compositions/OutcomePagesCompositions/SectionDivider.tsx';
import ReviewSectionGenerator from '../components/compositions/OutcomePagesCompositions/ReviewSectionGenerator.tsx';
import CustomDropDown from '../components/compositions/CustomDropDown';
import { logout } from '../services/AccountServices';
import { GetIndicator, GetTooltipText, OutcomeMeasureTypes, HasImprovedScoreBand, GetNormalizedValue ,UsePercentage, LowerIsBetter, isRomType } from '../utility/OutcomeResults.jsx';
import { Chart as ChartJS, defaults } from "chart.js/auto";
import {  Line } from "react-chartjs-2";
import html2canvas from "html2canvas";
import {font1} from "../fonts/Inter-normal.js";
import {font2} from "../fonts/Inter-bold.js";



const PatientOutcomeVerificationPage = ({ history }) => {

    const custom_canvas_background_color = {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart, args, options) => {
          const {
            ctx,
            chartArea: { top, right, bottom, left, width, height },
            scales: { x, y },
          } = chart;
          ctx.save();
          ctx.fillStyle='rgba(0,255,0, 0.2)';
          ctx.fillRect(left,y.getPixelForValue(100),width,y.getPixelForValue(75)-y.getPixelForValue(100));
          ctx.fillStyle='rgba(173,255,47,0.3)'
          
          ctx.fillStyle='rgba(255, 165, 0, 0.2)'
          ctx.fillRect(left,y.getPixelForValue(75),width,y.getPixelForValue(50)-y.getPixelForValue(75));
          ctx.fillStyle='rgba(255, 165, 0, 0.2)'
          ctx.fillRect(left,y.getPixelForValue(50),width,y.getPixelForValue(25)-y.getPixelForValue(50));
          ctx.fillStyle='rgba(255, 0, 0, 0.2)'
          ctx.fillRect(left,y.getPixelForValue(25),width,y.getPixelForValue(0)-y.getPixelForValue(25));
    
    
          ctx.restore();
        },
      };

    const payerEnums = Object.freeze({
        KarunaHome: 0,
        Reclaim: 1,
        VA: 2,
       
    });

    const NotificationTypes = {
        NewSymptoms:"NewSymptoms",
        PsychotherapyReferral: "PsychotherapyReferral",
        MedicationReEvaluation: "MedicationReEvaluation",
        NewInjury: "NewInjury",
       
    }



    const location = window.location;

    const notifications=[ {
        id: 0,
        name: "Psychotherapy Referral",
        value: NotificationTypes.PsychotherapyReferral
      },
      {
        id: 1,
        name: "Medication Re-Evaluation",
        value: NotificationTypes.MedicationReEvaluation
      },
      {
        id: 2,
        name: "New Injury",
        value: NotificationTypes.NewInjury
      },
      {
        id: 3,
        name: "New Symptoms",
        value: NotificationTypes.NewSymptoms
      },
    ]

    const templateWeekOptions =[{
        id: 1,
        name: "Week 1",
        value: "1"
      },
      {
        id: 2,
        name: "Week 2",
        value: "2"
      },
      {
        id: 3,
        name: "Week 3",
        value: "3"
      },
      {
        id: 4,
        name: "Week 4",
        value: "4"
      },
      {
        id: 5,
        name: "Week 5",
        value: "5"
      },
      {
        id: 6,
        name: "Week 6",
        value: "6"
      },
      {
        id: 7,
        name: "Week 7",
        value: "7"
      },
      {
        id: 8,
        name: "Week 8",
        value: "8"
      },
      {
        id: 9,
        name: "Week 9",
        value: "9"
      },
      {
        id: 10,
        name: "Week 10",
        value: "10"
      },
      {
        id: 11,
        name: "Week 11",
        value: "11"
      },
      {
        id: 12,
        name: "Week 12",
        value: "12"
      }]

      const colors=[
        '#17B26A',
        '#F79009',
        '#488CE6',
        '#67E3F9',
        '#EE46BC',
    ]
    
    var reportId=useParams().outcomeId;
    const [headerInfo, setHeaderInfo] =useState(null);

    const [isKaruna,setIsKaruna] = useState(true)
    const [manual, SetManual] = useState(false);
    const [LoadingMessage, SetLoadingMessage] = useState("loading, Retrieving Patient Outcome Report...");

    const [data, setData] = useState(null);
    const [loadedVerification, SetLoadedVerification] = useState(false);
    const [generate, setGenerate]= useState(false);
    const [save, setSave]= useState(false)
    const [refresh, setRefresh]= useState(false)

    const [upload, setUpload]= useState(false)
    const [idleTime, setIdleTime] = useState(0);
    const role = cookie.load('role') ? cookie.load('role') : 'None';
    const user = cookie.load('user') ? cookie.load('user') : null;
    const token = cookie.load('token') ? cookie.load('token') : null;


	const [patientName, setPatientName] = useState(null);
    const [patientNameCheck, setPatientNameCheck] = useState(null);
    const [patientId, setPatientId] = useState(null);


    const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [loadedProfile, setLoadedProfile] = useState(false);
    const [coach, setCoach] = useState(null);
    const [physician, setPhysician] = useState('');

    const [currentWeek, setCurrentWeek] = useState(0);
    const [selectedWeek, setSelectedWeek] = useState(0);
    const [changedWeek, setChangedWeek] = useState(0);
    const [DOB,setDOB] = useState("");
    const [program,setProgram] = useState("");

    const [hasPlan, setHasPlan] = useState(true);

    const [templateWeeks,setTemplateWeeks] = useState("1");
    const [templateNotification,setTemplateNotification] = useState(NotificationTypes.PsychotherapyReferral);


    const [weeks, setWeeks] = useState([]);
    const [oversight, setOversight] = useState(false);
    const [plan, setPlan] = useState("");
    const [assessment, setAssessment] = useState("");
    const [summary, setSummary] = useState("");
    const [HistoryInjury, setHistoryInjury] = useState("");

    const [summaryOriginal, setSummaryOriginal] = useState("");
    const [HistoryInjuryOriginal, setHistoryInjuryOriginal] = useState("");


    //#region checkboxes


    const [revertSummary, setRevertSummary] = useState(false); 
    const [revertGoal, setRevertGoal] = useState(false); 
    const [revertDiscovery ,setRevertDiscovery] = useState(false); 
    const [revertBehavioral, setRevertBehavioral] = useState(false); 
    const [revertSocial, setRevertSocial] = useState(false); 
    const [revertPhysical, setRevertPhysical] = useState(false); 
    const [revertHistoryInjury, setRevertHistoryInjury] = useState(false); 


    const [goalProgress, setGoalProgress] = useState(false); 
    const [engagmentLevel, setEngagementLevel] = useState(false); 

    const [sessionsAttended, setSessionsAttended] = useState(false); 
    const [uniqueDays, setUniqueDays] =useState(false);  
    const [averageTime, setAverageTime] = useState(false); 

    const [leftFlexion, setLeftFlexion] = useState(false); 
    const [leftScaption, setLeftScaption] = useState(false); 
    const [leftAbduction, setLeftAbduction] = useState(false); 
    const [rightFlexion, setRightFlexion] = useState(false); 
    const [rightScaption, setRightScaption] =useState(false); 
    const [rightAbduction, setRightAbduction] = useState(false); 
    const [lowerFlexion, setLowerFlexion] = useState(false); 

    const [leftFlexionCheck, setLeftFlexionCheck] = useState(false);
    const [leftScaptionCheck, setLeftScaptionCheck] = useState(false);
    const [leftAbductionCheck, setLeftAbductionCheck] = useState(false);
    const [rightFlexionCheck, setRightFlexionCheck] = useState(false);
    const [rightScaptionCheck, setRightScaptionCheck] = useState(false);
    const [rightAbductionCheck, setRightAbductionCheck] = useState(false);
    const [lowerFlexionCheck, setLowerFlexionCheck] = useState(false);
    const [useCheck, setUseCheck] = useState(null);

    const [pain, setPain] = useState(false); 
    const [mood, setMood] = useState(false); 
    const [activity, setActivity] = useState(false); 
    const [sleep, setSleep] = useState(false); 
    const [confidence, setConfidence] =useState(false); 

    const [returnToWork, setReturnToWork] = useState(false); 
    const [medReduction, setMedReduction] = useState(false); 
    const [oswestry, setOwestry] = useState(false); 
    const [Dash, setDash] = useState(false); 
    const [PSEQ, setPSEQ] = useState(false); 
    const [PCS, setPCS] = useState(false); 
    const [UEFI, setUEFI] = useState(false);
    const [FABQTotal, setFABQTotal] = useState(false);
    const [FABQActivity, setFABQActivity] = useState(false);
    const [FABQWork, setFABQWork] = useState(false);
    const [PHQ9, setPHQ9] = useState(false);
    const [PCSS, setPCSS] = useState(false);
    const [WHODAS, setWHODAS] = useState(false);
    const [GAD7, setGAD7] = useState(false);
    const [NDI, setNDI] = useState(false);
    const [KarunaRating, setKarunaRating] = useState(false);


    const [physical, setPhysical,] = useState(true); 
    const [behavioral, setBehavioral] = useState(true); 
    const [social, setSocial] = useState(true); 
    const [continuedNeed, setContinuedNeed] = useState(true); 

    const [continueProgram, setContinueProgram] = useState(false); 
    const [notification, setNotification] = useState(false); 
    const [extension, setExtension] = useState(false); 
    const [planEnd, setPlanEnd] = useState(false); 
    const [claimNumber,setClaimNumber] =useState('');
    const [DOI,setDOI] =useState('');
    const [preparer,setPreparer] =useState('');
    const [prepareDate,setPrepareDate] =useState('');




    //#endregion checkboxes

    //#region raw data
    const [rawGoalProgressData, setRawGoalProgressData] = useState(null); //put in
    const [rawEngagmentLevelData, setRawEngagementLevelData] = useState(null); //put in


    const [RawSessionsAttendedData, setRawSessionsAttendedData] = useState(null); //in
    const [RawUniqueDaysData, setRawUniqueDaysData] = useState(null); //in
    const [RawAverageTimeData, setRawAverageTimeData] = useState(null); //in
    //#endregion raw data

     //#region formatted data
     
    const [physicalText, setPhysicalText] = useState(""); 
    const [behavioralText, setBehavioralText] = useState(""); 
    const [socialText, setSocialText] = useState(""); 
    const [continuedNeedText, setContinuedNeedText] = useState(""); 

    const [physicalTextOriginal, setPhysicalTextOriginal] = useState(""); 
    const [behavioralTextOriginal, setBehavioralTextOriginal] = useState(""); 
    const [socialTextOriginal, setSocialTextOriginal] = useState(""); 


     const [returnToWorkData, setReturnToWorkData] = useState(null); //in
     const [medReductionData, setMedReductionData] = useState(null); //in

     const [leftFlexionData, setLeftFlexionData] = useState([]);
    const [leftScaptionData, setLeftScaptionData] = useState([]);
    const [leftAbductionData, setLeftAbductionData] = useState([]);
    const [rightFlexionData, setRightFlexionData] = useState([]);
    const [rightScaptionData, setRightScaptionData] = useState([]);
    const [rightAbductionData, setRightAbductionData] = useState([]);
    const [lowerFlexionData, setLowerFlexionData] = useState([]);


    const [painData, setPainData] = useState([]);
    const [moodData, setMoodData] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [sleepData, setSleepData] = useState([]);
    const [confidenceData, setConfidenceData] = useState([]);

    const [PSEQData, setPSEQData] = useState([]);
    const [PCSData, setPCSData] = useState([]);
    const [oswestryData, setOwestryData] = useState([]);
    const [DashData, setDashData] = useState([]);
    const [UEFIData, setUEFIData] = useState([]);
    const [KarunaRatingData, setKarunaRatingData] = useState([]);

    const [FABQTotalData, setFABQTotalData] = useState([]);
    const [FABQActivityData, setFABQActivityData] = useState([]);
    const [FABQWorkData, setFABQWorkData] = useState([]);
    const [PHQ9Data, setPHQ9Data] = useState([]);
    const [PCSSData, setPCSSData] = useState([]);
    const [WHODASData, setWHODASData] = useState([]);
    const [GAD7Data, setGAD7Data] = useState([]);
    const [NDIData, setNDIData] = useState([]);
    //#endregion formatted data

    const [trySave, setTrySave] = useState(false);
    const [tryPreview, setTryPreview] = useState(false);

    //#region improvment
    const [leftFlexionImp, setLeftFlexionImp] = useState([]);
    const [leftScaptionImp, setLeftScaptionImp] = useState([]);
    const [leftAbductionImp, setLeftAbductionImp] = useState([]);
    const [rightFlexionImp, setRightFlexionImp] = useState([]);
    const [rightScaptionImp, setRightScaptionImp] = useState([]);
    const [rightAbductionImp, setRightAbductionImp] = useState([]);
    const [lowerFlexionImp, setLowerFlexionImp] = useState([]);

    const [painImp, setPainImp] = useState(null);
    const [moodImp, setMoodImp] = useState(null);
    const [activityImp, setActivityImp] = useState(null);
    const [sleepImp, setSleepImp] = useState(null);
    const [confidenceImp, setConfidenceImp] = useState(null);

    const [medImp, setMedImp] = useState(null);


    const [PSEQImp, setPSEQImp] = useState(null);
    const [PCSImp, setPCSImp] = useState(null);
    const [oswestryImp, setOwestryImp] = useState(null);
    const [DashImp, setDashImp] = useState(null);
    const [UEFIImp, setUEFIImp] = useState(null);
    const [KarunaRatingImp, setKarunaRatingImp] = useState(null);

    const [FABQTotalImp, setFABQTotalImp] = useState(null);
    const [FABQActivityImp, setFABQActivityImp] = useState(null);
    const [FABQWorkImp, setFABQWorkImp] = useState(null);
    const [PHQ9Imp, setPHQ9Imp] = useState(null);
    const [PCSSImp, setPCSSImp] = useState(null);
    const [WHODASImp, setWHODASImp] = useState(null);
    const [GAD7Imp, setGAD7Imp] = useState(null);
    const [NDIImp, setNDIImp] = useState(null);
    //#endregion improvement




    const [discovery,setDiscovery] = useState([])
    const [discoveryOriginal,setDiscoveryOriginal] = useState([])
    const [htmlDisc,setHtmlDisc] = useState([])

    const [goal,setGoal] = useState([])
    const [goalOriginal,setGoalOriginal] = useState([])
    const [htmlGoal,setHtmlGoal] = useState([])

	const [errors, setErrors] = useState(null);

    const [payerType, setPayerType] = useState(payerEnums.KarunaHome);

    const [ImprovementsComplete,setImprovementsComplete]= useState([]);
    const [selectedImprovements,setSelectedImprovements]= useState([]);
    const [improvementToggle,setImprovementToggle] = useState(false);


	useEffect(() => {
			getPatientData();
            
		

	}, []);

    useEffect(()=>{
       if(data!==null){
       var head={}
       head.trail=breadcrumbLinks();
        head.body={};
        head.body.subheader="Review "+(data.isFutureReport?"6 Months Post" :"Week "+data.included_weeks)+" Outcome Report";
        head.body.name=data!==null?data.patient:"Patient"
        head.body.buttons = [{title:"Back",click:toHome,img:"/icons/flip-backward.svg"},{title:"Preview Final PDF",click:(()=>{setTryPreview(true)}),img:"/icons/file-download-02-black.svg"},{title:"Save Draft",click:(()=>{setTrySave(true)}),img:"/icons/save-01-black.svg"}]
        setHeaderInfo(head);
       }
    },[data])

    useEffect(() => {
        if(save)
        {
            setSave(false);
            console.log("saving")
            SaveReportDraft();

        }
        
    

}, [save]);

useEffect(() => {
    if(trySave)
    {
        setTrySave(false);
        SaveWarning()

    }
    


}, [trySave]);


useEffect(() => {
    if(tryPreview)
    {
        setTryPreview(false);
        FakeHandleSubmit();

    }
    


}, [tryPreview]);


useEffect(() => {
    if(refresh)
    {
        setRefresh(false);
        getPatientData();

    }
    


}, [refresh,data]);



    useEffect(() => {
		if(revertSummary){
            if(summaryOriginal[summaryOriginal.length-1].week===selectedWeek){
                var fullSum="";
                summaryOriginal[summaryOriginal.length-1].value.forEach(e=>{fullSum+=(e+"\n\n")})
                setSummary(fullSum);

            }
            else
            {
                setSummary("");
            }
        }
        else
        {
            if(data){
            setSummary(data.customizations.customSummary);
            }
        }

	}, [revertSummary]);


    useEffect(() => {
		if(revertHistoryInjury){
            setHistoryInjury(HistoryInjuryOriginal);
        }
        else
        {
            if(data&&data.customizations.customHistoryInjury!==undefined){
            setHistoryInjury(data.customizations.customHistoryInjury);
            }
            else{
                setHistoryInjury("");
            }
        }

	}, [revertHistoryInjury]);

    useEffect(() => {
    

		if(revertBehavioral){
            setBehavioralText(behavioralTextOriginal);
        }
        else
        {
            if(data){

            setBehavioralText(data.customizations.customBehavioral) 
            }
          }

	}, [revertBehavioral]);


    useEffect(() => {
		if(revertSocial){
            setSocialText(socialTextOriginal);
        }
        else
        {
            if(data){

            setSocialText(data.customizations.customSocial) 
            }
          }

	}, [revertSocial]);

    useEffect(() => {
		if(revertPhysical){
            
            setPhysicalText(physicalTextOriginal);
        }
        else
        {
            if(data){

            setPhysicalText(data.customizations.customPhysical) 
            }
          }

	}, [revertPhysical]);


    

    useEffect(() => {

		if(revertGoal){
            setHtmlGoal([])
            if(goalOriginal[goalOriginal.length-1]!==undefined){
            AddGoalManual(goalOriginal[goalOriginal.length-1],true);
            }

        }
        else
        {
            if(data){
            setHtmlGoal([])
            AddGoalManual(data.customizations.custumGoals,true);
            }
        }

	}, [revertGoal]);


    useEffect(() => {

		if(revertDiscovery){
            if(discoveryOriginal[discoveryOriginal.length-1]!==undefined){
            AddDiscoveryManual(discoveryOriginal[discoveryOriginal.length-1],true);
            }
        }
        else
        {
            if(data){

            AddDiscoveryManual(data.customizations.customDiscovery,true);
            }
        }

	}, [revertDiscovery]);

    useEffect(() => {

        if(!loadedVerification &&data)
        {
            setKarunaRating(data.enabled.KarunaRating!==undefined?data.enabled.KarunaRating:false);
            setGoalProgress(data.enabled.goalProgress);
            setEngagementLevel(data.enabled.engagmentLevel);
            setSessionsAttended(data.enabled.sessionsAttended);
            setUniqueDays(data.enabled.uniqueDays);
            setAverageTime(data.enabled.averageTime);
            setLeftFlexion(data.enabled.leftFlexion);
            setLeftAbduction(data.enabled.leftAbduction);
            setLeftScaption(data.enabled.leftScaption);
            setRightAbduction(data.enabled.rightAbduction);
            setRightFlexion(data.enabled.rightFlexion);
            setRightScaption(data.enabled.rightScaption);
            setLowerFlexion(data.enabled.lowerFlexoion);
            setPain(data.enabled.pain);
            setMood(data.enabled.mood);
            setActivity(data.enabled.activity);
            setSleep(data.enabled.sleep);
            setConfidence(data.enabled.confidence);
            setReturnToWork(data.enabled.returnToWork);
            setMedReduction(data.enabled.medicationReduction);
            setOwestry(data.enabled.oswestry);
            setDash(data.enabled.Dash);
            setPSEQ(data.enabled.PSEQ);
            setPCS(data.enabled.PCS);
            setUEFI(data.enabled.UEFI);
            setFABQTotal(data.enabled.FABQTotal);
            setFABQActivity(data.enabled.FABQActivity);
            setFABQWork(data.enabled.FABQWork);
            setBehavioral(data.enabled.behavioral);
            setPhysical(data.enabled.physical);
            setSocial(data.enabled.social);
           
            setPCSS(data.enabled.PCSS!==undefined?data.enabled.PCSS:false);
            setGAD7(data.enabled.GAD7!==undefined?data.enabled.GAD7:false);
            setWHODAS(data.enabled.WHODAS!==undefined?data.enabled.WHODAS:false);
            setNDI(data.enabled.NDI!==undefined?data.enabled.NDI:false);
            setPHQ9(data.enabled.PHQ9!==undefined?data.enabled.PHQ9:false);
            SetLoadedVerification(true);
        }


       

	}, [rawGoalProgressData,rawEngagmentLevelData,RawSessionsAttendedData,RawUniqueDaysData,RawAverageTimeData,returnToWorkData,medReductionData,leftFlexionData,leftScaptionData,leftAbductionData,rightFlexionData,rightScaptionData,rightAbductionData, painData, moodData, activityData, sleepData, confidenceData, PSEQData,PCSData,oswestryData,DashData, lowerFlexionData, UEFIData,data,FABQTotalData,FABQActivityData,FABQWorkData,PCSSData,WHODASData,NDIData,PHQ9Data,GAD7Data]);


    useEffect(() => {
       
        var text=""
        if(continueProgram)
        {
            var programName= "KarunaHOME"
            if(payerType===payerEnums.VA){programName="Valor"}
            if(payerType===payerEnums.Reclaim ){programName="Reclaim"}

            if(selectedWeek<12)
            {


            text+=`Given ${patientName.split(" ")[0]}’s progress and current needs, it is my recommendation to continue with the ongoing ${programName} program. The upcoming weeks will cover the following topics, tailored to support ${patientName.split(" ")[0]}'s specific goals and to build upon their existing knowledge:\n\n`+

            ("Pain Defined, Neural Circuit Pain and Calming the Nervous System, Danger Signals, Graded Exposure, Emotions, Thoughts and Behaviors, Stress Interpretation and Relaxation Skills, Mindfulness and Gratitude, Enjoyable Activities, Social Connection, Restoring Life Balance, Reclaiming Your Life, Planning For Success".split(", ").slice(selectedWeek-1, 11).join(", ")+"\n\n")+

            `${patientName.split(" ")[0]} will be encouraged to continue engaging with 5-25 minutes of daily VET™ and maintain regular physical activity. Regular check-ins and adjustments will be made as necessary to ensure the patient's optimal engagement and benefit.`
            }
            else
            {
                text+=`Given ${patientName.split(" ")[0]}'s progress and current needs, it is my recommendation to continue with the ongoing ${programName} program. Having completed the core 12-week curriculum, ${patientName.split(" ")[0]}'s coaching sessions will be tailored to support their specific goals and to build upon their existing knowledge.\n\n`+`${patientName.split(" ")[0]} will be encouraged to continue engaging with 5-25 minutes of daily VET™ and maintain regular physical activity. Regular check-ins and adjustments will be made as necessary to ensure the patient's optimal engagement and benefit.`
            }
        }
        if(notification)
        {
			text  +=((text.length>0?"\n\n":""))
            switch(templateNotification)
            {
                case NotificationTypes.PsychotherapyReferral:
                    text+=`${patientName.split(" ")[0]} has expressed challenges with their mental health. They are interested in exploring options such as psychotherapy or counseling.`
                    break
                case NotificationTypes.MedicationReEvaluation:
                    text+=`${patientName.split(" ")[0]} has voiced concerns regarding their current medication regimen. They would like to request a re-evaluation of their current needs to address any potential side-effects or concerns.`
                    break;
                case NotificationTypes.NewInjury:
                    text+=`${patientName.split(" ")[0]} has reported a recent injury and is requesting further medical attention to assess the extent of the injury and advise any necessary modifications to their current plan of care.`
                    break;
                case NotificationTypes.NewSymptoms:
                    text+=`${patientName.split(" ")[0]} has reported experiencing new concerning symptoms. They are requesting a physician re-evaluation to ascertain the cause and implications of these symptoms.`
                    break;
            }
        }
		if (extension) {
            var template=`In consideration of the subjective and objective progress exhibited by ${patientName} over the initial ${selectedWeek} week${selectedWeek>1?"s":""} of the KarunaHOME program, it is my recommendation that a request for an additional ${templateWeeks} week${templateWeeks>1?"s":""} be submitted for authorization.`
			text  +=((text.length>0?"\n\n":"")+template)
		}
        if(planEnd)
            {
                var template=`Given ${patientName.split(" ")[0]}'s progress and current needs, I recommend that they continue implementing the skills and concepts of the KarunaHOME program independently. Having completed the core 12-week curriculum, ${patientName.split(" ")[0]} will be encouraged to continue working towards their goals. ${patientName.split(" ")[0]} will have a 6-month check-in, and adjustments will be made as necessary to ensure their optimal engagement and benefit. If ${patientName.split(" ")[0]}'s current state starts to decline, it is my recommendation that they are re-evaluated for additional sessions with the KarunaHOME program.`
                text  +=((text.length>0?"\n\n":"")+template)
            }
        setPlan(text)

        

	}, [extension,continueProgram, notification, templateWeeks, templateNotification, planEnd]);

    useEffect(() => {
        if(selectedWeek!==changedWeek)
        {
            setChangedWeek(selectedWeek)
            
        }

	}, [selectedWeek]);


    useEffect(() => {
        if(manual)
        {
        setErrors("");
        setLoadedProfile(false);
        getPatientData(changedWeek);
        SetManual(false);
        }

	}, [changedWeek]);

    useEffect(() => {
		var text=""

        if(physical)
        {
            if(physicalText.trim()!=="")
            {
                text+=physicalText.trim(); 
                if(text.charAt(text. length-1)!==".")
                {
                    text+="."
                }

            }
        }
        else
        {
            
        }

        if(behavioral)
        {
            if(behavioralText.trim()!=="")
            {
                if(text.length>0)
                {
                    text+="\n\n"
                }
                text+=behavioralText.trim(); 
                if(text.charAt(text. length-1)!==".")
                {
                    text+="."
                }
            }
        }
        else
        {
            
        }

        if(social)
        {
            if(socialText.trim()!=="")
            {
                if(text.length>0)
                {
                    text+="\n\n"
                }
                text+=socialText.trim(); 
                if(text.charAt(text. length-1)!==".")
                {
                    text+="."
                }
            }
        }
        else
        {
           
        }

        setAssessment(text);

	}, [social,behavioral,physical,socialText,behavioralText,physicalText]);




    useEffect(() => {
		if (generate) {
            setErrors("");
            setLoadedProfile(false);
			GeneratePDF(false);
            setGenerate(false)
		}
        if (upload) {
            setErrors("");
            setLoadedProfile(false);
			GeneratePDF(true);
            setUpload(false)
		}

	}, [generate,upload]);


   

    useEffect(() => {
        if(errors!==""){
        window.scrollTo(0, 0);  
        }     
        else{

        }


	}, [errors]);

	
	useEffect(() => {
	}, [loadedProfile])

    useEffect(() => {
        if(useCheck===false){
            setErrors("Data for this report is formatted for an outdated report format for the range of motion tables, please create a new report if you would like the report to be in the new format")
        }
	}, [useCheck])

    useEffect(() => {
		window.onload = resetTimer;
		document.onmousemove = resetTimer;
		document.onload = resetTimer;
		document.onmousedown = resetTimer; 
		document.ontouchstart = resetTimer;
		document.onclick = resetTimer;    
		document.onkeydown = resetTimer;   
		document.addEventListener('scroll', resetTimer, true);
		return updateTimer();
	}, [idleTime])

    useEffect(() => {
       
        if(user===null)
        {
            if(	cookie.load('user')===undefined&&cookie.load('token')===undefined  ){
            if(window.location.pathname.pathname!=='/login'&&!location.pathname.includes("/password/reset")&&!location.pathname.includes("/account-confirm-email/")){
                console.log("cookie timed out");
                var message={message: `forced logout on cookies not found in verification page, user variable is ${user}, user cookie value is ${cookie.load('user')}, token variable is ${token}, and token cookie value is ${cookie.load('token')}, role variable is ${role}, and role cookie value is ${cookie.load('role') }`}
		 API.post(`/diagnostics/`, JSON.stringify(message)).then((response) => {

			handleLogout();

			
			  
		}).catch(error => {
			console.error(error)
			

		}) 
    
                handleLogout();
            }
        }
        }
    }, [user])

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }

      const updateTimer = () => {
		const timer = setTimeout(() => {
		//	console.log(idleTime)
			if (idleTime > 3600) {
				console.log("timed out");
				handleLogout();
			}
			else {
				setIdleTime(idleTime + 1);
			}
		}, 1000);

		return () => clearTimeout(timer);
	}

	const resetTimer = () => {
		setIdleTime(0);
	}

	const handleLogout = async (event) => {

        var discoveryText="";
        document.querySelectorAll('.discoveryInput').forEach(function(el){
            if(el.value.trim()!==""){
            discoveryText+= (el.value)+(el.value.endsWith(".")?"":".")+"\n\n";
            }
            
        });
        //setDiscovery(discoveryText);

        var g1=[]
        var g2=[]
        document.querySelectorAll('.goalInput').forEach((el)=>{
            g2.push( el.value +(el.value.endsWith(".")?"":"."));
          });   

          document.querySelectorAll('.goal2Input').forEach((el)=>{
            g1.push(el.value);
          });

          var gtemp=[];
        
          g1.forEach((e,i)=>{
            if(e.trim()!=="" && g2[i].trim()!==""){
            gtemp.push({goal: e , detail: g2[i]})
            }
            
          })
          //setGoal(gtemp);


        data.enabled={};
        data.enabled.goalProgress=goalProgress;
        data.enabled.engagmentLevel=engagmentLevel;
        data.enabled.sessionsAttended=sessionsAttended;
        data.enabled.uniqueDays=uniqueDays;
        data.enabled.averageTime=averageTime;
        data.enabled.leftFlexion=leftFlexion;
        data.enabled.leftScaption=leftScaption;
        data.enabled.leftAbduction=leftAbduction;
        data.enabled.rightFlexion=rightFlexion;
        data.enabled.rightScaption=rightScaption;
        data.enabled.rightAbduction=rightAbduction
        data.enabled.lowerFlexoion = lowerFlexion;
        data.enabled.pain=pain;
        data.enabled.mood=mood;
        data.enabled.activity=activity;
        data.enabled.sleep=sleep;
        data.enabled.confidence=confidence;
        data.enabled.returnToWork=returnToWork;
        data.enabled.medicationReduction=medReduction;
        data.enabled.oswestry=oswestry;
        data.enabled.Dash=Dash;
        data.enabled.PSEQ=PSEQ;
        data.enabled.PCS=PCS;
        data.enabled.UEFI=UEFI;
        data.enabled.WHODAS= WHODAS;
        data.enabled.NDI= NDI;
        data.enabled.GAD7= GAD7;
        data.enabled.PHQ9= PHQ9;
        data.enabled.KarunaRating= KarunaRating;
        
        data.enabled.social=social;
        data.enabled.physical=physical;
        data.enabled.behavioral=behavioral;
        data.enabled.FABQTotal= FABQTotal;
        data.enabled.FABQActivity= FABQActivity;
        data.enabled.FABQWork= FABQWork;
        data.customizations={};
        data.included_weeks=selectedWeek!=="M6"? selectedWeek:currentWeek;
    
    
        data.customizations.customSummary=summary;
        var tempGoals=[];
        gtemp.forEach(e=>{
            tempGoals.push([e.goal,e.detail]);
        })
        data.customizations.custumGoals=tempGoals;
        
        data.customizations.customDiscovery=discoveryText.split("\n\n").filter(function (el) {
            return el !=="";
          });
    
        data.customizations.customSocial=socialText;
        data.customizations.customPhysical=physicalText;
        data.customizations.customBehavioral=behavioralText;
        data.customizations.customContinuedNeed= continuedNeedText;
        data.customizations.customHistoryInjury = HistoryInjury

        data.customizations.selectedImp= selectedImprovements;
    
        data.plan= plan;
    
        var json={}
        json.report_json_data=data;
    
    console.log(json)
        await API.put(`/outcome_reports/${reportId}/`,json ).then((response) => {
            if(response.code!==undefined)
            {
                if(response.code==="ERR_BAD_RESPONSE")
                {
                    throw new Error("Recieved bad "+response.code+" error response from API please try refreshing the page")
                    
                }
            }
            if(response.status==undefined){
                throw new Error(response);
            }
            if(response.data==undefined){
                throw new Error(response);
            }
           // window.location.reload(false);
           
           var message={message: `attemptiong logout from outcome verification page`}
           API.post(`/diagnostics/`, JSON.stringify(message)).then((response) => {
  
              handleLogout();
  
              
                
          }).catch(error => {
              console.error(error)
              
  
          }) 
           //setRefresh(true);
    
    
            
        }).catch(error => {
            console.error(error)
            setLoadedProfile(true);
    
            setErrors(error.message);
            setLoadedProfile(true);
    		logout(onLogout);

    
        })  
		
	}

    const onLogout = () => {
		cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
	localStorage.clear();
		history.push('/login');
	}

      const IndicatePSEQ=(val)=>{
            var text="";
            if(val>=40)
            {
                text+="Higher confidence to return to work";
            }
            if(val<=30)
            {
                text+="Decreased likelihood to return to work";
            }
            return text+",";
      }

      const IndicateOswestry=(val)=>{
        var text="";
        if(val<=20)
        {
            text="minimal disability";
        }
        else if(val<=40)
        {
            text="moderate disability";

        }
        else if (val <=60)
        {
            text="severe disability";

        }
        else if (val <=80)
        {
            text="crippled";

        }
        else if (val <=100)
        {
            text="bed-bound";

        }
        return text+",";

      }

      const getPercent=(x, y, isUpper=true)=> {
        console.log(x)
        console.log(y)
        var divider=180;
        if(!isUpper)
        {
            divider=90
        }
        if(x===0&&y===0)
        { 
            return 0;
        }
        if(x===0&&y!==0)
        { 
            x=1;
        }
        var percentage =(Math.abs((y - x) * 100)/divider).toFixed(1);
        console.log(percentage)
        if(parseFloat( percentage)===0)
        {

            percentage=0;
        }
        percentage= percentage.toString();
        percentage=parseFloat(percentage);
        return percentage
       }

       const getDifference=(x, y)=> {
        var dif = y-x;
        if(parseFloat( dif)===0)
        {

            dif=0;
        }
        return dif
       }

    const formatDate = (date) =>{
        return [
        padTo2Digits(date.getMonth() + 1),
          padTo2Digits(date.getDate()),
          date.getFullYear(),
        ].join('/');
      }


      const AddGoal=(e)=>{
        e.preventDefault();

        var newHTML = htmlGoal.concat([<SingleInput  start={"Description (Progress and Action Steps)"} nameClass="goalInput" index={htmlGoal.length} eraseFunction={RemoveGoal} title='Goal' multiInput={true} secondClass='goal2Input' start2="Title (1-6 words)" />])
        setHtmlGoal(newHTML)
    }

    const AddGoalManual=(vals, revertOveride=false)=>{
        var newHTML=[]
        vals.forEach(v=>{
        newHTML = newHTML.concat([<SingleInput  startVal={v[1]} nameClass="goalInput" index={newHTML.length} eraseFunction={RemoveGoal} title='Goal' multiInput={true} secondClass='goal2Input' startVal2={v[0]} />])

        })


        if(revertOveride)
        {
           


              var g1 =document.querySelectorAll('.goalInput');
              var g2 = document.querySelectorAll('.goal2Input');

              for(var i =0; i<vals.length;i++)
              {
                if(g1[i]!==undefined&&g2[i]!==undefined){
                g1[i].value=vals[i][1];
                g2[i].value=vals[i][0];
                }


              }
        }

        
        setHtmlGoal(newHTML)



    }


   




    const RemoveGoal=(index)=>
    {
        var valueArr = [];
        document.querySelectorAll('.goalInput').forEach(function(el){
            valueArr.push(el.value);
        });
        valueArr.splice(index,1);

        var valueArr2 = [];
        document.querySelectorAll('.goal2Input').forEach(function(el){
            valueArr2.push(el.value);
        });
        valueArr2.splice(index,1);
       

        var newHolder=[];
        valueArr.forEach((element,i)=>{
            newHolder.push(<SingleInput startVal={element===""?null:element} startVal2={valueArr2[i]===""?null:valueArr2[i]}  start={element===""?"Description (Progress and Action Steps)":element} nameClass="goalInput" index={i} eraseFunction={RemoveGoal} title='Goal' multiInput={true} secondClass='goal2Input' start2={valueArr2[i]===""?"Title (1-6 words)":valueArr2[i]}/>)
        })
        setHtmlGoal(newHolder)
        document.querySelectorAll('.goalInput').forEach((el,i)=>{
            el.value=valueArr[i];
          });   

          document.querySelectorAll('.goal2Input').forEach((el,i)=>{
            el.value=valueArr2[i];
          });
    }

    const MedReductionCheck=(ar)=>{
        var length = ar.length;
        if(length<=1)
        {
            return true;
        }
        var result =true;
        var ar2 = ar.slice(0,length-1);
        ar2.forEach(e=>{
            if(parseInt(e.value)!==0)
            {
                result=false;
            }
        })
        return result;
    }

    const MedReductionCheckFull=(ar)=>{
        var length = ar.length;
        if(length<=1)
        {
            return true;
        }
        var result =true;
        var ar2 = ar.slice(0,length);
        ar2.forEach(e=>{
            if(parseInt(e.value)!==0)
            {
                
                result=false;
            }
        })
        return result;
    }

    const GetWHODASIndication=(val)=>{
        switch(true)
        {
            case val <1.5:  return "No Disability, ";
            case val <2.5:  return "Mild Disability, ";
            case val<3.5:  return "Moderate Disability, ";
            case val <4.5:  return "Severe Disability, ";
            default: return "Extreme Disability, ";

        }

    }

    const GetGAD7Indication=(val)=>{
        switch(true)
        {
            case val <=4:  return "No anxiety, ";
            case val <=9:  return "Mild anxiety, ";
            case val<=14:  return "Moderate anxiety, ";
            case val <=21:  return "Severe anxiety, ";
            default: return "";
        }
    }

    const GetNDIIndication=(val)=>{
        switch(true)
        {
            case val <=4:  return "No Neck disability, ";
            case val <=14:  return "Mild disability, ";
            case val<=24:  return "Moderate disability, ";
            case val <=34:  return "Severe disability, ";
            default: return "Complete disability, ";
        }
    }

    const GetPHQ9Indication=(val)=>{
        switch(true)
        {
            case val <=4:  return "Minimal depression, ";
            case val <=9:  return "Mild depression, ";
            case val<=14:  return "Moderate depression, ";
            case val <=19:  return "Moderatel severe depression, ";
            case val <=27:  return "Severe depression, ";

            default: return "Complete disability, ";
        }
    }

    const GetMedReductionResult=(ar)=>{

        var medRed="No";
      
        if(ar.slice(-1)[0].value>=2||MedReductionCheckFull(ar))
        {
            medRed="No";
        }
        else
        {
          
            if(ar.slice(-1)[0].value<=1&&!MedReductionCheck(ar)){

                medRed="Yes";

            }
            else
            {

                medRed="No";
            }
        }
        return medRed;
    }


    const AddDiscovery=(e)=>{
        e.preventDefault();

        var newHTML = htmlDisc.concat([<SingleInput start="Description" nameClass="discoveryInput" index={htmlDisc.length} eraseFunction={RemoveDiscovery} title='Discovery'/>])
        setHtmlDisc(newHTML)
    }

    const AddDiscoveryManual=(vals, revertOveride=false)=>{
        var newHTML=[]
        vals.forEach(v=>{
        newHTML = newHTML.concat([<SingleInput startVal={v} nameClass="discoveryInput" index={newHTML.length} eraseFunction={RemoveDiscovery} title='Discovery'/>])
        })


        if(revertOveride)
        {
           
              var d1 =document.querySelectorAll('.discoveryInput');

              for(var i =0; i<vals.length;i++)
              {
                if(d1[i]!==undefined)
                {
                    d1[i].value=vals[i];
                }

              }
        }

        setHtmlDisc(newHTML)
    }

    const RemoveDiscovery=(index)=>
    {
        var valueArr = [];
        document.querySelectorAll('.discoveryInput').forEach(function(el){
            valueArr.push(el.value);
        });
        valueArr.splice(index,1);

        var newHolder=[];
        valueArr.forEach((element,i)=>{
            newHolder.push(<SingleInput startVal={element===""?null:element}  start={element===""?"Description":element} nameClass="discoveryInput" index={i} eraseFunction={RemoveDiscovery} title='Discovery'/>)
        })
        setHtmlDisc(newHolder)
        document.querySelectorAll('.discoveryInput').forEach((el,i)=>{
            el.value=valueArr[i];
          });   
    }

    const toHome =()=>{
		goToHome();
	}

    


	const goToHome = (success=false) => {

		if(success)
		{
			localStorage.setItem("success","updated patient profile" );

		}
		history.push('/');
	}

	

   
	


    const NumberPages =(doc)=>{
        var pageWidth = doc.internal.pageSize.width;  
        var pageHeight = doc.internal.pageSize.height;  

        var pages = doc.internal.getNumberOfPages();
       
        doc.setFontSize(11);  //Optional
        doc.setFont('Inter','normal')

        for (let j = 1; j < pages + 1 ; j++) {
              let horizontalPos = pageWidth / 2;  //Can be fixed number
              let verticalPos = pageHeight - 20;  //Can be fixed number
              doc.setPage(j);
              doc.text(`Rewire Your Brain. Unlearn Your Pain`, 40, verticalPos, {align: 'left'  });
              doc.text(`${j}`, pageWidth-40, verticalPos, {align: 'right'  });   

              doc.setDrawColor(30,170,241)
              doc.setFillColor(30,170,241 )

              verticalPos = pageHeight - 10;  //Can be fixed number
                doc.rect (0,verticalPos , pageWidth, 10,'FD');
                doc.rect (0,0, pageWidth, 10,'FD');

               
        }
       
    }


    const ProviderSection =(doc)=>{

        var {
            ComboBox,
            ListBox,
            CheckBox,
            PushButton,
            TextField,
            PasswordField,
            RadioButton,
            Appearance
        } = jsPDF.AcroForm;

        var pageWidth = doc.internal.pageSize.width;  
        var pageHeight = doc.internal.pageSize.height;  
        var y= doc.lastAutoTable.finalY
        doc.addPage();
        y=30;

        doc.setFont('Inter','normal')
        doc.setFontSize(12);  
    
        doc.setFillColor(234,236,240)
        doc.setDrawColor(234,236,240)
    
        doc.setTextColor(71,84,103)
    
         let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Physician Review`) * doc.internal.getFontSize() / 2); 
    

        doc.line(40,y,((doc.internal.pageSize.width / 2) )-(52),y)
        doc.line(((doc.internal.pageSize.width / 2) )+(52),y,(pageWidth-40),y)
        doc.text(`Physician Review`, xOffset, y+3);

        doc.setFont('Inter','normal')
        doc.setFontSize(8); 
        doc.setTextColor(71, 84, 103)
        xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`For provider use only`) * doc.internal.getFontSize() / 2); 
        y+=25;

        doc.text(`For provider use only`, xOffset, y);
        y+=15;
        doc.setFillColor(207, 212, 220 )
        doc.setDrawColor(207, 212, 220)
        doc.setLineWidth(1); 

        var checkBox = new CheckBox();
        checkBox.fieldName = "CheckBox1";
        checkBox.Rect = [40, y+=5, 10, 10];
        checkBox.width = 10;
		checkBox.height = 10;
        checkBox.fontSize=10;
       checkBox.appearanceState = 'Off'
		checkBox.maxFontSize = 10;
        doc.addField(checkBox);
        doc.setFillColor("Black" )
        doc.rect(40,y,10,10)

        doc.setTextColor(52, 64, 83)
        doc.setFontSize(10); 



        
        doc.autoTable({
            pageBreak:'avoid',
    
            showHead: "firstPage",
            body: [[`Having reviewed the subjective and objective outcomes of ${patientName}, I concur with and hold as my own ${coach}’s assessment and onward plan of care.`]],
            startY: y-5 ,
            theme: 'plain',
            bodyStyles:{fontSize: 10, font: "Inter", textColor:'#344053'},
            margin: {
                left: 60,
              },
       
           
               
                     })
                     y = doc.lastAutoTable.finalY;  


        doc.setTextColor(50, 96, 158)
        doc.setFontSize(14); 
        doc.setFont('Inter', 'bold');

        doc.text(40, y=y+30, "Other Comments:");
        doc.setFont('Inter', 'normal');

        doc.setFillColor(207, 212, 220 )
        doc.setTextColor("Black")

        doc.rect(40,y+=10,530,400)
        doc.setFillColor(207, 212, 220)
        doc.setDrawColor(207, 212, 220)

    var notesField = new TextField();
    notesField.multiline = true;
    notesField.maxFontSize = 12;
    notesField.fontSize=12;
    notesField.height = 150;
    notesField.Rect = [40,y,530,400];
    notesField.fontSize = 10;
    notesField.fontName= "Inter"

    
    doc.addField(notesField);

    y+=400;
    doc.setLineWidth(3); 

    doc.setFillColor(	217, 217, 217);
    doc.setDrawColor(	217, 217, 217);
    doc.setTextColor(52, 64, 84)

    doc.fontSize=12;
    doc.line(pageWidth-270,y+=80,pageWidth-40,y)
    doc.setFont('Inter', 'normal');

    doc.text(pageWidth-40, y+=20, "Provider Signature", { align: 'right' })

    doc.line(pageWidth-270,y+=80,pageWidth-40,y)
    doc.text(pageWidth-40, y+=20, "Date", { align: 'right' })

    }


    const HandleUpload =()=>{
        if(selectedWeek===0)
            {
                setErrors("cannot create export during week 0.\n")
                return;
            }
    
            if(hasPlan===false)
            {
                setErrors("cannot create export without active coaching plan.\n")
                return;
            }
    
            
    
            //error cancel if no active plan
    
            var error ="";
            var nameWarning="";
    
            if(social&& socialText.length>600)
            {
                error+=("Social section of assessment is above character limit\n")
            }
    
            if(physical&& physicalText.length>900)
            {
                error+=("Physical section of assessment is above character limit\n")
            }
    
            if(behavioral&& behavioralText.length>900)
            {
                error+=("Behavioral section of assessment is above character limit\n")
            }
            if(continuedNeed&& continuedNeedText.length>900)
                {
                        error+=("Continued Need section of assessment is above character limit\n")
                }
    
            var discoveryText="";
            document.querySelectorAll('.discoveryInput').forEach(function(el){
                if(el.value.trim()!==""){
                discoveryText+= (el.value)+(el.value.endsWith(".")?"":".")+"\n\n";
                }
                else
                {
                    error+="incomplete discovery field, please remove or fill out.\n"
                    
                }
            });
            setDiscovery(discoveryText);
    
            var g1=[]
            var g2=[]
            document.querySelectorAll('.goalInput').forEach((el)=>{
                g2.push( el.value +(el.value.endsWith(".")?"":"."));
              });   
    
              document.querySelectorAll('.goal2Input').forEach((el)=>{
                g1.push(el.value);
              });
    
              var gtemp=[];
            
              g1.forEach((e,i)=>{
                if(e.trim()!=="" && g2[i].trim()!==""){
                gtemp.push({goal: e , detail: g2[i]})
                }
                else
                {
                    error+="incomplete goal field, please remove or fill out.\n"
    
                }
              })
              setGoal(gtemp);
    
    
              if(summary.trim()===""){
                error+="please fill out Progression Summary.\n"
              }
    
              if(assessment.trim()===""){
                error+="please fill out Assessment. There must be some form of Assessment\n"
              }
    
              if(plan.trim()===""){
                error+="please fill out Plan. There must be some form of Plan\n"
              }
    
              if(error===""){
                setErrors("");
                if(payerType===payerEnums.VA)
                    {
            
                        if(StringContainsNamePart(patientNameCheck,behavioralText))
                        {
                            nameWarning+="behavioral assessment, "
                            
                        }
                        if(StringContainsNamePart(patientNameCheck,physicalText))
                        {
                                nameWarning+="physical assessment, "
                                
                        }
                        if(StringContainsNamePart(patientNameCheck,socialText))
                        {
                                    nameWarning+="social assessment, "
                                    
                        }
                        if(StringContainsNamePart(patientNameCheck,summary))
                        {
                                nameWarning+="summary, "
                                
                        }
                        if(StringContainsNamePart(patientNameCheck,plan))
                        {
                                    nameWarning+="final plan, "
                                    
                        }
            
                        if(StringContainsNamePart(patientNameCheck,discoveryText))
                        {
                                        nameWarning+="discoveries, "
                                        
                        }
                        if(goalArrayContainsNamePart(patientNameCheck,gtemp))
                        {
                            nameWarning+="goals, "
            
                        }
            
            
            
                    }
                    console.log(nameWarning)
                    if(nameWarning.length>0)
                    {
                        nameWarning = nameWarning.substring(0,nameWarning.length-2);
                        let text = "Warning\n\nVA patients should have their names hidden in reports. Part or all of the patient name\""+patientNameCheck+"\" was found in "+ nameWarning+"Are you sure you wish to submit the report?";

                        if (window.confirm(text) === true)
                        {
                            setUpload(true);
            
                        }
                        else{
                            return;
                        }
                    }
                    else
                    {
                    setUpload(true);
                    }
    
             }
              else
              {
                setErrors(error)
              }
     }

     const RemoveDuplicateWeeks=(measures,wantLower)=>{
        let duplicateWeek={}
        let toRemove=[]
        measures.forEach((e,index)=>{
            if(duplicateWeek[ e.week]!==undefined)
            {
                duplicateWeek[ e.week].push(index);
            }
            else
            {
                duplicateWeek[ e.week]=[index];
            }
        })

        for (const key in duplicateWeek) 
            {

              if(duplicateWeek[key].length>1)
              {
                if(wantLower)
                {
                    let index=duplicateWeek[key][0];
                    let lowest =measures[index].value;
                    duplicateWeek[key].forEach(i=>{
                        if(measures[i].value<lowest)
                        {
                            index=i;
                            lowest =measures[i].value;
                        }
                    })

                    duplicateWeek[key].forEach(i=>{if(i!==index){
                        toRemove.push(i)
                    }})





                }
                else
                {
                    let index=duplicateWeek[key][0];
                    let highest =measures[index].value;
                    duplicateWeek[key].forEach(i=>{
                        if(measures[i].value>highest)
                        {
                            index=i;
                            highest =measures[i].value;
                        }
                    })
                    duplicateWeek[key].forEach(i=>{if(i!==index){
                        toRemove.push(i)
                    }})


                }
              }
          }

          toRemove.sort((a, b) => b - a); 

          for (const index of toRemove) {
            if (index >= 0 && index < measures.length) {
              measures.splice(index, 1);
            }
          }

        
          return measures;
    }

   const  RemoveNoneFirstMeasure=(measures)=>{
        let newarr= [...measures]
        newarr.splice(1, 1);

        return newarr;
    }

    const UpdateNotificationType=(val)=>{
        if(val!==notification)
            {
                setTemplateNotification(val);
            }
    }

    const UpdateTemplateWeekType=(val)=>{
        if(val!==templateWeeks)
            {
                setTemplateWeeks(val);
            }
    }


    function roundTo(num, precision) {
        const factor = Math.pow(10, precision)
        return Math.round(num * factor) / factor
      }
    
      const StringContainsNamePart=(name,text)=>
        {
          let result=false;
              let nameParts=  name.split(' ');
              nameParts.forEach(part=>{if(text.toLowerCase().includes(part.toLowerCase())){result=true;}})
              return result;
        }
    
      const goalArrayContainsNamePart=(name,goalArray)=>
        {
            let result=false;
            goalArray.forEach(g=>{
                if(StringContainsNamePart(name,g.goal)||StringContainsNamePart(name,g.detail))
                {
                    result=true;
                }
                })
                return result;
        }

        const getValueDifferenceType=(a,b, wantDecrease)=>{
            let change = a-b ;
            
            if(change===0)
                {
                    return 0;
                   
                }
                if(!wantDecrease)
                {
                    if(change>0)
                    {
                        return -1
                    }
                    else
                    {
                        return 1
                    }
                }
                else
                {
                    if(change>0)
                        {
                            return 1
                        }
                        else
                        {
                            return -1
                        }
                }
        }


    const  FakeHandleSubmit = ()=>{

        if(selectedWeek===0)
        {
            setErrors("cannot create export during week 0.\n")
            return;
        }

        if(hasPlan===false)
        {
            setErrors("cannot create export without active coaching plan.\n")
            return;
        }

        

        //error cancel if no active plan

        var error ="";
        var nameWarning="";

        if(social&& socialText.length>600)
        {
            error+=("Social section of assessment is above character limit\n")
        }

        if(physical&& physicalText.length>900)
        {
            error+=("Physical section of assessment is above character limit\n")
        }

        if(behavioral&& behavioralText.length>900)
        {
            error+=("Behavioral section of assessment is above character limit\n")
        }
        if(continuedNeed&& continuedNeedText.length>900)
            {
                    error+=("Continued Need section of assessment is above character limit\n")
            }

        var discoveryText="";
        document.querySelectorAll('.discoveryInput').forEach(function(el){
            if(el.value.trim()!==""){
            discoveryText+= (el.value)+(el.value.endsWith(".")?"":".")+"\n\n";
            }
            else
            {
                error+="incomplete discovery field, please remove or fill out.\n"
                
            }
        });
        setDiscovery(discoveryText);

        var g1=[]
        var g2=[]
        document.querySelectorAll('.goalInput').forEach((el)=>{
            g2.push( el.value +(el.value.endsWith(".")?"":"."));
          });   

          document.querySelectorAll('.goal2Input').forEach((el)=>{
            g1.push(el.value);
          });

          var gtemp=[];
        
          g1.forEach((e,i)=>{
            if(e.trim()!=="" && g2[i].trim()!==""){
            gtemp.push({goal: e , detail: g2[i]})
            }
            else
            {
                error+="incomplete goal field, please remove or fill out.\n"

            }
          })
          setGoal(gtemp);


          if(summary.trim()===""){
            error+="please fill out Progression Summary.\n"
          }

          if(assessment.trim()===""){
            error+="please fill out Assessment. There must be some form of Assessment\n"
          }

          if(plan.trim()===""){
            error+="please fill out Plan. There must be some form of Plan\n"
          }

          if(error===""){
            setErrors("");
            /*if(payerType===payerEnums.VA)
                {
        
                    if(StringContainsNamePart(patientNameCheck,behavioralText))
                    {
                        nameWarning+="behavioral assessment, "
                        
                    }
                    if(StringContainsNamePart(patientNameCheck,physicalText))
                    {
                            nameWarning+="physical assessment, "
                            
                    }
                    if(StringContainsNamePart(patientNameCheck,socialText))
                    {
                                nameWarning+="social assessment, "
                                
                    }
                    if(StringContainsNamePart(patientNameCheck,summary))
                    {
                            nameWarning+="summary, "
                            
                    }
                    if(StringContainsNamePart(patientNameCheck,plan))
                    {
                                nameWarning+="final plan, "
                                
                    }
        
                    if(StringContainsNamePart(patientNameCheck,discoveryText))
                    {
                                    nameWarning+="discoveries, "
                                    
                    }
                    if(goalArrayContainsNamePart(patientNameCheck,gtemp))
                    {
                        nameWarning+="goals, "
        
                    }
        
        
        
                }*/
                console.log(nameWarning)
                if(nameWarning.length>0)
                {
                    nameWarning = nameWarning.substring(0,nameWarning.length-2);
                    let text = "Warning\n\nVA patients should have their names hidden, an instace of part of the patient name\""+patientNameCheck+"\" was found in "+ nameWarning+" do you want to continue?";
                    if (window.confirm(text) === true)
                    {
                        setGenerate(true);
        
                    }
                    else{
                        return;
                    }
                }
                else
                {
                setGenerate(true);
                }

         }
          else
          {
            setErrors(error)
          }
    }

    const handleSubmit = (e)=>{
        e.preventDefault();

        if(selectedWeek===0)
        {
            setErrors("cannot create export during week 0.\n")
            return;
        }

        if(hasPlan===false)
        {
            setErrors("cannot create export without active coaching plan.\n")
            return;
        }

        

        //error cancel if no active plan

        var error ="";

        if(social&& socialText.length>600)
        {
            error+=("Social section of assessment is above character limit\n")
        }

        if(physical&& physicalText.length>900)
        {
            error+=("Physical section of assessment is above character limit\n")
        }

        if(behavioral&& behavioralText.length>900)
        {
            error+=("Behavioral section of assessment is above character limit\n")
        }
        if(continuedNeed&& continuedNeedText.length>900)
            {
                    error+=("Continued Need section of assessment is above character limit\n")
            }

        var discoveryText="";
        document.querySelectorAll('.discoveryInput').forEach(function(el){
            if(el.value.trim()!==""){
            discoveryText+= (el.value)+(el.value.endsWith(".")?"":".")+"\n\n";
            }
            else
            {
                error+="incomplete discovery field, please remove or fill out.\n"
                
            }
        });
        setDiscovery(discoveryText);

        var g1=[]
        var g2=[]
        document.querySelectorAll('.goalInput').forEach((el)=>{
            g2.push( el.value +(el.value.endsWith(".")?"":"."));
          });   

          document.querySelectorAll('.goal2Input').forEach((el)=>{
            g1.push(el.value);
          });

          var gtemp=[];
        
          g1.forEach((e,i)=>{
            if(e.trim()!=="" && g2[i].trim()!==""){
            gtemp.push({goal: e , detail: g2[i]})
            }
            else
            {
                error+="incomplete goal field, please remove or fill out.\n"

            }
          })
          setGoal(gtemp);


          if(summary.trim()===""){
            error+="please fill out Progression Summary.\n"
          }

          if(assessment.trim()===""){
            error+="please fill out Assessment. There must be some form of Assessment\n"
          }

          if(plan.trim()===""){
            error+="please fill out Plan. There must be some form of Plan\n"
          }

          if(error===""){
            setErrors("");

        setGenerate(true);
          }
          else
          {
            setErrors(error)
          }
    }

	const GeneratePDF = async (uploading) => {
        console.log(uploading)
        setErrors("");

        if(uploading)
        {
            SetLoadingMessage("Loading, Approving Outcome Report...")

        }
        else
        {
            SetLoadingMessage("Loading, Creating Outcome Report...")

        }

        var callAddFont1 = function () {
            this.addFileToVFS('Inter-normal.ttf', font1);
            this.addFont('Inter-normal.ttf', 'Inter', 'normal');
            };
            jsPDF.API.events.push(['addFonts', callAddFont1])
        
        var callAddFont2 = function () {
                this.addFileToVFS('Inter-bold.ttf', font2);
                this.addFont('Inter-bold.ttf', 'Inter', 'bold');
                };
                jsPDF.API.events.push(['addFonts', callAddFont2])
        
        
        const processText = (text) => {
            const parts = text.split(/(<[^>]+>)/).filter(part => part.trim() !== '');
            return parts;
          };
        
          const renderTable = (doc, cell, text,colIndex, isRom=false) => {
            let rows= [];
            let measureData;
            let largeText;
            let smallText;
            let supportColor
            if(text==="-")
            {
                largeText="-"
                smallText=" "
                supportColor="#475467"; 
                rows=[['-']]

            }
            else{

             measureData= text[1];
            largeText= text[0];
                if(colIndex===0)
                {
                    
                    let week = measureData.value[0].week
                    
                    let changeIndicator= getValueDifferenceType(measureData.value[0].value,measureData.value[measureData.value.length-1].value,LowerIsBetter(measureData.type))
                    let change = (measureData.value[0].value - measureData.value[measureData.value.length-1].value)
                    change = Math.round(change * 100) / 100
                    change=Math.abs(change)
                    if(changeIndicator===1)
                    {
                        
                       smallText="Improved ";
                        smallText=smallText+change.toString()+(UsePercentage(measureData.type)?"%":"")+" from Week "+week;
                        supportColor ="#069454"
                    }
                    else if(changeIndicator===-1)
                    {
                        smallText="Declined ";

                     
                        smallText=smallText+(change).toString()+" from Week "+week;
                        supportColor ="#c14b24"
                    }
                    else
                    {
                        smallText="No Change from Week "+week;
                        supportColor="#475467";

                    }
                }
                else
                {
                
                    let val = parseInt( text[0])
                   let indicator= GetIndicator(measureData.type, val );
                   smallText= indicator[0];
                   if(indicator[1][1].includes("warning"))
                   {
                    supportColor="#db6803"
                   }
                   else if(indicator[1][1].includes("success"))
                   {
                    supportColor="#069454"

                   }
                   else
                   {
                    supportColor="#c14b24"

                   }
                }
            }
            if(rows.length===0){
            rows=[[largeText],[smallText]];
           
            }

            const expandBodyDetails=(detail)=>{
                let bod=[]
                if(colIndex===0)
                {
                    console.log(measureData.type)
                    switch(measureData.type)
                    {
                        case OutcomeMeasureTypes.FABQActivity:
                            bod.push(["Activity Fear Avoidance (0-24)"])
                            break;
                        case OutcomeMeasureTypes.FABQTotal:
                            bod.push(["Fear Avoidance Beliefs (0-96)"])

                            break;
                        case OutcomeMeasureTypes.FABQWork:
                            bod.push(["Work Fear Avoidance (0-42)"])

                            break;
                        case OutcomeMeasureTypes.PCS:
                            bod.push(["Pain Catastrophizing (0-52)"])

                            break;
                        case OutcomeMeasureTypes.PSEQ:
                            bod.push(["Self Efficacy (0-60)"])

                            break;
                        case OutcomeMeasureTypes.PHQ9:
                            bod.push(["Depression (1-27)"])

                            break;
                        case OutcomeMeasureTypes.GAD7:
                            bod.push(["General Anxiety (0-21)"])

                            break;
                        case OutcomeMeasureTypes.Oswestry:
                            bod.push(["Low Back Disability (0-50)"])

                            break;
                        case OutcomeMeasureTypes.DASH:
                            bod.push(["Upper Limb Disability (0-100)"])
                            break;
                        case OutcomeMeasureTypes.NDI:
                            bod.push(["Neck Disability (0-50)"])

                            break;
                        case OutcomeMeasureTypes.WHODAS:
                            bod.push(["General Disability (0.0-5.0)"])

                            break;
                        case OutcomeMeasureTypes.UEFI:
                            bod.push(["Upper Limb Function (0-80)"])

                            break;
                        case OutcomeMeasureTypes.Pain:
                            bod.push(["(0-10)"])

                            break;
                        case OutcomeMeasureTypes.PCSS:
                            bod.push(["Post Concussive Sx (0-90)"])

                            break;
                        case OutcomeMeasureTypes.Activity:
                            bod.push(["(0-10)"])

                            break;
                        case OutcomeMeasureTypes.Confidence:
                            bod.push(["(0-10)"])

                            break;
                        case OutcomeMeasureTypes.Sleep:
                            bod.push(["(0-10)"])

                            break;
                        case OutcomeMeasureTypes.Mood:
                            bod.push(["(0-10)"])

                            break;
                        case OutcomeMeasureTypes.LowerFlexion:
                            bod.push(["(0°-90°)"])

                            break;
                        case OutcomeMeasureTypes.LeftFlexion:
                            bod.push(["(0°-180°)"])

                            break;
                        case OutcomeMeasureTypes.LeftAbduction:
                            bod.push(["(0°-180°)"])

                            break;
                        case OutcomeMeasureTypes.LeftScaption:
                            bod.push(["(0°-180°)"])

                            break;
                        case OutcomeMeasureTypes.RightFlexion:
                            bod.push(["(0°-180°)"])

                            break;
                        case OutcomeMeasureTypes.RightAbduction:
                            bod.push(["(0°-180°)"])

                            break;
                        case OutcomeMeasureTypes.RightScaption:
                            bod.push(["(0°-180°)"])

                            break;
                        case OutcomeMeasureTypes.KarunaRating:
                            bod.push(["General Function (0-5000)"])

                            break;
                        
                    }
                }

                bod.push(detail)
                return bod;
            }
            
            
        

            if(rows.length>1){
                console.log(expandBodyDetails( rows[1]))
                            doc.autoTable({
                                colSpan:1,

                                tableWidth: (cell.width),
                                body: expandBodyDetails( rows[1]),
                                head:[rows[0]],
                                startY:  colIndex===0?(!isRom+(cell.height*(1/4))?cell.y+(cell.height*(1/4)): cell.y):cell.y+(cell.height*(1/4)),
                                margin: {
                                    left: cell.x +5
                                },
                                tableWidth: 'wrap',
                                theme: 'plain',
                                headStyles:{
                                    fontSize: 12,
                                    textColor:colIndex===0?"#101828":"#475467",
                                    fontStyle:colIndex===0? 'bold':'normal'


                                },
                                bodyStyles:
                                {
                                    fontSize: 8,
                                    textColor: supportColor

                                },
                                alternateRowStyles:{
                                    textColor: colIndex===0 ?"#475467":supportColor

                                },
                                styles: {
                                    cellPadding:1,
                                    cellWidth: cell.width-2,
                                    halign: colIndex===0?'left':'center', // left, center, right
                                    valign: 'top', // top, middle, bottom
                                
                                
                                },
                            });
                        }
                        else
                        {
                            doc.autoTable({
                                 

                                colSpan:1,
                                tableWidth: (cell.width),
                                body:[rows[0]],
                                startY: cell.y+(cell.height*(2/5)),
                                margin: {
                                    left: cell.x +2
                                },
                                tableWidth: 'wrap',
                                theme: 'plain',
                               
                                bodyStyles:
                                {
                                    fontSize: 14,
                                    textColor:colIndex===0?"#101828":"#475467",
                                    fontStyle:colIndex===0? 'bold':'normal'

                                },
                                styles: {
                                    cellPadding:1,
                                    cellWidth: cell.width-2,
                                    halign: colIndex===0?'left':'center', // left, center, right
                                    valign: 'top', // top, middle, bottom
                                
                                
                                },
                            });
                        }
           
        
          };
        
          const renderText = (doc, cell, text) => {
            let pageWidth= cell.width
            var addY=0;
            var parts=[]
                           
            if (typeof text === 'string' || text instanceof String){
                var blocks = processText(text);
                blocks.forEach(e=>{
                    if(e.includes("<")){
                        parts.push(e);
                    }
                    else
                    {
                       var pieces= e.split(' ');
                      parts= parts.concat(pieces)
                    }
                })
                
            const xPos = cell.x + 2; // Add some padding from the left
            let yPos = cell.y + (cell.height / 2);
        
            let cursorX = xPos;
            doc.setFont('Inter', 'normal');
            parts.forEach(part => {
              if (part === '<b>') {
                doc.setFont('Inter', 'bold');
              } else if (part === '</b>') {
                doc.setFont('Inter', 'normal');
              }
              else if (part === '<0>') {
                doc.setTextColor(colors[0]);
              } else if (part === '</0>') {
                doc.setTextColor('#344054');
              }
              else if (part === '<1>') {
                doc.setTextColor(colors[1]);
              } else if (part === '</1>') {
                doc.setTextColor('#344054');
              }
              else if (part === '<2>') {
                doc.setTextColor(colors[2]);
              } else if (part === '</2>') {
                doc.setTextColor('#344054');
              }
              else if (part === '<3>') {
                doc.setTextColor(colors[3]);
              } else if (part === '</3>') {
                doc.setTextColor('#344054');
              }
              else if (part === '<4>') {
                doc.setTextColor(colors[4]);
              } else if (part === '</4>') {
                doc.setTextColor('#344054');
              }
              else if (part === '<red>') {
                doc.setTextColor('#ED5C2D');
              } else if (part === '</red>') {
                doc.setTextColor('#344054');
              }
              else if (part === '<orange>') {
                doc.setTextColor('#EF6820');
              } else if (part === '</orange>') {
                doc.setTextColor('#344054');
              }
              else if (part === '<green>') {
                doc.setTextColor('#17B26A');
              } else if (part === '</green>') {
                doc.setTextColor('#344054');
              }
               else {
                const textWidth = doc.getTextWidth(part+' ');
             
              
                if( cursorX+textWidth>(pageWidth))
                {
                    cursorX= xPos;
                    yPos+=doc.getTextDimensions(part).h+1;
                    addY+=doc.getTextDimensions(part).h+1;
                    doc.text(part+' s', cursorX, yPos);
                    cursorX += textWidth;
                }
                else{
                    doc.text(part+'', cursorX, yPos);
                    cursorX += textWidth;
                }
              }
            });
        }
        
        return addY
        
          };

          const canvasChart = document.getElementById("chart");

          const canvasImage= canvasChart.toDataURL('image/png')


        ///
        var weeks =[1,4,8,12]
        if(selectedWeek!=="M6"){
        weeks= weeks.filter((i)=>{return i<= parseInt(selectedWeek)})
        if(!weeks.includes(parseInt(selectedWeek))){weeks.push(parseInt(selectedWeek))}
        }
        else
        {
            weeks= weeks.filter((i)=>{return i<= parseInt(currentWeek)})
            if(!weeks.includes(parseInt(currentWeek))){weeks.push(parseInt(currentWeek))}

            weeks.push("M6");
        }

        var Today = formatDate(new Date() ); 
        var week =selectedWeek.toString();
        

        var doc = new jsPDF('p', 'pt', 'letter',  )
        doc.setLineHeightFactor(1.5)
        var y = 10;
        doc.setLineWidth(2); 

        doc.setFontSize(20);  
        var pageWidth = doc.internal.pageSize.width;  
        var pageHeight = doc.internal.pageSize.height;  
        var img = new Image()
        if(payerType===payerEnums.KarunaHome){
        img.src = '/icons/title.png';
        doc.addImage(img, 'png', 250, y, 120, 100)

        }
        else if(payerType===payerEnums.Reclaim)
        {
            img.src='/icons/Reclaim-System-Stacked-Full-Color.png';
            y+=20;
            doc.addImage(img, 'png', 190, y, 112*2, 41*2)

        }
        else
        {
            img.src='/icons/ValorTitle.png';
            y+=20;
            doc.addImage(img, 'png', 190, y, 112*2, 41*2)

            
        }
          doc.autoTable({
        pageBreak:'avoid',
        body: [[(payerType!==payerEnums.VA? patientName:"#"+patientId)]],
        startY: y = y + (payerType===payerEnums.KarunaHome? 100:(payerType===payerEnums.Reclaim?100:100)),
        theme: 'plain',
        styles:{font: "Inter", fontSize: 25, textColor:[50,96,158 ], halign: 'center', valign: 'middle', fontStyle:'bold'}
        })
    y = doc.lastAutoTable.finalY;  
     doc.setFont('Inter','normal')

        //patient info heading
        doc.setFontSize(12);  
        y+=10;
       
    doc.setFillColor(234,236,240)
    doc.setDrawColor(234,236,240)
    doc.setTextColor(71,84,103)
       let  xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Patient Outcome Report`) * doc.internal.getFontSize() / 2); 

        doc.line(40,y,((doc.internal.pageSize.width / 2) )-(70),y)
        doc.line(((doc.internal.pageSize.width / 2) )+(70),y,(pageWidth-40),y)
        doc.text(`Patient Outcome Report`, xOffset, y+3);

        doc.setTextColor('#344054')

        let intro=[["Date of Birth",(payerType!==payerEnums.VA? DOB:"N/A"),"Reporter",preparer],["Date of Injury", ((DOI==="Unknown"||DOI===""||payerType===payerEnums.VA)?"N/A":DOI), "Report Date", ((prepareDate==="Unknown"||prepareDate==="")?"N/A":prepareDate)],["Physician", physician===""?"N/A": physician, "Program Stage", (selectedWeek!=="M6"?"Week " +selectedWeek.toString():"6 Months Post") ],["Claim Number",((claimNumber==="Unknown"||claimNumber==="")?"N/A":claimNumber),"Program Type",program]]
        doc.autoTable({
            pageBreak:'avoid',
            body: intro,
            startY: y = y + 10,
            theme: 'plain',
            styles:{font: "Inter", fontSize: 10, textColor:"#344054", valign: 'middle', },
            columnStyles: {
                0: {halign: 'left',fontStyle:'bold'},
                1: {halign: 'left'},
                2: {halign: 'right',fontStyle:'bold'},
                3: {halign: 'right'},

            },
          
        })
        y = doc.lastAutoTable.finalY; 
        doc.setFontSize(12);  
        y+=15;
    if(selectedImprovements.length>0){   
    doc.setFillColor(234,236,240)
    doc.setDrawColor(234,236,240)

    doc.setTextColor(71,84,103)

      xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Key Improvements`) * doc.internal.getFontSize() / 2); 

    doc.line(40,y,((doc.internal.pageSize.width / 2) )-(55),y)
    doc.line(((doc.internal.pageSize.width / 2) )+(56),y,(pageWidth-40),y)
    doc.text(`Key Improvements`, xOffset, y+3);
        y+=20
        let symbol= new Image()
        symbol.src='/icons/info.png';
        doc.addImage(symbol, 'png', 40, y, 10 ,10 )
        doc.setFontSize(10);  

        doc.text("Measures in the graph have been converted to a standard percentage score, where higher is better.",53,y+8.5,{align: 'left'  })

        doc.setTextColor('#344054') 
        y+=20
        doc.addImage(canvasImage,'png',15,y,pageWidth-30,150);
        y+=150;
//            {content:"<b>Subjective</b> Outcomes"}

        let textbody= [ ]
        let tempTextBody=[]
        selectedImprovements.forEach((label,index)=>{
            let metric = GetMeasureFromType(label);
            let first = GetIndicator(metric.type,metric.value[0].value)
            let last= null
            if(isRomType(metric.type))
            {
                last= GetIndicator(metric.type,metric.value[ GetBestWeek(metric.value,3)].value)
            }
            else
            {
                last =  GetIndicator( metric.type,metric.value.slice(-1)[0].value)

            }
            let firstColor= first[1][0].includes("success")?"green":(first[1][0].includes("warning")?"orange":"red")
            let lastColor= last[1][0].includes("success")?"green":(last[1][0].includes("warning")?"orange":"red")

            let text = "<"+index.toString()+">";
            text+=metric.name;
            text+="</"+index.toString()+">";
            text+=" has improved from "
            text+="<"+firstColor+">"
            text+=metric.value[0].value
            text+=" ("
            text+=first[0];
            text+=")"
            text+="</"+firstColor+">"

            text+=" to "
            text+="<"+lastColor+">"
            text+= (isRomType(metric.type)?metric.value[ GetBestWeek(metric.value,3)].value:metric.value.slice(-1)[0].value)
            text+=" ("
            text+=last[0];
            text+=")"
            text+="</"+lastColor+">"

            let tempText = ""
            tempText+=metric.name;
            tempText+=" has improved from "
            tempText+=metric.value[0].value
            tempText+=" ("
            tempText+=first[0];
            tempText+=")"

            tempText+=" to "
            tempText+= (isRomType(metric.type)?metric.value[ GetBestWeek(metric.value,3)].value:metric.value.slice(-1)[0].value)
            tempText+=" ("
            tempText+=last[0];
            tempText+=")"

            
            
            tempTextBody.push(text)
            textbody.push({content:tempText})
        })
        let cellIndex=0;
        doc.autoTable({

            body: textbody.map(row => [row.content]),
            willDrawCell: function (textbody) {
               // console.log(tempbody)
              if (textbody.section === 'body') {
                
                const cell = textbody.cell;
                const text = tempTextBody[cellIndex];
                cellIndex++;
                cell.text = [];
                renderText(doc, cell, text, pageWidth);
              }
            },
            startY: y = y + 10,
            theme: 'plain',
            styles:{ fontSize: 10,halign: 'left'}
          
        })
        y = doc.lastAutoTable.finalY; 
    }
    y+=10

    //history of injury
    if(HistoryInjury.length>0){
    doc.setFontSize(12);  

    doc.setFillColor(234,236,240)
    doc.setDrawColor(234,236,240)

    doc.setTextColor(71,84,103)

      xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Patient History`) * doc.internal.getFontSize() / 2); 

    doc.line(40,y,((doc.internal.pageSize.width / 2) )-(45),y)
    doc.line(((doc.internal.pageSize.width / 2) )+(45),y,(pageWidth-40),y)
    doc.text(`Patient History`, xOffset, y+3);
        y+=10

        doc.autoTable({
            pageBreak:'avoid',
            body: [["History of Injury"]],
            startY: y ,
            theme: 'plain',
            styles:{font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], halign: 'left', valign: 'middle', fontStyle:'bold'}
            })
        y = doc.lastAutoTable.finalY; 
        let pHeight = doc.internal.pageSize.height;  

        doc.autoTable({
            pageBreak:'avoid',
            body: [[HistoryInjury]],
            startY: y+=2.5 ,
            theme: 'plain',
            styles:{font: "Inter", fontSize: 10, textColor:"#344054", halign: 'left', }
            })
            y = doc.lastAutoTable.finalY

            if(y%pHeight>(pHeight*(4/5)))
            {
                doc.addPage();
                y=30;
            }



        }

        //SUBJECTIVE OUTCOMES

        var col = [
            { header:'Program Goals', dataKey: 'goal'}, {header:'', dataKey:'detail' }
        ]
            var body =[];
            body= goal;

            if(goal.length>0|| discovery.length>0||engagmentLevel||goalProgress){

                doc.setFontSize(12);  

                doc.setFillColor(234,236,240)
                doc.setDrawColor(234,236,240)
            
                doc.setTextColor(71,84,103)
            
                  xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Subjective Outcomes`) * doc.internal.getFontSize() / 2); 
            
                doc.line(40,y,((doc.internal.pageSize.width / 2) )-(65),y)
                doc.line(((doc.internal.pageSize.width / 2) )+(65),y,(pageWidth-40),y)
                doc.text(`Subjective Outcomes`, xOffset, y+3);
                  
        }

            

            if(body.length>0){
                doc.autoTable({

                    showHead: "firstPage",
                    body: body,
                    columns: col,
                    startY: y = y + 30,
                    theme: 'plain',
                    headStyles: {font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], fontStyle:'bold'},
                    columnStyles: {
                        goal: {cellWidth: 250,  font: "Inter", fontSize: 10, fontStyle:'bold'},
                        detail: {cellWidth: 250, halign: 'left',  font: "Inter", fontSize: 10},
                        // etc
                    },
                    bodyStyles:{cellPadding:10, textColor:"#344054"}
                            })

                y = doc.lastAutoTable.finalY
            }

        //perspective
        var col = [
            { header:'Patient Perspective', dataKey: 'goal'}, {header:'', dataKey:'detail' }
        ]

        var body=[]

        if(goalProgress){
            body.push({ 
                goal:"Perceived Progress Towards Goals", detail:(rawGoalProgressData.value.toString()+ "/10")},
            )
        }
        if (engagmentLevel)
        {
           body.push({ 
                goal:"Level of Engagement with Program", detail:(rawEngagmentLevelData.value.toString()+ "/10")},
            )
        }

        

        if(discovery.length>0)
        {
            console.log(discovery)
            body.push(
                {
                    goal: "Discoveries", detail: discovery
                }
            )
        }

        if(body.length>0){
            doc.autoTable({
                pageBreak:'avoid',

                showHead: "firstPage",
                body: body,
                columns: col,
                startY: y = y + 20,
                theme: 'plain',
                headStyles: {font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], fontStyle:'bold'},
                bodyStyles:{cellPadding:10, textColor:"#344054"},
                columnStyles: {
                    goal: {cellWidth: 250,  font: "Inter", fontSize: 10,  fontStyle:'bold'},
                    detail: {cellWidth: 250, halign: 'left',  font: "Inter", fontSize: 10},
                
                  }
                         })

            y = doc.lastAutoTable.finalY+10
        }

        //summary

        var col = [
            { header:'Subjective Progress Summary', dataKey: 'summary'}
        ]
        var body=[]
        body= [ { 
            summary: summary
        },
        
        ]


        if(summary.length>0)
        {
            doc.autoTable({
                pageBreak:'avoid',

                showHead: "firstPage",
                body: body,
                columns: col,
                startY: y = y + 10,
                theme: 'plain',
                pageBreak:'avoid',
                headStyles: {font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], fontStyle:'bold'},
                bodyStyles:{fontSize: 10,font: "Inter", textColor:"#344054"}
               
                   
                         })

        y = doc.lastAutoTable.finalY+20
        }

        if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
            {
                doc.addPage();
                y=30;
            }

        //objective


            if(sessionsAttended||uniqueDays||averageTime||leftAbduction||leftScaption||leftFlexion||rightAbduction||rightScaption||rightFlexion ||returnToWork||medReduction||PSEQ||PCS||oswestry||Dash||lowerFlexion||UEFI||FABQActivity||FABQTotal||FABQWork||PCSS||NDI||PHQ9||GAD7||WHODAS)
            {
                doc.setFontSize(12);  

                doc.setFillColor(234,236,240)
                doc.setDrawColor(234,236,240)
            
                doc.setTextColor(71,84,103)
            
                  xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Objecive Outcomes`) * doc.internal.getFontSize() / 2); 
            
                doc.line(40,y,((doc.internal.pageSize.width / 2) )-(57),y)
                doc.line(((doc.internal.pageSize.width / 2) )+(61),y,(pageWidth-40),y)
                doc.text(`Objective Outcomes`, xOffset, y+3);
            }

            var col = [
                { header:'Adherence', dataKey: 'goal'}, {header:'', dataKey:'detail' }
            ]
    
            var body= [ 
            (sessionsAttended?{ goal:"Sessions Attended", detail:RawSessionsAttendedData.toString()}:null),
            (
                uniqueDays?
            {
                goal: "Avg. Days DME Usage per Week", detail: RawUniqueDaysData.toString()
            }:null
            ),
            ( averageTime?
            { 
                goal: "Avg. Time Spent in DME per Week", detail: RawAverageTimeData.toString()
            }:null
            )
            ]
           body= body.filter(n => n)
             if(body.length>0){

                doc.autoTable({
                    pageBreak:'avoid',

                showHead: "firstPage",
                body: body,
                columns: col,
                startY: y = y + 10,
                theme: 'plain',
                headStyles: {font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], fontStyle:'bold'},
                bodyStyles:{cellPadding:10, textColor:"#344054"},
                columnStyles: {
                    goal: {cellWidth: 250,  font: "Inter", fontSize: 10,  fontStyle:'bold'},
                    detail: {cellWidth: 250, halign: 'left',  font: "Inter", fontSize: 10},
                
                  }
                   
                       
                })
    
            y = doc.lastAutoTable.finalY+10
             }

             var col = [
                { header:'Program Impact', dataKey: 'goal'}, {header:'', dataKey:'detail' }
            ]
        
             var body= [ 
                (returnToWork? { goal:"Return to Work", detail:returnToWorkData.slice(-1)[0].value>=3?"Yes":"No"}:null),
                ( medReduction?{ goal:"Medication Reduction", detail:medImp}:null),
          
            ]
            body =body.filter(n => n)
             if(body.length>0){
               
            
               
        
                doc.autoTable({
                    pageBreak:'avoid',

                    showHead: "firstPage",
                    body: body,
                    columns: col,
                    startY: y = y + 10,
                    theme: 'plain',
                    headStyles: {font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], fontStyle:'bold'},
                    bodyStyles:{cellPadding:10, textColor:"#344054"},
                    columnStyles: {
                        goal: {cellWidth: 250,  font: "Inter", fontSize: 10,  fontStyle:'bold'},
                        detail: {cellWidth: 250, halign: 'left',  font: "Inter", fontSize: 10},
                    
                      }     
                })
        
            y = doc.lastAutoTable.finalY
             }





            body=[];


            var dashRow={field:["DASH",GetMeasureFromType(OutcomeMeasureTypes.DASH),'title']}
    DashData.forEach(element=>{
       
        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
            dashRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.DASH),element.week];
        }
    })
    weeks.forEach( (i) =>
    {
        if(dashRow["week"+i.toString()]===undefined){dashRow["week"+i.toString()]="-"}
    })
    var NDIRow={field:["NDI",GetMeasureFromType(OutcomeMeasureTypes.NDI),'title']}
    NDIData.forEach(element=>{
       
        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
            NDIRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.NDI),element.week]
        }
    })
    weeks.forEach( (i) =>
    {
        if(NDIRow["week"+i.toString()]===undefined){NDIRow["week"+i.toString()]="-"}
    })


    var oswRow={field:["Oswestry",GetMeasureFromType(OutcomeMeasureTypes.Oswestry),'title']}
    oswestryData.forEach(element=>{
       
        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
            oswRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.Oswestry),element.week]
        }
    })
    weeks.forEach( (i) =>
    {
        if(oswRow["week"+i.toString()]===undefined){oswRow["week"+i.toString()]="-"}
    })

    var WHODASRow={field:["WHODAS",GetMeasureFromType(OutcomeMeasureTypes.WHODAS),'title']}
    WHODASData.forEach(element=>{
       
        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
            WHODASRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.WHODAS),element.week]
        }
    })
    weeks.forEach( (i) =>
    {
        if(WHODASRow["week"+i.toString()]===undefined){WHODASRow["week"+i.toString()]="-"}
    })

    body=[
        (Dash?dashRow:null),
        (NDI?NDIRow:null),
        (oswestry?oswRow:null),
        (WHODAS?WHODASRow:null)
    ]
    body=body.filter(n => n);
    if(body.length>0)
        {

            if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
                {
                    doc.addPage();
                    y=30;
                }

            doc.autoTable({
                pageBreak:'avoid',
                body: [["Physical Disability"]],
                startY: y+=10 ,
                theme: 'plain',
                styles:{font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], halign: 'left', valign: 'middle', fontStyle:'bold'}
                })
            y = doc.lastAutoTable.finalY; 


            var header= [         
                { header:'Measure', dataKey: 'field'} 
    
                        ]
            
            weeks.forEach( (i,index) =>
                (
                    
                    header.push({ header:(i!=="M6"?"Week " +i.toString():"6 Months Post"), dataKey: "week"+i.toString()})
                    
    
                )
            )
            if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
                {
                    doc.addPage();
                    y=30;
                }
            doc.autoTable({
                tableLineWidth: 1,
                tableLineColor: "#eaecf0",

    rowPageBreak: 'avoid',
    body: body,
    willDrawCell: function (t) {
      if (t.section === 'body') {
        const cell = t.cell;
        const text = cell.raw;
        const colIndex= t.column.index;
        cell.text = [];
       // console.log(cell)
      renderTable(doc, cell, text,colIndex,false);
      }
    },
bodyStyles: {lineColor: "#eaecf0",lineWidth: 1,fillColor:false, fontSize: 12, cellPadding: {top: 10,  bottom: 15, },  textColor:"#344054", halign:'center',valign:'middle'  },
headStyles:{fillColor:"#f9fafb", lineWidth: 1, textColor:"#475467",halign:'center',valign:'middle' , lineColor: "#eaecf0", fontSize: 12},
columnStyles: {
    0: {halign: 'left', fontSize: 10, minCellHeight: 61},
},
columns: header,
startY: y+=20,
theme: 'grid',
               
                   
            })

        y = doc.lastAutoTable.finalY
        }


        body=[];


        var activityRow={field:["Activity",GetMeasureFromType(OutcomeMeasureTypes.Activity),'title']}
activityData.forEach(element=>{
   
    if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
        activityRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.Activity),element.week];
    }
})
weeks.forEach( (i) =>
{
    if(activityRow["week"+i.toString()]===undefined){activityRow["week"+i.toString()]="-"}
})
var KarunaRow={field:["Karuna Rating",GetMeasureFromType(OutcomeMeasureTypes.KarunaRating),'title']}
KarunaRatingData.forEach(element=>{
   
    if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
        KarunaRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.KarunaRating),element.week]
    }
})
weeks.forEach( (i) =>
{
    if(KarunaRow["week"+i.toString()]===undefined){KarunaRow["week"+i.toString()]="-"}
})


var painRow={field:["Pain",GetMeasureFromType(OutcomeMeasureTypes.Pain),'title']}
painData.forEach(element=>{
   
    if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
        painRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.Pain),element.week]
    }
})
weeks.forEach( (i) =>
{
    if(painRow["week"+i.toString()]===undefined){painRow["week"+i.toString()]="-"}
})

var PCSSRow={field:["PCSS",GetMeasureFromType(OutcomeMeasureTypes.PCSS),'title']}
PCSSData.forEach(element=>{
   
    if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
        PCSSRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.PCSS),element.week]
    }
})
weeks.forEach( (i) =>
{
    if(PCSSRow["week"+i.toString()]===undefined){PCSSRow["week"+i.toString()]="-"}
})

var sleepRow={field:["Sleep",GetMeasureFromType(OutcomeMeasureTypes.Sleep),'title']}
sleepData.forEach(element=>{
   
    if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
        sleepRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.Sleep),element.week]
    }
})
weeks.forEach( (i) =>
{
    if(sleepRow["week"+i.toString()]===undefined){sleepRow["week"+i.toString()]="-"}
})

var UEFIRow={field:["UEFI",GetMeasureFromType(OutcomeMeasureTypes.UEFI),'title']}
UEFIData.forEach(element=>{
   
    if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
        UEFIRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.UEFI),element.week]
    }
})
weeks.forEach( (i) =>
{
    if(UEFIRow["week"+i.toString()]===undefined){UEFIRow["week"+i.toString()]="-"}
})

body=[
    (activity?activityRow:null),
    (KarunaRating?KarunaRow:null),
    (pain?painRow:null),
    (PCSS?PCSSRow:null),
    (sleep?sleepRow:null),
    (UEFI?UEFIRow:null),

]
body=body.filter(n => n);
if(body.length>0)
    {

        if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
            {
                doc.addPage();
                y=30;
            }

        doc.autoTable({
            pageBreak:'avoid',
            body: [["Physical Function"]],
            startY: y+=10 ,
            theme: 'plain',
            styles:{font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], halign: 'left', valign: 'middle', fontStyle:'bold'}
            })
        y = doc.lastAutoTable.finalY; 

        if(KarunaRating){
            if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
                {
                    doc.addPage();
                    y=30;
                }
                else{
        y+=5
                }
        let symbol= new Image()
        symbol.src='/icons/info.png';
        doc.addImage(symbol, 'png', 40, y, 7 ,7 )
        doc.setFontSize(10);  

        doc.autoTable({
            pageBreak:'avoid',
            showHead: "firstPage",
            body: [[`Karuna Rating is a bespoke measure of function across multiple VET™ movement activities. Range of motion is combined with functional capability demonstrated in each activity to produce a single score.`]],
            startY: y-5 ,
            theme: 'plain',
            bodyStyles:{fontSize: 8, font: "Inter", textColor:'#344053'},
            margin: {
                left: 45,
              },
       
           
               
                     })
                     y = doc.lastAutoTable.finalY;
        }


        var header= [         
            { header:'Measure', dataKey: 'field'} 

                    ]
        
        weeks.forEach( (i,index) =>
            (
                
                header.push({ header:(i!=="M6"?"Week " +i.toString():"6 Months Post"), dataKey: "week"+i.toString()})
                

            )
        )

        if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
            {
                doc.addPage();
                y=30;
            }

        doc.autoTable({
 
tableLineWidth: 1,
tableLineColor: "#eaecf0",

rowPageBreak: 'avoid',
body: body,
willDrawCell: function (t) {
  if (t.section === 'body') {
    const cell = t.cell;
    const text = cell.raw;
    const colIndex= t.column.index;
    cell.text = [];
   // console.log(cell)
  renderTable(doc, cell, text,colIndex,false);
  }
},
bodyStyles: {lineColor: "#eaecf0",lineWidth: 1, fillColor:false, fontSize: 12, cellPadding: {top: 10,  bottom: 15, },  textColor:"#344054", halign:'center',valign:'middle'  },
headStyles:{fillColor:"#f9fafb", lineWidth: 1, textColor:"#475467",halign:'center',valign:'middle' , lineColor: "#eaecf0",  fontSize: 12},
columnStyles: {
0: {halign: 'left', fontSize: 10, minCellHeight: 61},
},
columns: header,
startY: y+=(KarunaRating?7:20),
theme: 'grid',
           
               
        })

    y = doc.lastAutoTable.finalY
    }

    var header= [ { header:'Measure', dataKey: 'field'} ]
header.push({ header:"First Measurement", dataKey: "first"})
header.push({ header:"Recent Best", dataKey: "recent"})



                var lfRow={field: ["Left Shoulder Flexion",GetMeasureFromType(OutcomeMeasureTypes.LeftFlexion),'title']}
                if(leftFlexionData.length>0)
                {
                lfRow["first"]= [leftFlexionData[0].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.LeftFlexion),leftFlexionData[0].week];
                lfRow["recent"]= [leftFlexionData[GetBestWeek(leftFlexionData,3)].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.LeftFlexion),leftFlexionData[GetBestWeek(leftFlexionData,3)].week];
                }

                var laRow={field:["Left Shoulder Abduction",GetMeasureFromType(OutcomeMeasureTypes.LeftAbduction),'title']}
                if(leftAbductionData.length>0)
                {
                laRow["first"]= [leftAbductionData[0].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.LeftAbduction),leftAbductionData[0].week];
                laRow["recent"]= [leftAbductionData[GetBestWeek(leftAbductionData,3)].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.LeftAbduction),leftAbductionData[GetBestWeek(leftAbductionData,3)].week];
                }

                var leRow={field:["Left Shoulder Scaption",GetMeasureFromType(OutcomeMeasureTypes.LeftScaption),'title']}
                if(leftScaptionData.length>0)
                {
                leRow["first"]= [leftScaptionData[0].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.LeftScaption),leftAbductionData[0].week];
                leRow["recent"]= [leftScaptionData[GetBestWeek(leftScaptionData,3)].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.LeftScaption),leftScaptionData[GetBestWeek(leftScaptionData,3)].week];
                }

                var rfRow={field:["Right Shoulder Flexion",GetMeasureFromType(OutcomeMeasureTypes.RightFlexion),'title']}
                if(rightFlexionData.length>0)
                {
                rfRow["first"]= [rightFlexionData[0].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.RightFlexion),rightFlexionData[0].week];
                rfRow["recent"]= [rightFlexionData[GetBestWeek(rightFlexionData,3)].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.RightFlexion),rightFlexionData[GetBestWeek(rightFlexionData,3)].week];
                }

                var raRow={field:["Right Shoulder Abduction",GetMeasureFromType(OutcomeMeasureTypes.RightAbduction),'title']}
                if(rightAbductionData.length>0)
                {
                raRow["first"]= [rightAbductionData[0].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.RightAbduction),rightAbductionData[0].week];
                raRow["recent"]= [rightAbductionData[GetBestWeek(rightAbductionData,3)].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.RightAbduction),rightAbductionData[GetBestWeek(rightAbductionData,3)].week];
                }

                var reRow={field:["Right Shoulder Scaption",GetMeasureFromType(OutcomeMeasureTypes.RightScaption),'title']}
                if(rightScaptionData.length>0)
                {
                reRow["first"]= [rightScaptionData[0].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.RightScaption),rightAbductionData[0].week];
                reRow["recent"]= [rightScaptionData[GetBestWeek(rightScaptionData,3)].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.RightScaption),rightScaptionData[GetBestWeek(rightScaptionData,3)].week];
                }

                var lbRow={field:["Back Flexion (Estimate)",GetMeasureFromType(OutcomeMeasureTypes.LowerFlexion),'title']}
                if(lowerFlexionData.length>0)
                {
                lbRow["first"]= [lowerFlexionData[0].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.LowerFlexion),lowerFlexionData[0].week];
                lbRow["recent"]= [lowerFlexionData[GetBestWeek(lowerFlexionData,3)].value.toString()+"°",GetMeasureFromType(OutcomeMeasureTypes.LowerFlexion),lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week];
                }
        


                body=[
                    (leftFlexion? lfRow:null),
                    (leftScaption?leRow:null),
                    (leftAbduction?laRow:null),
                   (rightFlexion? rfRow:null),
             (rightScaption?reRow:null),
             (rightAbduction?raRow:null)
            , (lowerFlexion?lbRow:null),
                ]
                body=body.filter(n => n);

                if(body.length>0)
                    {
                
                        if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
                            {
                                doc.addPage();
                                y=30;
                            }
                
                        doc.autoTable({
                            pageBreak:'avoid',
                            body: [["Range of Motion"]],
                            startY: y+=10 ,
                            theme: 'plain',
                            styles:{font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], halign: 'left', valign: 'middle', fontStyle:'bold'}
                            })
                        y = doc.lastAutoTable.finalY; 
                        if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
                            {
                                doc.addPage();
                                y=30;
                            }
                
                        doc.autoTable({
                             
                            tableLineWidth: 1,
                            tableLineColor: "#eaecf0",

                
                rowPageBreak: 'avoid',
                body: body,
                willDrawCell: function (t) {
                  if (t.section === 'body') {

                    const cell = t.cell;
                    console.log(cell)
                    const text = cell.raw;
                    const colIndex= t.column.index;
                    cell.text = [];
                  renderTable(doc, cell, text,colIndex,true);
                  }
                },
                bodyStyles: {lineColor: "#eaecf0",lineWidth: 1,fillColor:false, fontSize: 12, cellPadding: {top: 10,  bottom: 15, },  textColor:"#344054", halign:'center',valign:'middle'  },
                headStyles:{fillColor:"#f9fafb", lineWidth: 1, textColor:"#475467",halign:'center',valign:'middle' , lineColor: "#eaecf0",  fontSize: 12},
                columnStyles: {
                0: {halign: 'left', fontSize: 10, minCellHeight: 61},
                },
                columns: header,
                startY: y+=20,
                theme: 'grid',
                           
                               
                        })
                
                    y = doc.lastAutoTable.finalY
                    }


                    var confidenceRow={field:["Confidence",GetMeasureFromType(OutcomeMeasureTypes.Confidence),'title']}
                    confidenceData.forEach(element=>{
                       
                        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
                            confidenceRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.Confidence),element.week];
                        }
                    })
                    weeks.forEach( (i) =>
                    {
                        if(confidenceRow["week"+i.toString()]===undefined){confidenceRow["week"+i.toString()]="-"}
                    })

                    var FABQARow={field:["FABQ Activity",GetMeasureFromType(OutcomeMeasureTypes.FABQActivity),'title']}
                    FABQActivityData.forEach(element=>{
                       
                        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
                            FABQARow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.FABQActivity),element.week]
                        }
                    })
                    weeks.forEach( (i) =>
                    {
                        if(FABQARow["week"+i.toString()]===undefined){FABQARow["week"+i.toString()]="-"}
                    })

                    var FABQWRow={field:["FABQ Work",GetMeasureFromType(OutcomeMeasureTypes.FABQWork),'title']}
                    FABQWorkData.forEach(element=>{
                       
                        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
                            FABQWRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.FABQWork),element.week]
                        }
                    })
                    weeks.forEach( (i) =>
                    {
                        if(FABQWRow["week"+i.toString()]===undefined){FABQWRow["week"+i.toString()]="-"}
                    })
                    
                    var FABQTRow={field:["FABQ Total",GetMeasureFromType(OutcomeMeasureTypes.FABQTotal),'title']}
                    FABQTotalData.forEach(element=>{
                       
                        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
                            FABQTRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.FABQTotal),element.week]
                        }
                    })
                    weeks.forEach( (i) =>
                    {
                        if(FABQTRow["week"+i.toString()]===undefined){FABQTRow["week"+i.toString()]="-"}
                    })
                    
                  
                    
                    var gad7Row={field:["GAD-7",GetMeasureFromType(OutcomeMeasureTypes.GAD7),'title']}
                    GAD7Data.forEach(element=>{
                       
                        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
                            gad7Row["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.GAD7),element.week]
                        }
                    })
                    weeks.forEach( (i) =>
                    {
                        if(gad7Row["week"+i.toString()]===undefined){gad7Row["week"+i.toString()]="-"}
                    })
                    
                    var moodRow={field:["Mood",GetMeasureFromType(OutcomeMeasureTypes.Mood),'title']}
                    moodData.forEach(element=>{
                       
                        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
                            moodRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.Mood),element.week]
                        }
                    })
                    weeks.forEach( (i) =>
                    {
                        if(moodRow["week"+i.toString()]===undefined){moodRow["week"+i.toString()]="-"}
                    })
                    
                    var PCSRow={field:["PCS",GetMeasureFromType(OutcomeMeasureTypes.PCS),'title']}
                    PCSData.forEach(element=>{
                       
                        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
                            PCSRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.PCS),element.week]
                        }
                    })
                    weeks.forEach( (i) =>
                    {
                        if(PCSRow["week"+i.toString()]===undefined){PCSRow["week"+i.toString()]="-"}
                    })

                    var PHQ9Row={field:["PHQ9",GetMeasureFromType(OutcomeMeasureTypes.PHQ9),'title']}
                    PHQ9Data.forEach(element=>{
                       
                        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
                            PHQ9Row["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.PHQ9),element.week]
                        }
                    })
                    weeks.forEach( (i) =>
                    {
                        if(PHQ9Row["week"+i.toString()]===undefined){PHQ9Row["week"+i.toString()]="-"}
                    })

                    var PSEQRow={field:["PSEQ",GetMeasureFromType(OutcomeMeasureTypes.PSEQ),'title']}
                    PSEQData.forEach(element=>{
                       
                        if(weeks.includes( !isNaN(parseInt(element.week))? parseInt(element.week):"M6")){
                            PSEQRow["week"+element.week.toString()]=[element.value.toString(),GetMeasureFromType(OutcomeMeasureTypes.PSEQ),element.week]
                        }
                    })
                    weeks.forEach( (i) =>
                    {
                        if(PSEQRow["week"+i.toString()]===undefined){PSEQRow["week"+i.toString()]="-"}
                    })
                    
                    body=[
                        (confidence?confidenceRow:null),
                        (FABQActivity?FABQARow:null),
                        (FABQWork?FABQWRow:null),
                        (FABQTotal?FABQTRow:null),
                        (GAD7?gad7Row:null),
                        (mood?moodRow:null),
                        (PCS?PCSRow:null),
                        (PHQ9?PHQ9Row:null),
                        (PSEQ?PSEQRow:null),  
                    
                    ]
                    body=body.filter(n => n);
                    if(body.length>0)
                        {
                    
                            if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
                                {
                                    doc.addPage();
                                    y=30;
                                }
                    
                            doc.autoTable({
                                pageBreak:'avoid',
                                body: [["Behavioral"]],
                                startY: y+=10 ,
                                theme: 'plain',
                                styles:{font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], halign: 'left', valign: 'middle', fontStyle:'bold'}
                                })
                            y = doc.lastAutoTable.finalY; 
                    
                    
                            var header= [         
                                { header:'Measure', dataKey: 'field'} 
                    
                                        ]
                            
                            weeks.forEach( (i,index) =>
                                (
                                    
                                    header.push({ header:(i!=="M6"?"Week " +i.toString():"6 Months Post"), dataKey: "week"+i.toString()})
                                    
                    
                                )
                            )
                            if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
                                {
                                    doc.addPage();
                                    y=30;
                                }
                            doc.autoTable({
                                rowPageBreak: 'avoid',

                                tableLineWidth: 1,
tableLineColor: "#eaecf0",
                    body: body,
                    willDrawCell: function (t) {
                      if (t.section === 'body') {
                        const cell = t.cell;
                        const text = cell.raw;
                        const colIndex= t.column.index;
                        cell.text = [];

                       // console.log(cell)
                      renderTable(doc, cell, text,colIndex,false);
                      }
                    },
                    bodyStyles: {lineColor: "#eaecf0",lineWidth: 1, fillColor:false, fontSize: 12, cellPadding: {top: 10,  bottom: 15, },  textColor:"#344054", halign:'center',valign:'middle',  },
                    headStyles:{fillColor:"#f9fafb", lineColor: "#eaecf0",lineWidth: 1, textColor:"#475467",halign:'center',valign:'middle' , lineColor: "#eaecf0", fontSize: 12},
                    columnStyles: {
                    0: {halign: 'left', fontSize: 10, minCellHeight: 61},
                    },
                    columns: header,
                    startY: y+=20,
                    theme: 'grid',
                               
                                   
                            })
                    
                        y = doc.lastAutoTable.finalY
                        }          


//Assessment
y+=30 
if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
    {
        doc.addPage();
        y=30;
    }
if(physical||behavioral||social||continuedNeed){
    if(y%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
        {
            doc.addPage();
            y=30;
        }
doc.setFontSize(12);  

    doc.setFillColor(234,236,240)
    doc.setDrawColor(234,236,240)

    doc.setTextColor(71,84,103)

      xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Assessment`) * doc.internal.getFontSize() / 2); 

    doc.line(40,y,((doc.internal.pageSize.width / 2) )-(37),y)
    doc.line(((doc.internal.pageSize.width / 2) )+(37),y,(pageWidth-40),y)
    doc.text(`Assessment`, xOffset, y+3);
        y+=10

       

if(physical&&physicalText.length>0)
{
    var col = [
        { header:"Physical Assessment", dataKey: 'goal'}, {header:'', dataKey:'detail' }
    ]
        doc.autoTable({
            pageBreak:'avoid',
    
            showHead: "firstPage",
            body: [[physicalText]],
            columns: col,
            startY: y ,
            theme: 'plain',
            bodyStyles:{fontSize: 10, font: "Inter", textColor:'#344054'},
            headStyles: {font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], fontStyle:'bold'},
       
           
               
                     })
    
    y = doc.lastAutoTable.finalY+10 
       
}

if(behavioral&&behavioralText.length>0)
    {
       

            var col = [
                { header:"Behavioral Assessment", dataKey: 'goal'}, {header:'', dataKey:'detail' }
            ]

            doc.autoTable({
                pageBreak:'avoid',
        
                showHead: "firstPage",
                body: [[behavioralText]],
                columns: col,
                    startY: y ,
                    theme: 'plain',
                    bodyStyles:{fontSize: 10, font: "Inter", textColor:'#344054'},
                    headStyles: {font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], fontStyle:'bold'},
               
                   
                         })
        
        y = doc.lastAutoTable.finalY+10 
        
        
    }

    if(social&&socialText.length>0)
        {
            

                var col = [
                    { header:"Social Assessment", dataKey: 'goal'}, {header:'', dataKey:'detail' }
                ]

                doc.autoTable({
                    pageBreak:'avoid',
            
                    showHead: "firstPage",
                    body: [[socialText]],
                    columns: col,
                    startY: y ,
                    theme: 'plain',
                    bodyStyles:{fontSize: 10, font: "Inter", textColor:'#344054'},
                    headStyles: {font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], fontStyle:'bold'},
        
                   
                       
                             })
            
            y = doc.lastAutoTable.finalY+10 
                       
        }

    if(continuedNeed&& continuedNeedText.length>0)
    {
        var col = [
            { header:'Continued Need', dataKey: 'goal'}, {header:'', dataKey:'detail' }
        ]
        doc.autoTable({
            pageBreak:'avoid',
    
            showHead: "firstPage",
            body: [[continuedNeedText]],
            columns: col,
            startY: y ,
            theme: 'plain',
            bodyStyles:{fontSize: 10, font: "Inter", textColor:'#344054'},
            headStyles: {font: "Inter", fontSize: 12, textColor:[50, 96, 158 ], fontStyle:'bold'},

           
               
                     })
    
    y = doc.lastAutoTable.finalY+10 
    }
}
///Plan
    
y+=10;

var body= [ { 
    summary: plan},

]

if(plan.length>0){
    if((y+30)%doc.internal.pageSize.height>(doc.internal.pageSize.height*(4/5)))
        {
            doc.addPage();
            y=30;
        }
    
        doc.setFontSize(12);  
    
        doc.setFillColor(234,236,240)
        doc.setDrawColor(234,236,240)
    
        doc.setTextColor(71,84,103)
    
          xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Plan`) * doc.internal.getFontSize() / 2); 
    
        doc.line(40,y,((doc.internal.pageSize.width / 2) )-(15),y)
        doc.line(((doc.internal.pageSize.width / 2) )+(15),y,(pageWidth-40),y)
        doc.text(`Plan`, xOffset, y+3);
            y+=10
 
    doc.autoTable({
        pageBreak:'avoid',

        showHead: "firstPage",
        body: body,
        startY: y ,
        theme: 'plain',
        bodyStyles:{fontSize: 10, font: "Inter", textColor:'#344054'}
       
           
                 })

y = doc.lastAutoTable.finalY+10
                }

        //phyisican response page
       if(oversight){ProviderSection(doc)}

    //page section
      NumberPages(doc);
        if(uploading)
        {
 var returnOutput = doc.output('datauristring',);

///json blob update
 data.enabled={};
 data.enabled.goalProgress=goalProgress;
 data.enabled.engagmentLevel=engagmentLevel;
 data.enabled.sessionsAttended=sessionsAttended;
 data.enabled.uniqueDays=uniqueDays;
 data.enabled.averageTime=averageTime;
 data.enabled.leftFlexion=leftFlexion;
 data.enabled.leftScaption=leftScaption;
 data.enabled.leftAbduction=leftAbduction;
 data.enabled.rightFlexion=rightFlexion;
 data.enabled.rightScaption=rightScaption;
 data.enabled.rightAbduction=rightAbduction
 data.enabled.pain=pain;
 data.enabled.mood=mood;
 data.enabled.activity=activity;
 data.enabled.sleep=sleep;
 data.enabled.confidence=confidence;
 data.enabled.returnToWork=returnToWork;
 data.enabled.medicationReduction=medReduction;
 data.enabled.oswestry=oswestry;
 data.enabled.Dash=Dash;
 data.enabled.PSEQ=PSEQ;
 data.enabled.PCS=PCS;
 data.enabled.UEFI=UEFI;
 data.enabled.PCSS= PCSS;
    data.enabled.WHODAS= WHODAS;
    data.enabled.NDI= NDI;
    data.enabled.GAD7= GAD7;
    data.enabled.PHQ9= PHQ9;
    data.enabled.KarunaRating= KarunaRating;
 
 data.enabled.social=social;
 data.enabled.physical=physical;
 data.enabled.behavioral=behavioral;
 data.enabled.FABQTotal= FABQTotal;
 data.enabled.FABQActivity= FABQActivity;
 data.enabled.FABQWork= FABQWork;
 data.customizations={};
 data.included_weeks=selectedWeek!=="M6"? selectedWeek:currentWeek;


 data.customizations.customSummary=summary;
 var tempGoals=[];
 goal.forEach(e=>{
     tempGoals.push([e.goal,e.detail]);
 })
 data.customizations.custumGoals=tempGoals;
 
 data.customizations.customDiscovery=discovery.split("\n\n").filter(function (el) {
     return el !=="";
   });

 data.customizations.customSocial=socialText;
 data.customizations.customPhysical=physicalText;
 data.customizations.customBehavioral=behavioralText;
data.customizations.customContinuedNeed= continuedNeedText;
data.customizations.customHistoryInjury = HistoryInjury

 data.plan= plan;
///json blob update

 var json={}
 json.report_json_data=data;
 json.report_pdf_data=returnOutput;
 API.put(`/outcome_reports/${reportId}/`,json ).then((response) => {
    if(response.code!==undefined)
                {
                    if(response.code==="ERR_BAD_RESPONSE")
                    {
                        throw new Error("Recieved bad "+response.code+" error response from API please try refreshing the page")
                        
                    }
                }
    if(response.status==undefined){
        throw new Error("debug point 1: "+response);
    }
    if(response.data==undefined){
        throw new Error("debug point 2: "+response);
    }
    API.get(`/outcome_reports/${reportId}/approve` ).then((response) => {
        if(response.code!==undefined)
        {
            if(response.code==="ERR_BAD_RESPONSE")
            {
                throw new Error("Recieved bad "+response.code+" error response from API please try refreshing the page")
                
            }
        }
        if(response.status==undefined){
            throw new Error("debug point 3: "+response);
        }
        if(response.data==undefined){
            throw new Error("debug point 4: "+response);
        }
        setErrors("");
        localStorage.setItem("success",patientName+(selectedWeek==="M6"?" 6 Months Post":" week "+selectedWeek.toString())+ " outcome report approved");

        history.push("/reports",{
            state: {
                message: patientName+" week "+selectedWeek.toString()+ " outcome report approved"
            }
        })

        
    }).catch(error => {
    console.error(error)
    setErrors(error.message);
    setLoadedProfile(true);
    })


}).catch(error => {
console.error(error)
setErrors(error.message);
setLoadedProfile(true);


})  


        }
        else
        {
        doc.save(`${patientName}_Outcomes_${formatDate(new Date() )}.pdf`);		
        setLoadedProfile(true);
        }
	}

	function isEmptyObject(obj) {
        for(var prop in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
          }
        }
        return true;
      }

    

    const changeNotification=(event)=>{
        if(event.target.value!==templateNotification){
            setTemplateNotification(event.target.value)
        }
    }

    function removeItemOnce(arr, value) {
        var index = arr.indexOf(value);
        if (index > -1) {
          arr.splice(index, 1);
        }
        return arr;
    }

    function removeItemAll(arr, value) {
        var i = 0;
        while (i < arr.length) {
          if (arr[i] === value) {
            arr.splice(i, 1);
          } else {
            ++i;
          }
        }
        return arr;
    }

    const RemoveSelectedImprovement=(measureType)=>{
        setSelectedImprovements((prev)=>{
            if(prev.includes(measureType))
            {
                removeItemAll(prev,measureType);
            }
            return prev;
        })
    }

    const AddSelectedImprovement=(measureType)=>{
        setSelectedImprovements((prev)=>{
            if(!prev.includes(measureType))
            {
                prev.push(measureType);
            }
            return prev;
        })
    }

    const HasSelectedImprovement=(measureType)=>{ return selectedImprovements.includes(measureType) }

    const ForceToggleMeasure=(measureType,value)=>{
        

        switch(measureType)
        {
            case OutcomeMeasureTypes.FABQActivity:
                setFABQActivity(value);
                break;
            case OutcomeMeasureTypes.FABQTotal:
                setFABQTotal(value);
                break;
            case OutcomeMeasureTypes.FABQWork:
                setFABQWork(value);
                break;
            case OutcomeMeasureTypes.PCS:
                setPCS(value);
                break;
            case OutcomeMeasureTypes.PSEQ:
                setPSEQ(value);
                break;
            case OutcomeMeasureTypes.PHQ9:
                return setPHQ9(value);
                break;
            case OutcomeMeasureTypes.GAD7:
                setGAD7(value);
                break;
            case OutcomeMeasureTypes.Oswestry:
                setOwestry(value);
                break;
            case OutcomeMeasureTypes.DASH:
                setDash(value);
                break;
            case OutcomeMeasureTypes.NDI:
                setNDI(value);
                break;
            case OutcomeMeasureTypes.WHODAS:
                setWHODAS(value);
                break;
            case OutcomeMeasureTypes.UEFI:
                setUEFI(value);
                break;
            case OutcomeMeasureTypes.Pain:
                setPain(value);
                break;
            case OutcomeMeasureTypes.PCSS:
                setPCSS(value);
                break;
            case OutcomeMeasureTypes.Activity:
                setActivity(value);
                break;
            case OutcomeMeasureTypes.Confidence:
                setConfidence(value);
                break;
            case OutcomeMeasureTypes.Sleep:
                setSleep(value);
                break;
            case OutcomeMeasureTypes.Mood:
                setMood(value);
                break;
            case OutcomeMeasureTypes.LowerFlexion:
                setLowerFlexion(value);
                break;
            case OutcomeMeasureTypes.LeftFlexion:
                setLeftFlexion(value);
                break;
            case OutcomeMeasureTypes.LeftAbduction:
                setLeftAbduction(value);
                break;
            case OutcomeMeasureTypes.LeftScaption:
                setLeftScaption(value);
                break;
            case OutcomeMeasureTypes.RightFlexion:
                setRightFlexion(value);
                break;
            case OutcomeMeasureTypes.RightAbduction:
                setRightAbduction(value);
                break;
            case OutcomeMeasureTypes.RightScaption:
                setRightScaption(value)
                break;
            case OutcomeMeasureTypes.KarunaRating:
                setKarunaRating(value);
                break;
            
        }
    }

    const GenerateKeyImprovementSection=(measureObject)=>
        {
            let values = measureObject.value
            let percent= measureObject.percent
            let measureHint =GetTooltipText(measureObject.type)
            if(values.length===0){return;}
            return <React.Fragment>
                            <div className='w-full inline-flex items-baseline '>
                                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                    <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(KarunaRatingData.length>0?"text-gray-600":"text-gray-400")}>
                                                <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                    type="checkbox"  checked={HasSelectedImprovement(measureObject.type) } onClick={(e)=>{
                                                        
                                                        if(!e.target.checked)
                                                        {
                                                            console.log(" remove improvement")
    
                                                            RemoveSelectedImprovement(measureObject.type)
                                                         //   ForceToggleMeasure(measureObject.type, false);
    
                                                        }
                                                        else
                                                        {
                                                            if(selectedImprovements.length>=5){return;}
    
                                                            console.log(" add improvement")
    
                                                            AddSelectedImprovement(measureObject.type)
                                                            ForceToggleMeasure(measureObject.type, true);
    
                                                        }
                                                        setImprovementToggle(!improvementToggle);
                                                        
    
                                                   //     ForceToggleMeasure(measureObject.type, !e.target.checked);
    
    
                                                    }} disabled={(selectedImprovements.Length>=5&&!HasSelectedImprovement(measureObject.type))} /> {measureObject.name}
                                    </label>
                                    { measureHint!==null&&MetricTooltip(measureHint)}
    
                                </div>
                                    <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                                   
                                    {
                                    isRomType(measureObject.type)?
                                    ( values.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{values[GetBestWeek(values,3)].value.toString()}{values[GetBestWeek(values,3)].week!==selectedWeek?WeekDecorator(values[GetBestWeek(values,3)].week):""}{values.length>1?changeDecorator(percent,values[0].week,UsePercentage(measureObject.type),LowerIsBetter(measureObject.type)):""}{values.length>0?IndicatorDecorator(GetIndicator( measureObject.type,values[GetBestWeek(values,3)].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>)
                                   :( values.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{values.slice(-1)[0].value.toString()}{values.slice(-1)[0].week!==selectedWeek?WeekDecorator(values.slice(-1)[0].week):""}{values.length>1?changeDecorator(percent,values[0].week,UsePercentage(measureObject.type),LowerIsBetter(measureObject.type)):""}{values.length>0?IndicatorDecorator(GetIndicator( measureObject.type,values.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>)
                                }
                                    </div>
                            </div>
    
            </React.Fragment>
        }

    const changeTemplateWeek=(event)=>{
        if(event.target.value!==templateWeeks){
            setTemplateWeeks(event.target.value)
        }
    }

    const GetBestWeek=(weeks, weekRange,nofilter=false)=>{
        
        if(nofilter)
        {
            weekRange=1;
        }
       

        var maxIndex=0;
        var maxVal=Number.MIN_SAFE_INTEGER;
        var count=0;

        for(var i=weeks.length-1; i>=0;i--)
        {
            if(count>=weekRange)
            {
                break;
            }
            if(weeks[i].value>maxVal)
            {

                maxVal=weeks[i].value;
                maxIndex=i;
            }
            count++;

        }
        return maxIndex;
    }

	const getPatientData = (week) => {
        let ImpComplete=[];

		API.get(`/outcome_reports/${reportId}/?json_data=${true}/`)
			.then(response => {
                console.log(response)

                if(response.code!==undefined)
                {
                    if(response.code==="ERR_BAD_RESPONSE")
                    {
                        setErrors("Recieved bad 500 error response from API please try refreshing the page")
                        setLoadedProfile(true);

                        return
                    }
                }
                setLoadedProfile(true);


                if(response.data["error"]!==undefined)
                {
                    
                    if(response.data.error.includes("No matching active Practice Better account found")||response.data.error.includes("must not start with 'changeme'"))
                    {
                        setErrors("Could not retrieve Practice Better data for this patient. Please make sure the patient's email address matches the one entered in Practice Better exactly.")
                        return;
                    }
                    else
                    {
                        
                        return;
                    }
                }
                else
                {
                    if(response.data.brand!==undefined && response.data.brand!=="KarunaHOME")
                    {
                        setIsKaruna(false);
                    }
                    var data =response.data.report_json_data;
                    
                    console.log(data);
                    if(data.program_type==="No Active Coaching Plan")
                    {
                       
                        setErrors("Cannot generate outcome report. Patient requires an active coaching plan to access the required data")
                        return;
                    }
                    else
                    {
                        setProgram(data.program_type)
                        setHasPlan(true);
                    }
                    if(data.current_coaching_plan_week<=0)
                    {
                        
                        
                    }
                    else
                    {
                        var week=-1;
                        setCurrentWeek(data.current_coaching_plan_week);
                        if(data.isFutureReport!==undefined&&data.isFutureReport===true)
                        {
                            setChangedWeek("M6");
                            setSelectedWeek("M6");
                            week="M6"
                        }
                        else
                        {
                        setChangedWeek(data.included_weeks!=="All"?data.included_weeks :data.current_coaching_plan_week)
                        setSelectedWeek(data.included_weeks!=="All"?data.included_weeks :data.current_coaching_plan_week)
                        week= data.included_weeks!=="All"?data.included_weeks :data.current_coaching_plan_week
                        }
                        
            
                        
                    }

                    if(data.payer_type!==undefined)
                    {
                        if(data.payer_type===2)
                        {
                            setPayerType(payerEnums.VA);
                        }
                        else if(data.payer_type===1)
                        {
                            setPayerType(payerEnums.Reclaim)
                        }
                        else
                        {
                            setPayerType(payerEnums.KarunaHome);
                        }
                    }

                    if(data.coach!==undefined)
                    {
                        setCoach(data.coach)
                    }
                    if(data.physician!==undefined)
                        {
                            setPhysician(data.physician)
                        }

                    if(data.has_physician_oversight_agreement!==undefined)
                    {
                        setOversight(data.has_physician_oversight_agreement);
                    }


                    if(data.subjective_outcomes!==undefined)
                    {
                        var selected= data.included_weeks!=="All"?data.included_weeks :data.current_coaching_plan_week;
                        var discoveries=[];
                        var goals=[];
                        var engage=[];
                        var prog=[];
                        var summary=[];
                        var tempDisc= Object.entries(data.subjective_outcomes);



                        tempDisc.forEach(temp=>
                        { 
                            if((week==="M6"&&temp[0]!==0)||(temp[0]>0&&temp[0]<=selected))
                            {
                                
                                if(temp[1].discoveries!==undefined)
                                {
                                    discoveries.push(temp[1].discoveries);
                                }
                                if(temp[1].program_goals!==undefined)
                                {
                                    if(temp[1].program_goals.goals!==undefined)
                                    {
                                       
                                        goals.push(Object.values(temp[1].program_goals.goals))
                                    }
                                }
                                
                                if(temp[1].overall_subjective_progress!==undefined)
                                {
                                    summary.push({week:!isNaN(parseInt(temp[0]))? parseInt(temp[0]):"M6",value:temp[1].overall_subjective_progress})
                                }
                                if(temp[1].level_of_engagement_in_program!==undefined)
                                {
                                    engage.push({week:!isNaN(parseInt(temp[0]))? parseInt(temp[0]):"M6",value:temp[1].level_of_engagement_in_program})
                                }
                                if(temp[1].perceived_progress_towards_goals!==undefined)
                                {
                                    prog.push({week:!isNaN(parseInt(temp[0]))? parseInt(temp[0]):"M6",value:temp[1].perceived_progress_towards_goals})
                                }
                                
                            }
                        }
                        )

                        setSummary(data.customizations.customSummary);
                        if(data.customizations.customHistoryInjury!==undefined)
                        {
                            setHistoryInjury(data.customizations.customHistoryInjury);
                        }
                        AddGoalManual(data.customizations.custumGoals,true);
                        AddDiscoveryManual(data.customizations.customDiscovery,true);
                        setSummaryOriginal(summary);
                        setGoalOriginal(goals);
                        setDiscoveryOriginal(discoveries);
                        

                      
                        if(prog.length>0)
                        {
                            setRawGoalProgressData(prog[prog.length-1]);
                        }
                        else
                        {
                            setRawGoalProgressData(null)
                        }
                       
                        if(engage.length>0)
                        {
                            setRawEngagementLevelData(engage[engage.length-1]);
                        }
                        else
                        {
                            setRawEngagementLevelData(null);
                        }


                    }

                    if(data.condition_history!==undefined)
                        {
                            var history=[];
                            var histories = Object.entries(data.condition_history);
                            histories.forEach(element=>{
                                if((week==="M6" && (element[0]!==0))||(element[0]>0&&element[0]<=selected)){
                                    if(element[1].length>0)
                                    {
                                        var text="";
                                        element[1].forEach(b=>{
                                            if(b.charAt(b.length-1) != "."){b+="."}
                                            
                                            text+=b+"\r\n";
                                        
                                        }
                                        )
                                        history.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:text});                            }
                                   
                                   
                                    }
                            })
                            if(history.length>0)
                                {
                                    setHistoryInjuryOriginal(history[history.length-1].value.trim());
                                }
                                else
                                {
                                    setHistoryInjuryOriginal("");
                                }
                           
                              
    
                        }
                    
                    if(data.objective_outcomes!==undefined){
                        var selected= data.included_weeks!=="All"?data.included_weeks :data.current_coaching_plan_week;


                        if(data.objective_outcomes.adherence!==undefined)
                        {
                            if(data.objective_outcomes.adherence.sessions_attended!==undefined   )
                            {
                            setRawSessionsAttendedData(data.objective_outcomes.adherence.sessions_attended);
                            }
                            else
                            {
                                setRawSessionsAttendedData(null);

                            }

                            if(data.objective_outcomes.adherence.unique_day_vet_usage_by_week!==undefined && Object.keys(data.objective_outcomes.adherence.unique_day_vet_usage_by_week).length>0  )
                            {
                                var values = Object.keys(data.objective_outcomes.adherence.unique_day_vet_usage_by_week);
                                var sum =0;
                        
                                values.forEach(e=>{ if(parseInt(e)>0 && parseInt(e)<=selected){sum+=data.objective_outcomes.adherence.unique_day_vet_usage_by_week[e];}});
                                setRawUniqueDaysData((sum / selected).toFixed(1));
                            }

                            if(data.objective_outcomes.adherence.vet_seconds_by_week!==undefined && Object.keys(data.objective_outcomes.adherence.vet_seconds_by_week).length>0 )
                            {
                                var values = Object.keys(data.objective_outcomes.adherence.vet_seconds_by_week);
                                var sum =0;
                                values.forEach(e=>{ if(parseInt(e)>0 && parseInt(e)<=selected){sum+=data.objective_outcomes.adherence.vet_seconds_by_week[e];}});
                                
                                var avg =(sum / selected);    
                                var duration = `${(moment.duration(avg, 'seconds').hours()+(moment.duration(avg, 'seconds').days()*24))  > 0 ? ((moment.duration(avg, 'seconds').hours()+(moment.duration(avg, 'seconds').days()*24)) + " h and ") : ""}${moment.duration(avg, 'seconds').minutes()?moment.duration(avg, 'seconds').minutes() +" m ":""}  ${moment.duration(avg, 'seconds').seconds()>0? moment.duration(avg, 'seconds').seconds()+" s":""}`
                                setRawAverageTimeData(duration)
                                
                            }

                        }
                        
                        if(data.objective_outcomes.rom_improvements!==undefined)
                        {
                            var leftFlex=[];
                            var leftAbd=[];
                            var leftSca=[];
                            var rightFlex=[];
                            var rightAbd=[];
                            var rightSca=[];
                            var lowerFlex=[];
                            var selected= data.included_weeks!=="All"?data.included_weeks :data.current_coaching_plan_week;

                            Object.entries(data.objective_outcomes.rom_improvements).forEach(element=>{
                                var weekx= element[0];
                                if(weekx>0&&weekx<=selected){
                                Object.entries(data.objective_outcomes.rom_improvements[weekx]).forEach(
                                    e=>{
                                        switch(e[0])
                                        {
                                            case "upper_extremity":
                                                Object.entries(e[1]).forEach(arm=>{
                                                    if(arm[0]==="left")
                                                    {
                                                        if(arm[1].abduction!==undefined)
                                                        {
                                                            if(arm[1].abduction.max_measurement>-1){
                                                            leftAbd.push({week:parseInt(weekx),value:Math.round(arm[1].abduction.max_measurement)});
                                                            }
                                                        }
                                                        if(arm[1].scaption!==undefined)
                                                        {
                                                            if(arm[1].scaption.max_measurement>-1){
                                                            leftSca.push({week:parseInt(weekx),value:Math.round(arm[1].scaption.max_measurement)});
                                                            }
                                                        }
                                                        if(arm[1].flexion!==undefined)
                                                        {
                                                            if(arm[1].flexion.max_measurement>-1){
                                                            leftFlex.push({week:parseInt(weekx),value:Math.round(arm[1].flexion.max_measurement)});
                                                            }
                                                        }
                                                    }
                                                    if(arm[0]==="right")
                                                    {
                                                        if(arm[1].abduction!==undefined)
                                                        {
                                                            if(arm[1].abduction.max_measurement>-1){
                                                            rightAbd.push({week:parseInt(weekx),value:Math.round(arm[1].abduction.max_measurement)});
                                                            }
                                                        }
                                                        if(arm[1].scaption!==undefined)
                                                        {
                                                            if(arm[1].scaption.max_measurement>-1){
                                                            rightSca.push({week:parseInt(weekx),value:Math.round(arm[1].scaption.max_measurement)});
                                                            }

                                                        }
                                                        if(arm[1].flexion!==undefined)
                                                        {
                                                            if(arm[1].flexion.max_measurement>-1){
                                                            rightFlex.push({week:parseInt(weekx),value:Math.round(arm[1].flexion.max_measurement)});
                                                            }

                                                        }
                                                    }
                                                })
                                                break;
                                            case "lower_back":
                                                Object.entries(e[1]).forEach(metric=>{
                                                    
                                                    if(metric[0]==="flexion")
                                                    {
                                                        if(metric[1].max_measurement>-1){
                                                        lowerFlex.push({week:parseInt(weekx),value:Math.round(metric[1].max_measurement)});
                                                        }
                                                    }
                                                })
                                                break;
                                        }
                                    }
                                )
                                }
                            
                                
                            })

                            
                            lowerFlex=  RemoveDuplicateWeeks(lowerFlex,LowerIsBetter(OutcomeMeasureTypes.lowerFlexion))
                            leftAbd=  RemoveDuplicateWeeks(leftAbd,LowerIsBetter(OutcomeMeasureTypes.leftAbduction))
                            leftSca=  RemoveDuplicateWeeks(leftSca,LowerIsBetter(OutcomeMeasureTypes.leftScaption))
                             leftFlex= RemoveDuplicateWeeks(leftFlex,LowerIsBetter(OutcomeMeasureTypes.leftFlexion))
                            rightAbd=  RemoveDuplicateWeeks(rightAbd,LowerIsBetter(OutcomeMeasureTypes.RightAbduction))
                            rightSca=  RemoveDuplicateWeeks(rightSca,LowerIsBetter(OutcomeMeasureTypes.RightScaption))
                           rightFlex=   RemoveDuplicateWeeks(rightFlex,LowerIsBetter(OutcomeMeasureTypes.RightFlexion))
                            var hascheck=false
                            
                            if(data.objective_outcomes.rom_first_measurements!==undefined)
                            {
                            setUseCheck(true);
                                if(data.objective_outcomes.rom_first_measurements.lower_back!==undefined &&data.objective_outcomes.rom_first_measurements.lower_back.flexion!==undefined )
                                {
                                    if(lowerFlex.length>0){
                                    lowerFlex.unshift({week:parseInt(data.objective_outcomes.rom_first_measurements.lower_back.flexion.plan_week),value:Math.round(data.objective_outcomes.rom_first_measurements.lower_back.flexion.first_measurement)});
                                    setLowerFlexionCheck(true);
                                    setUseCheck(true);
                                    hascheck=true;
                                    }


                                }
                                if(data.objective_outcomes.rom_first_measurements.upper_extremity!==undefined  )
                                {
                                   if(data.objective_outcomes.rom_first_measurements.upper_extremity.left!==undefined )
                                   {
                                        var left =data.objective_outcomes.rom_first_measurements.upper_extremity.left;
                                        if(left.abduction!==undefined)
                                        {
                                            if(leftAbd.length>0){
                                                leftAbd.unshift({week:parseInt(left.abduction.plan_week),value:Math.round(left.abduction.first_measurement)});
                                            setLeftAbductionCheck(true);
                                            setUseCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        if(left.scaption!==undefined)
                                        {
                                            if(leftSca.length>0){

                                            leftSca.unshift({week:parseInt(left.scaption.plan_week),value:Math.round(left.scaption.first_measurement)});
                                            setLeftScaptionCheck(true);
                                            setUseCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        if(left.flexion!==undefined)
                                        {
                                            if(leftFlex.length>0){

                                            leftFlex.unshift({week:parseInt(left.flexion.plan_week),value:Math.round(left.flexion.first_measurement)});
                                            setLeftFlexionCheck(true);
                                            setUseCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        
                                   }
                                   if(data.objective_outcomes.rom_first_measurements.upper_extremity.right!==undefined )
                                   {
                                        var right = data.objective_outcomes.rom_first_measurements.upper_extremity.right;
                                        if(right.abduction!==undefined)
                                        {
                                            if(rightAbd.length>0){

                                            rightAbd.unshift({week:parseInt(right.abduction.plan_week),value:Math.round(right.abduction.first_measurement)});
                                            setRightAbductionCheck(true);
                                            setUseCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        if(right.scaption!==undefined)
                                        {
                                            if(rightSca.length>0){

                                            rightSca.unshift({week:parseInt(right.scaption.plan_week),value:Math.round(right.scaption.first_measurement
                                            )});
                                            setRightScaptionCheck(true);
                                            setUseCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        if(right.flexion!==undefined)
                                        {
                                            if(rightFlex>0){
                                            rightFlex.unshift({week:parseInt(right.flexion.plan_week),value:Math.round(right.flexion.first_measurement)});
                                            setRightFlexionCheck(true);
                                            setUseCheck(true);
                                            hascheck=true;
                                            }
                                        }

                                   }

                                }
                            }
                            
                            if(rightAbd.length===0&&rightSca.length===0&&rightFlexion.length===0&&leftAbd.length===0&&leftSca.length===0&&leftFlex.length===0&&lowerFlex===0)
                            {
                            setUseCheck(true);
                            }

                            setRightAbductionData(rightAbd);
                            setRightScaptionData(rightSca);
                            setRightFlexionData(rightFlex);
                            setLeftAbductionData(leftAbd);
                            setLeftScaptionData(leftSca);
                            setLeftFlexionData(leftFlex);
                            setLowerFlexionData(lowerFlex)

                            if(rightAbd.length>0)
                            {
                                setRightAbductionImp(getPercent(rightAbd[0].value,rightAbd[GetBestWeek(rightAbd,3,!hascheck)].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.RightAbduction,"Right Shoulder Abduction", rightAbd,getPercent(rightAbd[0].value,rightAbd[GetBestWeek(rightAbd,3,!hascheck)].value),GetBestWeek(rightAbd,3,!hascheck)))

                            }

                            if(rightSca.length>0)
                            {
                                
                                setRightScaptionImp(getPercent(rightSca[0].value,rightSca[GetBestWeek(rightSca,3,!hascheck)].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.RightScaption,"Right Shoulder Scaption", rightSca,getPercent(rightSca[0].value,rightSca[GetBestWeek(rightSca,3,!hascheck)].value),GetBestWeek(rightSca,3,!hascheck)))

                            }
                            if(rightFlex.length>0)
                            {
                                setRightFlexionImp(getPercent(rightFlex[0].value,rightFlex[GetBestWeek(rightFlex,3,!hascheck)].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.RightFlexion,"Right Shoulder Flexion", rightFlex,getPercent(rightFlex[0].value,rightFlex[GetBestWeek(rightFlex,3,!hascheck)].value),GetBestWeek(rightFlex,3,!hascheck)))

                            }
                            if(leftAbd.length>0)
                            {
                                setLeftAbductionImp(getPercent(leftAbd[0].value,leftAbd[GetBestWeek(leftAbd,3,!hascheck)].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.LeftAbduction,"Left Shoulder Abduction", leftAbd,getPercent(leftAbd[0].value,leftAbd[GetBestWeek(leftAbd,3,!hascheck)].value),GetBestWeek(leftAbd,3,!hascheck)))

                            }
                            if(leftSca.length>0)
                            {
                                setLeftScaptionImp(getPercent(leftSca[0].value,leftSca[GetBestWeek(leftSca,3,!hascheck)].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.LeftScaption,"Left Shoulder Scaption", leftSca,getPercent(leftSca[0].value,leftSca[GetBestWeek(leftSca,3,!hascheck)].value),GetBestWeek(leftSca,3,!hascheck)))

                            }
                            if(leftFlex.length>0)
                            {

                                setLeftFlexionImp(getPercent(leftFlex[0].value,leftFlex[GetBestWeek(leftFlex,3,!hascheck)].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.LeftFlexion,"Left Shoulder Flexion", leftFlex,getPercent(leftFlex[0].value,leftFlex[GetBestWeek(leftFlex,3,!hascheck)].value),GetBestWeek(leftFlex,3,!hascheck)))

                            }
                            if(lowerFlex.length>0)
                            {
                                setLowerFlexionImp(getPercent(lowerFlex[0].value,lowerFlex[GetBestWeek(lowerFlex,3,!hascheck)].value,90));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.LowerFlexion,"Lower Back Flexion (Estimate)", lowerFlex,getPercent(lowerFlex[0].value,lowerFlex[GetBestWeek(lowerFlex,3,!hascheck)].value,90),GetBestWeek(lowerFlex,3,!hascheck)));
                            }
                        }
                        else
                        {
                        setUseCheck(true);
                        }

                        if(data.objective_outcomes.measurements!==undefined)
                        {
                        
                            var pain=[];
                            var mood=[];
                            var confidence=[];
                            var activity=[];
                            var sleep=[];
                            var osw=[];
                            var dash=[];
                            var pcs=[];
                            var pseq=[];
                            var uefi =[];
                            var FABQT =[];
                            var FABQA =[];
                            var FABQW =[];
                            var pcss=[];
                            var whodas=[];
                            var gad7=[];
                            var phq9=[];
                            var ndi=[];

                        var meds=[];
                        var work=[];
                        
                        var selected= data.included_weeks!=="All"?data.included_weeks :data.current_coaching_plan_week;


                            Object.entries(data.objective_outcomes.measurements).forEach(element=>{
                                element[1].forEach(e=>{
                                    if((week==="M6"&&element[0]!==0)||(element[0]>0&&element[0]<=selected)){
                                        switch(true)
                                        {
                                         case e.label.includes('PCSS'):
                                            pcss.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                         case e.label.includes('WHODAS'):
                                            whodas.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseFloat(e.value)})
                                            break;
                                         case e.label.includes('PHQ9'):
                                            phq9.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                         case e.label.includes('GAD-7'):
                                            gad7.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                         case e.label.includes('NDI'):
                                            ndi.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('Sleep'):
                                            sleep.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                            
                                        case e.label.includes('Confidence'):
                                            confidence.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('Activity')&&!e.label.includes('FABQ'):
                                            activity.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('Pain'):
                                            pain.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('Mood'):
                                            mood.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('PSEQ Score'):
                                            pseq.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('DASH Score'):
                                            var trueScore = ((e.value / 30) - 1) * 25
                                            dash.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(trueScore)})
                                            break;
                                        case e.label.includes('PCS Score'):
                                                pcs.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('UEFI Score'):
                                                uefi.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)});
                                                break;
                                        case e.label.includes('Oswestry Score'):
                                                osw.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('Work Status'):
                                                work.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('Medication'):
                                                meds.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('FABQ-Total'):
                                                FABQT.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('FABQ-Work'):
                                                FABQW.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('FABQ-Physical'):
                                                FABQA.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:parseInt(e.value)})
                                                break;
                                        
                                            
                                    }
                                    }
                                })
                                
                            })

                            pain=   RemoveDuplicateWeeks(pain,LowerIsBetter(OutcomeMeasureTypes.Pain))
                            mood=   RemoveDuplicateWeeks(mood,LowerIsBetter(OutcomeMeasureTypes.Mood))
                            confidence=   RemoveDuplicateWeeks(confidence,LowerIsBetter(OutcomeMeasureTypes.Confidence))
                            activity=   RemoveDuplicateWeeks(activity,LowerIsBetter(OutcomeMeasureTypes.Activity))
                            sleep=   RemoveDuplicateWeeks(sleep,LowerIsBetter(OutcomeMeasureTypes.Sleep))
                            osw=   RemoveDuplicateWeeks(osw,LowerIsBetter(OutcomeMeasureTypes.Oswestry))
                            dash=   RemoveDuplicateWeeks(dash,LowerIsBetter(OutcomeMeasureTypes.Dash))
                            pcs=   RemoveDuplicateWeeks(pcs,LowerIsBetter(OutcomeMeasureTypes.PCS))
                            pseq=   RemoveDuplicateWeeks(pseq,LowerIsBetter(OutcomeMeasureTypes.PSEQ))
                            uefi=   RemoveDuplicateWeeks(uefi,LowerIsBetter(OutcomeMeasureTypes.UEFI))
                            FABQT=   RemoveDuplicateWeeks(FABQT,LowerIsBetter(OutcomeMeasureTypes.FABQTotal))
                            FABQA=   RemoveDuplicateWeeks(FABQA,LowerIsBetter(OutcomeMeasureTypes.FABQActivity))
                            FABQW=   RemoveDuplicateWeeks(FABQW,LowerIsBetter(OutcomeMeasureTypes.FABQWork))
                            pcss=   RemoveDuplicateWeeks(pcss,LowerIsBetter(OutcomeMeasureTypes.PCSS))
                            whodas=   RemoveDuplicateWeeks(whodas,LowerIsBetter(OutcomeMeasureTypes.WHODAS))
                            gad7=   RemoveDuplicateWeeks(gad7,LowerIsBetter(OutcomeMeasureTypes.GAD7))
                            phq9=   RemoveDuplicateWeeks(phq9,LowerIsBetter(OutcomeMeasureTypes.PHQ9))
                            ndi=   RemoveDuplicateWeeks(ndi,LowerIsBetter(OutcomeMeasureTypes.NDI))
                            
                            setNDIData(ndi);
                            setGAD7Data(gad7);
                            setPCSSData(pcss);
                            setWHODASData(whodas);
                            setPHQ9Data(phq9);
                            setSleepData(sleep);
                            setOwestryData(osw);
                            setDashData(dash);
                            setActivityData(activity);
                            setPCSData(pcs);
                            setPSEQData(pseq);
                            setConfidenceData(confidence);
                            setMoodData(mood);
                            setPainData(pain);
                            setUEFIData(uefi); 
                            setFABQActivityData(FABQA);
                            setFABQTotalData(FABQT);
                            setFABQWorkData(FABQW);
                            if(work.length>0){
                                
                            setReturnToWorkData(work);
                            }
                            if(meds.length>0){
                            setMedReductionData(meds);
                            setMedImp(GetMedReductionResult(meds))
                            }
                            
                        
                            if(sleep.length>0)
                            {
                                setSleepImp(getDifference(sleep[0].value,sleep[sleep.length-1].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.Sleep,"Sleep", sleep,getDifference(sleep[0].value,sleep[sleep.length-1].value)))

                            }
                            if(osw.length>0)
                            {
                                setOwestryImp(getDifference(osw[0].value,osw[osw.length-1].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.Oswestry,"Oswestry", osw,getDifference(osw[0].value,osw[osw.length-1].value)))

                            }
                            if(dash.length>0)
                            {
                                setDashImp(getDifference(dash[0].value,dash[dash.length-1].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.DASH,"DASH", dash,getDifference(dash[0].value,dash[dash.length-1].value)))

                            }
                            if(activity.length>0)
                            {
                                setActivityImp(getDifference(activity[0].value,activity[activity.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.Activity,"Activity", activity,getDifference(activity[0].value,activity[activity.length-1].value)));
                            }
                            if(pcs.length>0)
                            {
                                setPCSImp(getDifference(pcs[0].value,pcs[pcs.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.PCS,"PCS", pcs,getDifference(pcs[0].value,pcs[pcs.length-1].value)));
                            }
                            if(pseq.length>0)
                            {
                                setPSEQImp(getDifference(pseq[0].value,pseq[pseq.length-1].value))
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.PSEQ,"PSEQ", pseq,getDifference(pseq[0].value,pseq[pseq.length-1].value)));
                            }
                            if(confidence.length>0)
                            {
                                setConfidenceImp(getDifference(confidence[0].value,confidence[confidence.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.Confidence,"Confidence", confidence,getDifference(confidence[0].value,confidence[confidence.length-1].value)))

                            }
                            if(mood.length>0)
                            {
                                setMoodImp(getDifference(mood[0].value,mood[mood.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.Mood,"Mood", mood,getDifference(mood[0].value,mood[mood.length-1].value)))

                            }
                            if(pain.length>0)
                            {
                                setPainImp(getDifference(pain[0].value,pain[pain.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.Pain,"Pain", pain,getDifference(pain[0].value,pain[pain.length-1].value)))

                            }

                            if(uefi.length>0)
                            {

                                setUEFIImp(getDifference(uefi[0].value,uefi[uefi.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.UEFI,"UEFI", uefi,getDifference(uefi[0].value,uefi[uefi.length-1].value)))

                            }
                            if(FABQT.length>0)
                            {
                                setFABQTotalImp(getDifference(FABQT[0].value,FABQT[FABQT.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.FABQTotal,"FABQ Total", FABQT,getDifference(FABQT[0].value,FABQT[FABQT.length-1].value)))

                            }

                            if(FABQA.length>0)
                            {
                                setFABQActivityImp(getDifference(FABQA[0].value,FABQA[FABQA.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.FABQActivity,"FABQ Activity", FABQA,getDifference(FABQA[0].value,FABQA[FABQA.length-1].value)))

                            }

                            if(FABQW.length>0)
                            {
                                setFABQWorkImp(getDifference(FABQW[0].value,FABQW[FABQW.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.FABQWork,"FABQ Work", FABQW,getDifference(FABQW[0].value,FABQW[FABQW.length-1].value)))

                            }  
                            if(pcss.length>0)
                            {
                                setPCSSImp(getDifference(pcss[0].value,pcss[pcss.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.PCSS,"PCSS", pcss,getDifference(pcss[0].value,pcss[pcss.length-1].value)))

                            }  
                             if(ndi.length>0)
                            {
                                setNDIImp(getDifference(ndi[0].value,ndi[ndi.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.NDI,"NDI", ndi,getDifference(ndi[0].value,ndi[ndi.length-1].value)))

                            } 
                             if(whodas.length>0)
                            {
                                setWHODASImp(roundTo( getDifference(whodas[0].value,whodas[whodas.length-1].value),2));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.WHODAS,"WHODAS", whodas,roundTo( getDifference(whodas[0].value,whodas[whodas.length-1].value),2)))

                            } 
                             if(phq9.length>0)
                            {
                                setPHQ9Imp(getDifference(phq9[0].value,phq9[phq9.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.PHQ9,"PHQ9", phq9,getDifference(phq9[0].value,phq9[phq9.length-1].value)))

                            } 
                            if(gad7.length>0)
                            {
                                setGAD7Imp(getDifference(gad7[0].value,gad7[gad7.length-1].value));
                                ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.GAD7,"GAD7", gad7,getDifference(gad7[0].value,gad7[gad7.length-1].value)));

                            } 
                            
                        }

                        if(data.objective_outcomes.ratings!==undefined)
                            {
                              let ratings =[]  
                              var tempDisc= Object.entries(data.objective_outcomes.ratings);
                              tempDisc.forEach(temp=>{
                                ratings.push({week:!isNaN(parseInt(temp[0]))? parseInt(temp[0]):"M6",value:temp[1]})
    
                              })
                              setKarunaRatingData(ratings)
                              if(ratings.length>0)
                                {
                                    setKarunaRatingImp(getDifference(ratings[0].value,ratings[ratings.length-1].value))
                                    ImpComplete.push(GenerateImprovmentObject(OutcomeMeasureTypes.KarunaRating,"Karuna Ratings", ratings,getDifference(ratings[0].value,ratings[ratings.length-1].value)))

                                }
    
                            }

                    }

                    if(data.assessments!==undefined)
                    {

                        var physical=[];
                        var behave=[];
                        var social=[];
                        var assessments = Object.entries(data.assessments);
                        assessments.forEach(element => {
                            if((week==="M6" && (element[0]!==0))||(element[0]>0&&element[0]<=selected)){

                            if(element[1].physical!==undefined&&element[1].physical.length>0)
                            {
                                var text="";
                                element[1].physical.forEach(b=>{
                                    if(b.charAt(b.length-1) != "."){b+="."}
                                    text+=b+"\r\n";
                                
                                }
                                )
                                physical.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:text});                            }
                           
                            if(element[1].behavioral!==undefined&&element[1].behavioral.length>0)
                            {
                                var text="";
                                element[1].behavioral.forEach(b=>{
                                    if(b.charAt(b.length-1) != "."){b+="."}
                                    text+=b+"\r\n";
                                
                                }
                                )
                                behave.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:text});

                            }
                            
                            if(element[1].social!==undefined&&element[1].social.length>0)
                            {
                                var text="";
                                element[1].social.forEach(b=>{
                                    if(b.charAt(b.length-1) != "."){b+="."}
                                    text+=b+"\r\n";
                                
                                }
                                )
                                social.push({week:!isNaN(element[0])? parseInt(element[0]):"M6",value:text});
                            }
                            }
                        });
                      
                        

                        if(physical.length>0)
                        {
                            setPhysicalTextOriginal(physical[physical.length-1].value);
                        }
                        else
                        {
                            setPhysicalTextOriginal("");
                        }

                        if(behave.length>0)
                        {
                            setBehavioralTextOriginal(behave[behave.length-1].value);
                        }
                        else
                        {
                            setBehavioralTextOriginal("");
                        }


                        if(social.length>0)
                        {
                            setSocialTextOriginal(social[social.length-1].value);
                        }
                        else
                        {
                            setSocialTextOriginal("");
                        }

                        setPhysicalText(data.customizations.customPhysical);
                        setBehavioralText(data.customizations.customBehavioral);
                        setSocialText(data.customizations.customSocial);
                        if(data.customizations.customContinuedNeed!==undefined){
                        setContinuedNeedText(data.customizations.customContinuedNeed);
                        }

                        

                       
                        
                        

                    }

                    if(data.plan!==undefined)
                    {
                        setPlan(data.plan)
                    }


                    if(data.dob!==undefined)
                    {
                    setDOB(moment( data.dob).format("MM/DD/YYYY"))
                    }
                    if(data.claim_number!==undefined)
                    {
                        setClaimNumber(data.claim_number)
                    }
                    if(data.date_of_injury!==undefined)
                        {
                            if(data.date_of_injury!=="Unknown"){
                            setDOI(  moment( data.date_of_injury).format("MM/DD/YYYY")  )
                            }
                            else
                            {
                                setDOI(data.date_of_injury)
                            }
                        }
                    if(data.preparer!==undefined)
                    {
                        setPreparer(data.preparer)
                    }
                    if(data.date!==undefined)
                    {
                        let dateMod= moment(data.date).format("MM/DD/YYYY");
                        console.log(dateMod)
                        setPrepareDate(dateMod)
                    }
                    if(data.patient!==undefined){
                        setPatientNameCheck(data.patient)
                        setPatientId(data.patient_id);
                        if(data.payer_type===undefined){
				    setPatientName(data.patient);
                        }
                        else
                        {
                            if(data.payer_type===2)
                            {
                                setPatientName("Patient")
                            }
                            else
                            {
                                setPatientName(data.patient);

                            }
                        }
                    }
                    ImpComplete.sort((a, b) => b.normalizedPercent - a.normalizedPercent);

                    if(data.customizations.selectedImp===undefined){
                        let index=0;
                        let selectedImp = ImpComplete&&ImpComplete.map((option)=>{
                            if(option.ImprovedValue>0&&index<5)
                            {
                                index++;
                                return option.type;
                            }
                        })
                        selectedImp=selectedImp.filter(n => n);
                        setSelectedImprovements(selectedImp)
                    }
                    else
                    {
                        setSelectedImprovements(data.customizations.selectedImp)

                    }

                   


                    setImprovementsComplete(ImpComplete);

                    setData(data);


                }
				
			})
			.catch(error => 
                {
                    console.log(error)
                    setErrors(error.message)
                    

                }
                );

        
	}

   const DeleteReport= async()=>{ 
    SetLoadingMessage("Loading, Deleting Outcome Report...")
    await API.get(`/outcome_reports/${reportId}/delete` ).then((response) => {
        setErrors("");
        localStorage.setItem("success",(selectedWeek==="M6"?" 6 Months Post": patientName+" week "+selectedWeek.toString())+ " outcome report deleted");

       history.push("/reports",{
            state: {
                message: patientName+" week "+selectedWeek.toString()+ " outcome report deleted"
            }
        });
        
    }).catch(error => {
    console.error(error)
    setErrors(error.message);
    setLoadedProfile(true);

    
    })
        
   }


   const DeleteWarning= ()=>{

    let text = "Confirm Delete Report\n\nAre you sure you want to delete this outcome report? There is no way to recover it once deleted!\n\nPress OK to delete or Cancel to return to editing.";
    if (window.confirm(text) === true)
    {
        setLoadedProfile(false);
        DeleteReport();     
    } 

   }

   const  SaveReportDraft= async ()=>{
    setErrors("");

    data.enabled={};
    data.enabled.goalProgress=goalProgress;
    data.enabled.engagmentLevel=engagmentLevel;
    data.enabled.sessionsAttended=sessionsAttended;
    data.enabled.uniqueDays=uniqueDays;
    data.enabled.averageTime=averageTime;
    data.enabled.leftFlexion=leftFlexion;
    data.enabled.leftScaption=leftScaption;
    data.enabled.leftAbduction=leftAbduction;
    data.enabled.rightFlexion=rightFlexion;
    data.enabled.rightScaption=rightScaption;
    data.enabled.rightAbduction=rightAbduction
    data.enabled.lowerFlexoion = lowerFlexion;
    data.enabled.pain=pain;
    data.enabled.mood=mood;
    data.enabled.activity=activity;
    data.enabled.sleep=sleep;
    data.enabled.confidence=confidence;
    data.enabled.returnToWork=returnToWork;
    data.enabled.medicationReduction=medReduction;
    data.enabled.oswestry=oswestry;
    data.enabled.Dash=Dash;
    data.enabled.PSEQ=PSEQ;
    data.enabled.PCS=PCS;
    data.enabled.UEFI=UEFI;
    data.enabled.PCSS= PCSS;
    data.enabled.WHODAS= WHODAS;
    data.enabled.NDI= NDI;
    data.enabled.GAD7= GAD7;
    data.enabled.PHQ9= PHQ9;
    data.enabled.KarunaRating= KarunaRating;

    data.enabled.social=social;
    data.enabled.physical=physical;
    data.enabled.behavioral=behavioral;
    data.enabled.FABQTotal= FABQTotal;
    data.enabled.FABQActivity= FABQActivity;
    data.enabled.FABQWork= FABQWork;
    data.customizations={};
    data.included_weeks=selectedWeek!=="M6"? selectedWeek:currentWeek;


    data.customizations.customSummary=summary;
    var tempGoals=[];
    goal.forEach(e=>{
        tempGoals.push([e.goal,e.detail]);
    })
    data.customizations.custumGoals=tempGoals;
    
    data.customizations.customDiscovery=discovery.split("\n\n").filter(function (el) {
        return el !=="";
      });

    data.customizations.customSocial=socialText;
    data.customizations.customPhysical=physicalText;
    data.customizations.customBehavioral=behavioralText;

        data.customizations.customContinuedNeed= continuedNeedText;
        data.customizations.customHistoryInjury = HistoryInjury

        data.customizations.selectedImp= selectedImprovements;

    data.plan= plan;

    var json={}
    json.report_json_data=data;

console.log(json)
    await API.put(`/outcome_reports/${reportId}/`,json ).then((response) => {
        if(response.code!==undefined)
        {
            if(response.code==="ERR_BAD_RESPONSE")
            {
                throw new Error("Recieved bad "+response.code+" error response from API please try refreshing the page")
                
            }
        }
        if(response.status==undefined){
            throw new Error(response);
        }
        if(response.data==undefined){
            throw new Error(response);
        }
       // window.location.reload(false);
        localStorage.setItem("success","Saved Outcome Report" );
        window.location.reload(false);
       //setRefresh(true);


        
    }).catch(error => {
        console.error(error)
        setLoadedProfile(true);

        setErrors(error.message);
        setLoadedProfile(true);


    })  



}

   const SaveWarning= ()=>{

    let text = "Confirm Save Report Draft\n\nAre you sure you want to save the current version of this report as your working draft? The originally submitted version of the report will be overwritten with any changes you have made, and cannot be recovered.";
    if (window.confirm(text) === true)
    {
        setLoadedProfile(false);
        if(selectedWeek===0)
        {
            setErrors("cannot Save during week 0.\n")
            return;
        }

        if(hasPlan===false)
        {
            setErrors("cannot Save without active coaching plan.\n")
            return;
        }

        


        var error ="";

        if(social&& socialText.length>600)
        {
            error+=("Social section of assessment is above character limit\n")
        }

        if(physical&& physicalText.length>900)
        {
            error+=("Physical section of assessment is above character limit\n")
        }

        if(behavioral&& behavioralText.length>900)
        {
            error+=("Behavioral section of assessment is above character limit\n")
        }
        if(continuedNeed&& continuedNeedText.length>900)
            {
                    error+=("Continued Need section of assessment is above character limit\n")
            }

        var discoveryText="";
        document.querySelectorAll('.discoveryInput').forEach(function(el){
            if(el.value.trim()!==""){
            discoveryText+= (el.value)+(el.value.endsWith(".")?"":".")+"\n\n";
            }
            else
            {
                error+="incomplete discovery field, please remove or fill out.\n"
                
            }
        });
        setDiscovery(discoveryText);

        var g1=[]
        var g2=[]
        document.querySelectorAll('.goalInput').forEach((el)=>{
            g2.push( el.value +(el.value.endsWith(".")?"":"."));
          });   

          document.querySelectorAll('.goal2Input').forEach((el)=>{
            g1.push(el.value);
          });

          var gtemp=[];
        
          g1.forEach((e,i)=>{
            if(e.trim()!=="" && g2[i].trim()!==""){
            gtemp.push({goal: e , detail: g2[i]})
            }
            else
            {
                error+="incomplete goal field, please remove or fill out.\n"

            }
          })
          setGoal(gtemp);


          if(summary.trim()===""){
            error+="please fill out Progression Summary.\n"
          }
          if(assessment.trim()===""){
            error+="please fill out Assessment. There must be some form of Assessment\n"
          }

          if(plan.trim()===""){
            error+="please fill out Plan. There must be some form of Plan\n"
          }

          if(error===""){
            setErrors("");

        setSave(true);
          }
          else
          {
            setErrors(error)
            setLoadedProfile(true);

          }
    } 

   }

	

	


   const breadcrumbLinks = () => {

    return [
       
        {
            label: 'Reports',
            url: `/reports`
        },
        {
            label: "Edit Outcome Report",
            url: `/outcomes/${reportId}/`
        }
    ]


}

const GenerateImprovmentObject=(measureType,displayName, valueArray,percentage=0, week=null)=>{
    let normalized = valueArray&&valueArray.map((obj)=>{return GetNormalizedValue(measureType,obj.value)})
    var obj= {
            type:measureType,
            name:displayName,
            value: valueArray,
            percent: percentage,
            ImprovedValue:HasImprovedScoreBand(measureType,valueArray,week),
            normalizedValues:normalized,
            normalizedPercent: normalized.length>0? normalized[normalized.length-1]-normalized[0]:0,
    }
    return obj;
}


const NoDataDecorator=()=>{
    return (
        <React.Fragment>
            <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-error-50 rounded-full border border-error-200 justify-start items-center gap-1.5 inline-flex">
<img className="w-3 h-3 relative" src="/icons/warning.svg"/>
<div className="text-center text-error-700 text-xs font-medium font-['Inter'] leading-[18px]">No Data</div>
</div>
        </React.Fragment>
    )
}




const WeekDecorator=(week)=>{
    return (
        <React.Fragment>
            <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-brand-50 rounded-full border border-brand-200 justify-start items-center gap-1.5 inline-flex">
<img className="w-3 h-3 relative" src="/icons/clock-rewind.svg"/>
<div className="text-center text-brand-700 text-xs font-medium font-['Inter'] leading-[18px]">Week {week}</div>
</div>
        </React.Fragment>
    )
}

const IndicatorDecorator=(result)=>{
    var svg = ""
    if(result[1][2].includes("error"))
    {
        svg = "info-circle-error"
    }
    else  if(result[1][2].includes("warning"))
    {
        svg = "info-circle-warning"

    }
    else
    {
        svg = "info-circle-success"

    }
    return (
        <React.Fragment>
            <span className="relative">
            <div className={" has-tooltip  pl-0.5 pr-2 py-0.5 mx-1.5  rounded-full border  justify-start items-center gap-1.5 inline-flex "+result[1][0]+" "+result[1][1]}>

            <span class='tooltip rounded shadow-lg  whitespace-normal	 break-normal min-w-[10vw] max-w-[30vw] [@media(max-width:1200px)]:max-w-[40vw] [@media(min-width:1600px)]:max-w-[30vw] w-max bottom-[125%] right-1   '>{result[2]}</span>

            <div class='  relative pl-1 inline-flex 	'>
                    <img className="w-3 h-3 relative hs-tooltip " src={"/icons/"+svg+".svg"}/>

         </div>
<div className={"text-center  text-xs font-medium font-['Inter'] leading-[18px] "+result[1][2]}>{result[0]}</div>
</div>
</span>
        </React.Fragment>
    )
}


const MetricTooltip=(result)=>{
        
    return (
        <React.Fragment>
            <span className="relative inline-flex">
                <div class=' has-tooltip relative pl-1 inline-flex 	'>
                        <img className="w-3.5 h-3.5 relative hs-tooltip translate-y-1 " src={"/icons/info-circle-measure.svg"}/>
                        <span class='tooltip rounded shadow-lg  whitespace-normal	 break-normal min-w-[10vw] max-w-[40vw] [@media(max-width:1200px)]:max-w-[45vw] [@media(min-width:1600px)]:max-w-[30vw] w-max   bottom-1  '>{result}</span>
                </div>
            </span>
        </React.Fragment>
    )
}

const changeDecorator=(change,firstWeek,percent=false, wantDecrease=false)=>{
    if(change===0)
    {
        return (
            <React.Fragment>
                <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-gray-50 rounded-full border border-gray-200 justify-start items-center gap-1.5 inline-flex">
                    <img className="w-3 h-3 relative" src="/icons/minus.svg"/>
                    <div className="text-center text-gray-700 text-xs font-medium font-['Inter'] leading-[18px]">No Change from Week {firstWeek}</div>
                </div>
            </React.Fragment>
        )
       
    }
    if(!wantDecrease)
    {
        if(change>0)
        {
            return (
                <React.Fragment>
                    <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-success-50 rounded-full border border-success-200 justify-start items-center gap-1.5 inline-flex">
                        <img className="w-3 h-3 relative" src="/icons/upgood.svg"/>
                        <div className="text-center text-success-700 text-xs font-medium font-['Inter'] leading-[18px]">{Math.abs( change)}{percent?"%":""} from Week {firstWeek}</div>
                    </div>
                </React.Fragment>
            )
        }
        else
        {
            return (
                <React.Fragment>
                    <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-warning-50 rounded-full border border-warning-200 justify-start items-center gap-1.5 inline-flex">
                        <img className="w-3 h-3 relative" src="/icons/downBad.svg"/>
                        <div className="text-center text-warning-700 text-xs font-medium font-['Inter'] leading-[18px]">{Math.abs( change)}{percent?"%":""} from Week {firstWeek}</div>
                    </div>
                </React.Fragment>
            )
        }
    }
    else
    {
        if(change>0)
            {
                return (
                    <React.Fragment>
                        <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-warning-50 rounded-full border border-warning-200 justify-start items-center gap-1.5 inline-flex">
                            <img className="w-3 h-3 relative" src="/icons/upBad.svg"/>
                            <div className="text-center text-warning-700 text-xs font-medium font-['Inter'] leading-[18px]">{Math.abs( change)}{percent?"%":""} from Week {firstWeek}</div>
                        </div>
                    </React.Fragment>
                )
            }
            else
            {
                return (
                    <React.Fragment>
                        <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-success-50 rounded-full border border-success-200 justify-start items-center gap-1.5 inline-flex">
                            <img className="w-3 h-3 relative" src="/icons/downgood.svg"/>
                            <div className="text-center text-success-700 text-xs font-medium font-['Inter'] leading-[18px]">{Math.abs( change)}{percent?"%":""} from Week {firstWeek}</div>
                        </div>
                    </React.Fragment>
                )
            }
    }

    
}



const PSEQIndicator=(val)=>{
    if(val>40)
    {
        return ["High",["bg-success-50","border-success-200","text-success-700"],"Indicates low confidence in their ability to perform activities despite pain, leading to reduced physical activity and greater disability."]

    }
    if(val>20)
    {
        return ["Moderate",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates some confidence in their ability to perform activities, but with some limitations requiring support."]

    }
    return ["Low",["bg-error-50","border-error-200","text-error-700"],"Indicates low confidence in their ability to perform activities despite pain, leading to reduced physical activity and greater disability."]

}
const PCSIndicator=(val)=>{
    if(val>=30)
    {
        return ["High",["bg-error-50","border-error-200","text-error-700"],"Indicates low confidence in their ability to perform activities despite pain, leading to reduced physical activity and greater disability."]

    }
    if(val>=15)
    {
        return ["Moderate",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates some confidence in their ability to perform activities, but with some limitations requiring support."]

    }
    return ["Low",["bg-success-50","border-success-200","text-success-700"],"Indicates high confidence in their ability to manage pain and perform various activities. leading to better physical function."]

}

const FABQTotalIndicator=(val)=>{
    if(val>=65)
    {
        return ["High",["bg-error-50","border-error-200","text-error-700"],"Indicates significant impact on function and likely to avoid many activities, leading to greater disability."]

    }
    if(val>=49)
    {
        return ["Moderate",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates potential impact on function and may avoid some activities due to fear of pain."]

    }
    return ["Low",["bg-success-50","border-success-200","text-success-700"],"Indicates minimal impact on function with better physical activity and function."]

}
const FABQWorkIndicator=(val)=>{
    if(val>=25)
    {
        return ["High",["bg-error-50","border-error-200","text-error-700"],"Indicates significant avoidance of physical activities and functional tasks, leading to considerable reductions in physical function and activity levels."]

    }
    if(val>=15)
    {
        return ["Moderate",["bg-warning-50","border-warning-200","text-warning-700"], "Indicates some functional limitations, but are not entirely incapacitated."]

    }
    return ["Low",["bg-success-50","border-success-200","text-success-700"], "Indicates higher likelihood to engage in physical activities and work tasks without excessive fear of pain exacerbation."]

}
const FABQActivityIndicator=(val)=>{
    if(val>=17)
    {
        return ["High",["bg-error-50","border-error-200","text-error-700"],"Indicates significant avoidance of physical activities and likely to experience poor physical function."]

    }
    if(val>=9)
    {
        return ["Moderate",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates some avoidance of physical activities and ikely to have moderate impact on physical function."]

    }
    return ["Low",["bg-success-50","border-success-200","text-success-700"],"Indicates active engagement in physical activities and likely to experience better physical function."]

}

const PHQ9Indicator=(val)=>{
    if(val>=20)
    {
        return ["Severe Depression",["bg-error-50","border-error-200","text-error-700"],"Indicates debilitating symptoms, making it very difficult to function in daily life."]

    }
    if(val>=15)
    {
        return ["Moderately Severe Depression",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates daily functioning is significantly impacted and may struggle with daily life functions."]

    }
    if(val>=10)
        {
            return ["Moderate Depression",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates decreased concentration, energy, and interest in daily activities making them difficult to complete."]

        }
    if(val>=5)
    {
        return ["Mild Depression",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates minor difficulties in daily activities, but can generally manage their responsibilities."]
    
    }
    return ["Minimal Depression",["bg-success-50","border-success-200","text-success-700"],"Indicates minimal impact on daily functioning, including work, social activities, and personal care."]

}
const GAD7Indicator=(val)=>{
    if(val>=15)
    {
        return ["Severe Anxiety",["bg-error-50","border-error-200","text-error-700"],"Indicates severe symptoms, often significantly interfering with daily activities and overall well-being."]

    }
    if(val>=10)
    {
        return ["Moderate Anxiety",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates symptoms of anxiety are more pronounced and may begin to impact daily functioning and quality of life. "]

    }
    if(val>=5)
        {
            return ["Mild Anxiety",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates some manageable symptoms of anxiety, but may not interfere significantly with daily activities. "]

        }
    
    return ["No Anxiety",["bg-success-50","border-success-200","text-success-700"],"Indicates little to no symptoms of anxiety and likely to perform effectively in their life roles."]

}
const OswestryIndicator=(val)=>{

    if(val>=35)
        {
            return ["Completely Disabled",["bg-error-50","border-error-200","text-error-700"],"Indicates inability to carry out most daily activities independently."]

        }
    if(val>=25)
    {
        return ["Severe Disability",["bg-error-50","border-error-200","text-error-700"],"Indicates significant pain and substantial limitations in their ability to perform daily activities."]

    }
    if(val>=15)
    {
        return ["Moderate Disability",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates difficulty with heavier or more strenuous activities."]

    }
    if(val>=5)
        {
            return ["Mild Disability",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates general ability to perform most activities, but may experience some pain and slight limitations in function."]

        }
    
    return ["No Disability",["bg-success-50","border-success-200","text-success-700"],"Indicates minimal or no pain and can perform all activities without significant restriction."]

}
const DashIndicator=(val)=>{
    if(val>=81)
        {
            return ["Extreme Disability",["bg-error-50","border-error-200","text-error-700"],"Indicates minimal to no functional capacity and complete dependence on others for most activities."]

        }
    if(val>=61)
    {
        return ["Severe Disability",["bg-error-50","border-error-200","text-error-700"],"Indicates low functional capacity with major restrictions in daily activities."]

    }
    if(val>=41)
    {
        return ["Moderate Disability",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates reduced functional capacity with significant challenges in daily activities."]

    }
    if(val>=21)
        {
            return ["Mild Disability",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates ability to perform most daily activities but may need occasional assistance."]

        }
    
    return ["No Disability",["bg-success-50","border-success-200","text-success-700"],"Indicates high functional capacity and ability to perform most daily activities with minimal difficulty."]

}
const NDIIndicator=(val)=>{
    if(val>=35)
        {
            return ["Complete Disability",["bg-error-50","border-error-200","text-error-700"],"Indicates complete disability from neck pain, unable to carry out most daily activities."]

        }
    if(val>=25)
    {
        return ["Severe Disability",["bg-error-50","border-error-200","text-error-700"],"Indicates severe pain and significant limitations in daily activities, with a substantial impact on their quality of life."]

    }
    if(val>=15)
    {
        return ["Moderate Disability",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates noticeable pain and moderate limitations in daily activities, affecting their ability to function normally."]

    }
    if(val>=5)
        {
            return ["Mild Disability",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates some pain and minor limitations in daily activities, but generally without significant impairment."]

        }
    
    return ["No Disability",["bg-success-50","border-success-200","text-success-700"],"Indicates no significant impact on daily activities due to neck pain."]

}

const WHODASIndicator=(val)=>{
    if(val>=4.5)
        {
            return ["Extreme Disability",["bg-error-50","border-error-200","text-error-700"],"Indicates major limitations in daily activities and overall functioning. Likely dependent on others for most tasks."]

        }
    if(val>=3.5)
    {
        return ["Severe Disability",["bg-error-50","border-error-200","text-error-700"],"Indicates significant limitations in performing daily activities. Likely require substantial assistance and support."]

    }
    if(val>=2.5)
    {
        return ["Moderate Disability",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates moderate difficulties in performing daily tasks. These limitations can impact work, social participation, and quality of life."]

    }
    if(val>=1.5)
        {
            return ["Mild Disability",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates slight difficulties in performing certain tasks, but these do not significantly affect their overall independence or quality of life."]

        }
    
    return ["No Disability",["bg-success-50","border-success-200","text-success-700"],"Indicates minimal interference with daily activities and overall functioning."]

}

const KarunaRatingIndicator=(val)=>{
    console.log(val)

    if(val>=4000)
    {
      return ["Min. to No Limitations",["bg-success-50","border-success-200","text-success-700"],"Indicates high levels of physical function. Likely able to perform most daily tasks independently."]

    }
    if(val>=2500)
    {
        return ["Mild Limitations",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates moderate levels of physical function. Likely independent in most daily tasks with some adjustments needed."]
    }
    if(val>=1000)
    {
        return ["Moderate Limitations",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates low levels of physical function. May be able to perform some basic tasks with difficulty and limited endurance."]
    }
    if(val>=0)
        {
    return ["Severe Limitations",["bg-error-50","border-error-200","text-error-700"],"Indicates severe limitations in function. Likely to experience significant mobility limitations and may struggle with basic daily tasks."]
        }
}

const UEFIIndicator=(val)=>{
    if(val>=61)
        {
            return ["Severe Limitations",["bg-error-50","border-error-200","text-error-700"]]

        }
   
    if(val>=41)
    {
        return ["Moderate Limitations",["bg-warning-50","border-warning-200","text-warning-700"]]

    }
    if(val>=21)
        {
            return ["Mild Limitations",["bg-warning-50","border-warning-200","text-warning-700"]]

        }
    
    return ["Min. to No Limitations",["bg-success-50","border-success-200","text-success-700"]]

}

const PainIndicator=(val)=>{
    if(val>=7)
    {
            return ["Severe",["bg-error-50","border-error-200","text-error-700"],"Indicates significant interference with daily activities or functions."]

    }
    
    if(val>=4)
    {
        return ["Moderate",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates difficulty with heavier or more strenuous activities and may need some assistance for daily tasks."]

    }
    if(val>=1)
        {
            return ["Mild",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates no significant interference with daily activities or functions."]

        }
    
    return ["None",["bg-success-50","border-success-200","text-success-700"],"Indicates no impact on function."]

}

const PCSSIndicator=(val)=>{
    if(val>=76)
    {
            return ["Extreme Symptoms",["bg-error-50","border-error-200","text-error-700"],"Indicates symptoms are debilitating, severely limiting the ability to carry out daily activities. May require substantial support."]

    }
    if(val>=61)
        {
                return ["Very Severe Symptoms",["bg-error-50","border-error-200","text-error-700"],"Indicates symptoms are very severe, causing major disruptions to daily life and functioning. "]
    
        }
    if(val>=46)
        {
                return ["Severe Symptoms",["bg-error-50","border-error-200","text-error-700"],"Indicates mild symptoms with possible disruption to daily activities, but can still function relatively well."]
    
        }
    
    if(val>=31)
    {
        return ["Moderate Symptoms",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates symptoms are significantly interfering with daily activities and life functions."]

    }
    if(val>=16)
        {
            return ["Mild Symptoms",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates mild symptoms with possible disruption to daily activities, but can still function relatively well."]

        }
    
    return ["Min. or No symptoms",["bg-success-50","border-success-200","text-success-700"],"Indicates minimal or mild symptoms, generally not interfering with daily activities."]

}

const ActivityIndicator=(val)=>{
    if(val>=8)
    {
            return ["Very High",["bg-success-50","border-success-200","text-success-700"],"Indicates excellent functional capacity with the ability to perform strenuous activities."]

    }
    if(val>=6)
        {
                return ["High",["bg-success-50","border-success-200","text-success-700"],"Indicates good functional capacity with minimal limitations and likely to manage daily activities and most tasks efficiently."]
    
        }
    
    if(val>=4)
    {
        return ["Moderate",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates moderate functional capacity with the ability to perform most daily activities. May experience fatigue or difficulty with more strenuous tasks."]

    }
    if(val>=2)
        {
            return ["Low",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates some functional limitations. Daily activities may be challenging and require additional effort. "]

        }
    
    return ["Very Low",["bg-error-50","border-error-200","text-error-700"],"Indicates significant functional impairment with considerable limitations in daily activities."]

}

const ConfidenceIndicator=(val)=>{
    if(val>=9)
    {
            return ["Very High",["bg-success-50","border-success-200","text-success-700"],"Indicates a positive mindset when approaching tasks, with full belief in their abilities."]

    }
    if(val>=7)
        {
                return ["High",["bg-success-50","border-success-200","text-success-700"],"Indicates general positivity about their ability to engage in most tasks and activities with minimal restrictions."]
    
        }
    
    if(val>=5)
    {
        return ["Moderate",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates willingness to attempt tasks with caution with some functional limitation."]

    }
    if(val>=3)
        {
            return ["Low",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates willingness to attempt some activities but remains hesitant and cautious."]

        }
    
    return ["Very Low",["bg-error-50","border-error-200","text-error-700"],"Indicates possible decrease in participation in activities of daily living and daily life roles."]

}

const SleepIndicator=(val)=>{
    if(val>=9)
    {
            return ["Excellent",["bg-success-50","border-success-200","text-success-700"],"Indicates optimal energy and physical performance, with little to no fatigue."]

    }
    if(val>=7)
        {
                return ["Good",["bg-success-50","border-success-200","text-success-700"],"Indicates good energy levels and physical performance, slightly reduced productivity."]
    
        }
    
    if(val>=5)
    {
        return ["Fair",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates mild fatigue, but generally able to perform daily activities."]

    }
    if(val>=3)
        {
            return ["Poor",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates noticeable fatigue and reduced stamina, with moderate impact on physical activities."]

        }
    
    return ["Very Poor",["bg-error-50","border-error-200","text-error-700"],"Indicates extreme fatigue and decreased physical performance."]

}

const MoodIndicator=(val)=>{
    if(val>=9)
    {
            return ["Very Good",["bg-success-50","border-success-200","text-success-700"],"Indicates higher energy and motivation, leading to higher levels of physical activity and function and excels in life roles."]

    }
    if(val>=7)
        {
                return ["Good",["bg-success-50","border-success-200","text-success-700"],"Indicates higher energy and motivation and able to manage challenges and engage in daily functions with resilience."]
    
        }
    
    if(val>=5)
    {
        return ["Fair",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates low energy, but still can engage in physical activities at a reduced level."]

    }
    if(val>=3)
        {
            return ["Poor",["bg-warning-50","border-warning-200","text-warning-700"],"Indicates fatigue, low energy, and decreased motivation, but may complete essential tasks and responsibilities."]

        }
    
    return ["Very Poor",["bg-error-50","border-error-200","text-error-700"],"Indicates fatigue, low energy, and decreased motivation, leading to reduced physical activity and participation in daily tasks."]

}

const RenderImprovementOptions=()=>{
            return ImprovementsComplete&&ImprovementsComplete.map((option, index)=>{
                if(option.ImprovedValue>0)
                {
                    return  GenerateKeyImprovementSection(option);
                }
            })
    
        }

        const generateWeekLabels=()=>{
            let labels=[]
            if(selectedWeek==="M6")
            {
    
                for(let i=1; i<=currentWeek;i++)
                {
                        labels.push(`Week ${i}`)
                }
                labels.push("6 Month");
    
            }
            else
            {
                for(let i=1; i<=selectedWeek;i++)
                {
                    labels.push(`Week ${i}`)
                }
            }
    
            return labels;
        }

         const getTrueGraphVal=(metrics, label, week)=>{
        let fixweek= (week==="M6"?week: parseInt( week.split(" ")[1]))
        let result=-1;
        metrics.forEach(m=>{
            if(m.name===label)
            {
                
                m.value.forEach((val,index)=>{
                    if(val.week===fixweek)
                    {
                        
                        result= m.normalizedValues[index];
                        result = Math.round(result * 10) / 10

                        return result;
                    }
                })
            }
        })

        return result;
    }
    
        const RenderGraphSection=()=>
            {
                let metrics= NudgeValues(selectedImprovements);
        
                return <React.Fragment>
                <div className=" [@media(max-width:1200px)]:w-[552px] w-[1104px] [@media(min-width:1700px)]:w-[1656px] [@media(max-width:1200px)]:h-[150px] h-[300px] [@media(min-width:1700px)]:h-[450px]" id="canvas">
                <Line id="chart"
           data={
              {
                labels: generateWeekLabels(),
                datasets: SetUpDataForGraph()
                
               
                
            }
           
            }
           height={null}
           width={null}
            options= {{
                
                scales: {
                    y: {
                        title: {
                            display: true,
                            align: 'center',
                            text: 'Standardized Score',
                            color: 'grey',
                            font: {
                              family: 'Arial',
                              size: 14,
                              
                            },
                            
                          },
                        max:100,
                      beginAtZero: true,
                      ticks: {
        
                        stepSize: 25,
                        autoSkip: false,
                        callback: function(value, index, ticks) {
                            return value+"%";
                        },
                        color: ['red', 'orange', 'orange','green','green'],
                        font: {
                            size: 12,
                        }
                      }
                    }
                  },
                  x:{
                    beginAtZero: true,

                    title: {
                        display: true,
                        text: 'X Axis Name' // Name of x-axis
                    },
                    ticks:{
                        
                        font: {
                            size: 12,
                        }
                    }
                  },
                  aspectRatio: 1,
                responsive: true,
                maintainAspectRatio: false,
                beforeDraw: function(chart)  {
                    var ctx = chart.chart.ctx;
                    ctx.save();
                    let xAxis = chart.scales['x-axis-0'];
                    let yAxis = chart.scales['y-axis-0'];
                    let dataset = chart.data.datasets[0];
                    var valueFrom = null;
                    var valueFromIndex = 0;
                    var xFrom = null;
                    let yFrom = null;
                    ctx.strokeStyle = dataset.borderColor;
                    dataset.data.forEach((value, index) => {
                      if (value != null) {
                        var x = xAxis.getPixelForTick(index);
                        var y = yAxis.getPixelForValue(value);
                        if (valueFrom != null) {
                          ctx.lineWidth = dataset.borderWidth;
                          if (index - valueFromIndex > 1) {
                            ctx.setLineDash([5, 5]);
                          } else {
                            ctx.setLineDash([]);
                          }
                          ctx.beginPath();
                          ctx.moveTo(xFrom, yFrom);
                          ctx.lineTo(x, y);
                          ctx.stroke();
                        }
                        valueFrom = value;
                        valueFromIndex = index;
                        xFrom = x;
                        yFrom = y;
                      }
                    });
                    ctx.restore();
                  },
                  plugins:{
                    tooltip: {
                       // usePointStyle:true,
                       // footerFont:{weight: 'normal'},
          
                        callbacks: {
                            label:(context)=>{
                                let label="";
                                label+=context.dataset.label+": ";
                                label+= getTrueGraphVal(metrics,context.dataset.label,context.label);
                                label+="%"
                                return label;
                              },
                        },
                        
                    },
                    legend: {
                        align:'end',
                        labels: {
                          usePointStyle: true,
                          font: {
                            size: 12
                        }
                        }
                      }
                  }
                
            
            /*, your other options*/
            
            }}
            
        plugins={[custom_canvas_background_color]}
        />
        </div>
        </React.Fragment>
            }
    
      
    
      
    
        const GetMeasureFromType=(measureType)=>{
            let measure=null
           
            ImprovementsComplete.forEach(e=>{
                if(e.type===measureType)
                {
                    measure=e;
                }
            })
            return measure;
        }

        const nudgeArray=(arr)=>{

            arr.map(n=>n)
            if(arr.length===2)
            {
                if(arr[0].value===0)
                {
                    arr[1].value+=1;
    
                }
                else
                {
                arr[0].value-=1;
                }
            }
    
            if(arr.length===3)
                {
                    if(arr[0].value===0)
                    {
                        arr[1].value+=1;
                        arr[2].value+=2;
                    }
                    else if(arr[0].value===100)
                    {
                        arr[1].value-=1;
                        arr[2].value-=2;
                    }
                    else{
                        arr[0].value-=1;
                        arr[2].value+=1
                    }
                }
    
            if(arr.length===4)
            {
                if(arr[0].value<=1)
                {
                    arr[1].value+=1;
                    arr[2].value+=2  
                    arr[3].value+=3  
                }
                else if(arr[0].value>=99)
                {
                    arr[1].value-=1;
                    arr[2].value-=2  
                    arr[3].value-=3  
                }
                else{
                arr[0].value-=2;
                arr[1].value-=1;
                arr[3].value+=1   
                }
            }
    
            if(arr.length===5)
            {
                if(arr[0].value<=1)
                {
                        arr[1].value+=1;
                        arr[2].value+=2  
                        arr[3].value+=3  
                        arr[4].value+=4  
    
                }
                else if(arr[0].value>=99)
                    {
                        arr[1].value-=1;
                        arr[2].value-=2  
                        arr[3].value-=3
                        arr[4].value-=4  
  
                    }
                else{
                arr[0].value-=2;
                arr[1].value-=1;
                arr[3].value+=1  
                arr[4].value+=2
                }        
            }
    
            return arr;
        }
    
       
        const NudgeValues=(improvementSelections)=>

            {
                let selections={}
                let measures=[];
             //   console.log(improvementSelections)
                
                improvementSelections.forEach((selection,index)=>{
                    let measure= GetMeasureFromType(selection);
                    measure.nudged=[]
                //    console.log(measure)
                    measures.push(measure);
                    measure.value.forEach((n,index2)=>{
                        if(n.week in selections)
                        {
                            if(Math.round(measure.normalizedValues[index2]) in selections[n.week])
                            {
                                selections[n.week][ Math.round( measure.normalizedValues[index2])].push(
                                    {
                                        value: measure.normalizedValues[index2],
                                        index: index,
                                        index2:index2
        
                                    }
                                )
                            }
                            else
                            {
                                selections[n.week][Math.round(measure.normalizedValues[index2])]=[
                                    {
                                        value: measure.normalizedValues[index2],
                                        index: index,
                                        index2:index2
        
                                    }
                                ]
                            }
                        }
                        else
                        {
                            selections[n.week]={}
                            selections[n.week][Math.round(measure.normalizedValues[index2])]=[
                                {
                                    value: measure.normalizedValues[index2],
                                    index: index,
                                    index2:index2
                                
        
                                }
                            ]
        
                        }

                    })
                })
        
        
              //  console.log(selections);
        
        
                for (let i in selections)
                {
                 //   console.log(i)
                    for(let j in selections[i])
                    {
                      //  console.log(j)
                      //  console.log(selections[i][j])
                        let nudged = nudgeArray(selections[i][j])
                        nudged.forEach(n=>{
                        //    console.log(measures[n.index])
                            measures[n.index].nudged.push(n.value);
                        })
                        //console.log(nudged)
                       // measures[nudged[0].index].nudged= nudgeToArray( nudged);
                        
                    }
                }
             //   console.log(measures)
        
                return measures
        
        
            }

        const SetUpDataForGraph=(values,normalizedValues)=>
        {
            let metrics= NudgeValues(selectedImprovements);
            return   metrics.map((measure,index)=>{
                // let measure= GetMeasureFromType(obj);
                // console.log(measure.name);
               //  console.log(measure.value)
                 let dataset=isRomType(measure.type)? SetUpGraphPoints(RemoveNoneFirstMeasure( measure.value),RemoveNoneFirstMeasure( measure.nudged)) :SetUpGraphPoints( measure.value,measure.nudged) ; // Specify the data values array
                 return {
                     label: measure.name,
                     connectNullData: true,
                     data: dataset,
                     fill: false,
                     borderWidth: 1, // Specify bar border width
                     spanGaps: true,
                     borderColor:colors[index] ,
                     backgroundColor: colors[index] ,
     
                     segment:{
                         borderDash:ctx=>{
                           return dataset[ctx.p0DataIndex]===null||dataset[ctx.p1DataIndex]===null?[10,19]:[]
     
                         }
                         
     
                       }
                 }
             })
            
        }
    
        const SetUpGraphPoints=(values,normalizedValues)=>{
            let week=1;
            let index=0
            let result=[]
           
            while(index<values.length)
            {
             
                let obj= values[index];
               
    
                if(obj.week===week||obj.week==='M6')
                {
                    result.push(normalizedValues[index]);
                    index++;
                    week++;
                }
                else{
                    result.push(null);
                    week++;
                }
                if(week>values[values.length-1].week)
                {
                    break;
                }
                
            }
            return result;
    
        }


	return (
        <Page errors={errors} headerObj={headerInfo!==null?headerInfo:{trail:breadcrumbLinks()}}  internal={true} noScroll={true}>
        <Spacer headerObj={headerInfo!==null?headerInfo:{trail:breadcrumbLinks()}} internal={true}/>
        {loadedProfile&&data!==null?
            <div id="body"  className={"w-full p-4 h-full gap-y-1 flex-col inline-flex  justify-start  whitespace-nowrap overflow-x-hidden overflow-y-auto"}>
                <ReviewSectionGenerator hasPhysicianReview={oversight}/>
                <span className="mb-2"/>
                <SectionDivider label={"Key Improvements"}  locked={true}>
                <div className="h-6 justify-start items-start gap-3 inline-flex">
                    <img className="w-6 h-6 relative" src="/icons/percent-03.svg"/>
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex text-wrap	">
                        <div className="self-stretch text-wrap	 text-gray-600 text-base max-[1200px]:text-sm font-normal font-['Inter'] leading-normal">When displayed in the graph, measures are converted to a standard percentage score, where higher is better.</div>
                    </div> 
                                   
                </div>
                                       {RenderGraphSection()}
                
                <div id="metrics" className='mt-5'>
                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                <div className="grow relative shrink basis-0   justify-center items-start gap-1 inline-flex">
                                  
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Included Measures</div>
                                    <div class=' align-text-bottom	has-tooltip self-center float-bottom text-center 	content-center	items-end	 pl-2 object-bottom whitespace-nowrap  inline-flex 	'>
                                        <span class='tooltip rounded shadow-lg  whitespace-normal	 break-normal min-w-[10vw] max-w-[40vw] [@media(max-width:1200px)]:max-w-[45vw] [@media(min-width:1600px)]:max-w-[30vw] w-max  mb-2 ml-2 '>To qualify for the Key Improvements graph, a measure must have improved into a higher score band since its first measurement.</span>
                                        <img className='h-5 w-5 hs-tooltip ' src ={"/icons/help-circle.svg"} ></img>
                                    </div>
                                    <div className="text-gray-400 text-xs font-normal font-['Inter'] translate-y-1.5 pl-2 leading-[18px]">{selectedImprovements.length}/5 included</div>
                                </div>  
                        </div>
                      
                        
                        {
                            RenderImprovementOptions()
                        }
                       
                        
                        {/** */}


                    </div>
                </div>     
                </div>
                    

                </SectionDivider>
                <SectionDivider label={"Patient History"}>
                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">History of Injury</div>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">Write a short summary of the Nature and Mechanism of the patient’s injury.</div>
                                </div>
                            </div>
                        </div>
                        <textarea className='w-full resize-none h-[15vh] px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex' placeholder="Enter text" onChange={e => setHistoryInjury(e.target.value)} value={HistoryInjury}></textarea>
                        <label className={"justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal font-['Inter'] leading-normal "}>
                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox"  checked={revertHistoryInjury }
                             onChange={()=>{setRevertHistoryInjury(!revertHistoryInjury)}} /> Restore Unedited History of Injury
                        </label>

                    </div>
                </div>
                </SectionDivider>
                <SectionDivider label={"Subjective Outcomes"}>
                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-3 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Patient Goals</div>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">Outline the real-life goals the patient set for themselves at the start of the program.</div>
                                </div>
                            </div>
                        </div>
                        {htmlGoal}
                        {htmlGoal.length==0?<span className="self-stretch text-warning-600  font-semibold font-['Inter'] leading-7"> &#9888; No Goals found in Practice Better data. You can add them manually below.
                            </span>:<span></span>}
                            <div className="self-stretch w-full  flex-col justify-start items-start gap-x-4 flex">
                            <label className={"justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal font-['Inter'] leading-normal "}>
                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox"  checked={revertGoal }
                             onChange={()=>{setRevertGoal(!revertGoal)}} /> Restore Unedited Goals
                            </label>
                            </div>
                            <div className="self-stretch w-full h-11 flex-col justify-center items-center gap-4 flex">
                           
								<button onClick={AddGoal} className="w-fit px-4 py-2.5 bg-white hover:bg-gray-300 rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex">
									<div className="px-0.5 justify-center gap-x-2 items-center inline-flex">
                                        <img className="w-5 h-5 pl-[3.33px] justify-center items-center inline-flex" src="/icons/plus.svg" />
										<div className="text-gray-700 text-base font-semibold  leading-normal">{"Add New Goal"}</div>
									</div>
								</button>
				            </div>
                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Patient Perspective</div>
                                </div>
                            </div>
                        </div>
                        {/** */}
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex gap-x-2  text-base font-normal leading-normal font-['Inter'] "+ (rawGoalProgressData===null?"text-gray-400 ":"text-gray-600 ")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                type="checkbox"  checked={goalProgress } onChange={()=>{setGoalProgress(!goalProgress)}} disabled={rawGoalProgressData===null?true:false} /> Perceived Progress Towards Goals
                                </label>
                            </div>
                                <div className="w-fit text-base self-start font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                                {
                                    rawGoalProgressData? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{rawGoalProgressData.value.toString()+"/10"}{(selectedWeek===rawGoalProgressData.week?"":WeekDecorator(rawGoalProgressData.week))}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                            
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] "+ (rawEngagmentLevelData===null?"text-gray-400 ":"text-gray-600 ")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                type="checkbox"  checked={engagmentLevel } onChange={()=>{setEngagementLevel(!engagmentLevel)}} disabled={rawEngagmentLevelData===null?true:false} />Level of Engagement in Program
                                </label>
                            </div>
                                <div className="w-fit text-base font-normal self-start text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                                {
rawEngagmentLevelData? <span >{rawEngagmentLevelData.value.toString()+"/10"}{(selectedWeek===rawEngagmentLevelData.week?"":WeekDecorator(rawEngagmentLevelData.week))}</span>:<span className="gap-x-2 ext-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>}
                                </div>
                            
                        </div>
                        {/** */}


                    </div>
                </div>
                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-3 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Patient Discoveries</div>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">Outline any breakthroughs the patient has made in understanding and managing their pain.</div>
                                </div>
                            </div>
                        </div>
                        {htmlDisc}
                        {htmlDisc.length==0?<span className="self-stretch text-warning-600  font-semibold font-['Inter'] leading-7"> &#9888; No Discoveries found in Practice Better data. You can add them manually below.
                            </span>:<span></span>}
                            <div className="self-stretch w-full  flex-col justify-start items-start gap-x-4 flex">
                            <label className={"justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal font-['Inter'] leading-normal "}>
                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox"  checked={revertDiscovery }
                             onChange={()=>{setRevertDiscovery(!revertDiscovery)}} /> Restore Unedited Discoveries
                            </label>
                            </div>
                            <div className="self-stretch w-full flex-col justify-center items-center gap-4 flex">
                            
                                <button onClick={AddDiscovery} className="w-fit px-4 py-2.5 bg-white hover:bg-gray-300 rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex">
									<div className="px-0.5 justify-center gap-x-2 items-center inline-flex">
                                        <img className="w-5 h-5 pl-[3.33px] justify-center items-center inline-flex" src="/icons/plus.svg" />
										<div className="text-gray-700 text-base font-semibold  leading-normal">{"Add New Discovery"}</div>
									</div>
								</button>
				            </div>
                    </div>
                </div>
                <div className=" w-1/2 [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  flex-col justify-start items-start pb-4 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Subjective Progress Summary</div>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">Write a short summary of the patient’s engagement with the program and improvements in any area of their lives. Focus on the patient’s own perspective and the progress they have communicated to you.</div>
                                </div>
                            </div>
                        </div>
                        <textarea className='w-full resize-none h-[15vh] px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex' placeholder="Enter text" onChange={e => setSummary(e.target.value)} value={summary}></textarea>
                        
                        <label className={"justify-start items-center flex text-gray-600 gap-x-2  text-base font-normal font-['Inter'] leading-normal "}>
                    <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
 type="checkbox"  checked={revertSummary } onChange={()=>{setRevertSummary(!revertSummary)}} /> Restore Unedited Progress Summary
                </label>
                    </div>
                </div>
                </SectionDivider>
                <SectionDivider label={"Objective Outcomes"}>
                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Adherence</div>
                                </div>
                            </div>
                        </div>
                        {/** */}
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " + (RawSessionsAttendedData===null?"text-gray-400 ":"text-gray-600 ")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={sessionsAttended } onChange={()=>{setSessionsAttended(!sessionsAttended)}} disabled={RawSessionsAttendedData===null?true:false} /> No. Coaching Sessions Attended
                                </label>
                            </div>
                                <div className="w-fit text-base self-start	 font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                                {
                                    RawSessionsAttendedData!==null? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{RawSessionsAttendedData}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                            
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " + (RawUniqueDaysData===null?"text-gray-400 ":"text-gray-600 ")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={uniqueDays} onChange={()=>{setUniqueDays(!uniqueDays)}} disabled={RawUniqueDaysData===null?true:false} /> Unique Days in Headset p/w
                                </label>
                            </div>
                                <div className="w-fit self-start text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                                {
                                    RawUniqueDaysData? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{RawUniqueDaysData}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                            
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex gap-x-2  text-base font-normal leading-normal font-['Inter'] "+ (RawAverageTimeData===null?"text-gray-400 ":"text-gray-600 ")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={averageTime} onChange={()=>{setAverageTime(!averageTime)}} disabled={RawAverageTimeData===null?true:false} /> Average Time in Activities p/w
                                </label>
                            </div>
                                <div className="w-fit text-base self-start font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                                {
                                    RawAverageTimeData? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{RawAverageTimeData}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                            
                        </div>
                        {/** */}


                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Program Impact</div>
                                </div>                    
                        </div>
                        {/** */}
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(returnToWorkData!==null?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={returnToWork } onChange={()=>{setReturnToWork(!returnToWork)}} disabled={returnToWorkData!==null?false:true} /> Return To Work
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                   returnToWorkData!==null? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">
                                    {returnToWorkData.slice(-1)[0].value>=3?"Yes":(returnToWorkData.slice(-1)[0].value!==0?"No":"N/A")}
                                    {returnToWorkData.slice(-1)[0].week!==selectedWeek?WeekDecorator(returnToWorkData.slice(-1)[0].week):""}
                                    </span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(returnToWorkData!==null?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={medReduction } onChange={()=>{setMedReduction(!medReduction)}} disabled={medReductionData!==null?false:true} /> Medication Reduction
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                   medReductionData!==null? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">
                                    {medImp}
                                    {medReductionData.slice(-1)[0].week!==selectedWeek?WeekDecorator(medReductionData.slice(-1)[0].week):""}
                                    </span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        
                        {/** */}


                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Physical Disability</div>
                                </div>                    
                        </div>
                        {/** */}
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%]  [@media(min-width:1600px)]:w-[35%] justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(DashData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={Dash } onChange={()=>{setDash(!Dash)}} disabled={DashData.length>0?false:true} /> DASH

                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.DASH))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    DashData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{DashData.slice(-1)[0].value.toString()}{DashData.slice(-1)[0].week!==selectedWeek?WeekDecorator(DashData.slice(-1)[0].week):""}{DashData.length>1?changeDecorator(DashImp,DashData[0].week,false,true):""}{DashData.length>0?IndicatorDecorator(DashIndicator( DashData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(NDIData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={NDI } onChange={()=>{setNDI(!NDI)}} disabled={NDIData.length>0?false:true} /> NDI
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.NDI))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    NDIData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{NDIData.slice(-1)[0].value.toString()}{NDIData.slice(-1)[0].week!==selectedWeek?WeekDecorator(NDIData.slice(-1)[0].week):""}{NDIData.length>1?changeDecorator(NDIImp,NDIData[0].week,false,true):""}{NDIData.length>0?IndicatorDecorator(NDIIndicator( NDIData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(oswestryData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={oswestry } onChange={()=>{setOwestry(!oswestry)}} disabled={oswestryData.length>0?false:true} /> Oswestry
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.Oswestry))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    oswestryData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{oswestryData.slice(-1)[0].value.toString()}{oswestryData.slice(-1)[0].week!==selectedWeek?WeekDecorator(oswestryData.slice(-1)[0].week):""}{oswestryData.length>1?changeDecorator(oswestryImp,oswestryData[0].week,false,true):""}{oswestryData.length>0?IndicatorDecorator(OswestryIndicator( oswestryData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(WHODASData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={WHODAS } onChange={()=>{setWHODAS(!WHODAS)}} disabled={WHODASData.length>0?false:true} /> WHODAS
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.WHODAS))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    WHODASData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{WHODASData.slice(-1)[0].value.toString()}{WHODASData.slice(-1)[0].week!==selectedWeek?WeekDecorator(WHODASData.slice(-1)[0].week):""}{WHODASData.length>1?changeDecorator(WHODASImp,WHODASData[0].week,false,true):""}{WHODASData.length>0?IndicatorDecorator(WHODASIndicator( WHODASData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>


                        
                        {/** */}


                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Physical Function</div>
                                </div>                    
                        </div>
                        {/** */}
                        
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(activityData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={activity } onChange={()=>{setActivity(!activity)}} disabled={activityData.length>0?false:true} /> Activity
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    activityData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{activityData.slice(-1)[0].value.toString()}{activityData.slice(-1)[0].week!==selectedWeek?WeekDecorator(activityData.slice(-1)[0].week):""}{activityData.length>1?changeDecorator(activityImp,activityData[0].week):""}{activityData.length>0?IndicatorDecorator(ActivityIndicator( activityData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(KarunaRatingData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={KarunaRating } onChange={()=>{setKarunaRating(!KarunaRating)}} disabled={KarunaRatingData.length>0?false:true} /> Karuna Rating
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.KarunaRating))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    KarunaRatingData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{KarunaRatingData.slice(-1)[0].value.toString()}{KarunaRatingData.slice(-1)[0].week!==selectedWeek?WeekDecorator(KarunaRatingData.slice(-1)[0].week):""}{KarunaRatingData.length>1?changeDecorator(KarunaRatingImp,KarunaRatingData[0].week):""}{KarunaRatingData.length>0?IndicatorDecorator(GetIndicator( OutcomeMeasureTypes.KarunaRating,KarunaRatingData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(painData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={pain } onChange={()=>{setPain(!pain)}} disabled={painData.length>0?false:true} /> Pain
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    painData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{painData.slice(-1)[0].value.toString()}{painData.slice(-1)[0].week!==selectedWeek?WeekDecorator(painData.slice(-1)[0].week):""}{painData.length>1?changeDecorator(painImp,painData[0].week,false,true):""}{painData.length>0?IndicatorDecorator(PainIndicator( painData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                                
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(PCSSData.length>0?"text-gray-600":"text-gray-400")}>

                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={PCSS } onChange={()=>{setPCSS(!PCSS)}} disabled={PCSSData.length>0?false:true} /> PCSS
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.PCSS))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    PCSSData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{PCSSData.slice(-1)[0].value.toString()}{PCSSData.slice(-1)[0].week!==selectedWeek?WeekDecorator(PCSSData.slice(-1)[0].week):""}{PCSSData.length>1?changeDecorator(PCSSImp,PCSSData[0].week,false,true):""}{PCSSData.length>0?IndicatorDecorator(PCSSIndicator( PCSSData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(sleepData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={sleep } onChange={()=>{setSleep(!sleep)}} disabled={sleepData.length>0?false:true} /> Sleep
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    sleepData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{sleepData.slice(-1)[0].value.toString()}{sleepData.slice(-1)[0].week!==selectedWeek?WeekDecorator(sleepData.slice(-1)[0].week):""}{sleepData.length>1?changeDecorator(sleepImp,sleepData[0].week):""}{sleepData.length>0?IndicatorDecorator(SleepIndicator( sleepData.slice(-1)[0].value)):""}
</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(UEFIData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={UEFI } onChange={()=>{setUEFI(!UEFI)}} disabled={UEFIData.length>0?false:true} /> UEFI
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.UEFI))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    UEFIData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{UEFIData.slice(-1)[0].value.toString()}{UEFIData.slice(-1)[0].week!==selectedWeek?WeekDecorator(UEFIData.slice(-1)[0].week):""}{UEFIData.length>1?changeDecorator(UEFIImp,UEFIData[0].week,false,false):""}{UEFIData.length>0?IndicatorDecorator(UEFIIndicator( UEFIData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        
                        {/** */}


                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Range of Motion</div>
                                    <div class=' align-text-bottom	has-tooltip self-center float-bottom text-center 	content-center	items-end	 pl-2 object-bottom whitespace-nowrap  inline-flex 	'>
                                        <span class='tooltip rounded shadow-lg p-1 ml-6 '>Range of motion values are "recent bests".<br/> The highest weekly best measurement from the past three weeks is selected as the recent best for each motion.</span>
                                        <img className='h-5 w-5 hs-tooltip ' src ={"/icons/help-circle.svg"} ></img>
                                    </div>
                                </div>
                            
                        </div>
                        {/** */}
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(leftFlexionData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={leftFlexion } onChange={()=>{setLeftFlexion(!leftFlexion)}} disabled={leftFlexionData.length>0?false:true} /> Left Arm Flexion
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    leftFlexionData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{leftFlexionData[GetBestWeek(leftFlexionData,3)].value.toFixed(0).toString()+"°"}{leftFlexionData[GetBestWeek(leftFlexionData,3)].week!==selectedWeek?WeekDecorator(leftFlexionData[GetBestWeek(leftFlexionData,3)].week):""}{leftAbductionData.length>1&&!(leftFlexionData[0].week===leftFlexionData[GetBestWeek(leftFlexionData,3)].week&&leftFlexionData[0].value===leftFlexionData[GetBestWeek(leftFlexionData,3)].value)?changeDecorator(leftFlexionImp,leftFlexionData[0].week,true):""}{leftFlexionData.length>0?IndicatorDecorator(GetIndicator(OutcomeMeasureTypes.LeftFlexion,leftFlexionData[GetBestWeek(leftFlexionData,3)].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] "+(leftScaptionData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={leftScaption } onChange={()=>{setLeftScaption(!leftScaption)}} disabled={leftScaptionData.length>0?false:true} /> Left Arm Scaption
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    leftScaptionData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{leftScaptionData[GetBestWeek(leftScaptionData,3)].value.toFixed(0).toString()+"°"}{leftScaptionData[GetBestWeek(leftScaptionData,3)].week!==selectedWeek?WeekDecorator(leftScaptionData[GetBestWeek(leftScaptionData,3)].week):""}{leftScaptionData.length>1&&!(leftScaptionData[0].week===leftScaptionData[GetBestWeek(leftScaptionData,3)].week&&leftScaptionData[0].value===leftScaptionData[GetBestWeek(leftScaptionData,3)].value)?changeDecorator(leftScaptionImp,leftScaptionData[0].week,true):""}{leftScaptionData.length>0?IndicatorDecorator(GetIndicator(OutcomeMeasureTypes.LeftScaption,leftScaptionData[GetBestWeek(leftScaptionData,3)].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] "+(leftAbductionData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={leftAbduction } onChange={()=>{setLeftAbduction(!leftAbduction)}} disabled={leftAbductionData.length>0?false:true} /> Left Arm Abduction
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    leftAbductionData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{leftAbductionData[GetBestWeek(leftAbductionData,3)].value.toFixed(0).toString()+"°"}{leftAbductionData[GetBestWeek(leftAbductionData,3)].week!==selectedWeek?WeekDecorator(leftAbductionData[GetBestWeek(leftAbductionData,3)].week):""}{leftAbductionData.length>1&&!(leftAbductionData[0].week===leftAbductionData[GetBestWeek(leftAbductionData,3)].week&&leftAbductionData[0].value===leftAbductionData[GetBestWeek(leftAbductionData,3)].value)?changeDecorator(leftAbductionImp,leftAbductionData[0].week,true):""}{leftAbductionData.length>0?IndicatorDecorator(GetIndicator(OutcomeMeasureTypes.LeftAbduction,leftAbductionData[GetBestWeek(leftAbductionData,3)].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>

                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(rightFlexionData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={rightFlexion } onChange={()=>{setRightFlexion(!rightFlexion)}} disabled={rightFlexionData.length>0?false:true} /> Right Arm Flexion
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    rightFlexionData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{rightFlexionData[GetBestWeek(rightFlexionData,3)].value.toFixed(0).toString()+"°"}{rightFlexionData[GetBestWeek(rightFlexionData,3)].week!==selectedWeek?WeekDecorator(rightFlexionData[GetBestWeek(rightFlexionData,3)].week):""}{rightAbductionData.length>1&&!(rightFlexionData[0].week===rightFlexionData[GetBestWeek(rightFlexionData,3)].week&&rightFlexionData[0].value===rightFlexionData[GetBestWeek(rightFlexionData,3)].value)?changeDecorator(rightFlexionImp,rightFlexionData[0].week,true):""}{rightFlexionData.length>0?IndicatorDecorator(GetIndicator(OutcomeMeasureTypes.RightFlexion,rightFlexionData[GetBestWeek(rightFlexionData,3)].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] "+(rightScaptionData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={rightScaption } onChange={()=>{setRightScaption(!rightScaption)}} disabled={rightScaptionData.length>0?false:true} /> Right Arm Scaption
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    rightScaptionData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{rightScaptionData[GetBestWeek(rightScaptionData,3)].value.toFixed(0).toString()+"°"}{rightScaptionData[GetBestWeek(rightScaptionData,3)].week!==selectedWeek?WeekDecorator(rightScaptionData[GetBestWeek(rightScaptionData,3)].week):""}{rightScaptionData.length>1&&!(rightScaptionData[0].week===rightScaptionData[GetBestWeek(rightScaptionData,3)].week&&rightScaptionData[0].value===rightScaptionData[GetBestWeek(rightScaptionData,3)].value)?changeDecorator(rightScaptionImp,rightScaptionData[0].week,true):""}{rightScaptionData.length>0?IndicatorDecorator(GetIndicator(OutcomeMeasureTypes.RightScaption,rightScaptionData[GetBestWeek(rightScaptionData,3)].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] "+(rightAbductionData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={rightAbduction } onChange={()=>{setRightAbduction(!rightAbduction)}} disabled={rightAbductionData.length>0?false:true} /> Right Arm Abduction
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    rightAbductionData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{rightAbductionData[GetBestWeek(rightAbductionData,3)].value.toFixed(0).toString()+"°"}{rightAbductionData[GetBestWeek(rightAbductionData,3)].week!==selectedWeek?WeekDecorator(rightAbductionData[GetBestWeek(rightAbductionData,3)].week):""}{rightAbductionData.length>1&&!(rightAbductionData[0].week===rightAbductionData[GetBestWeek(rightAbductionData,3)].week&&rightAbductionData[0].value===rightAbductionData[GetBestWeek(rightAbductionData,3)].value)?changeDecorator(rightAbductionImp,rightAbductionData[0].week,true):""}{rightAbductionData.length>0?IndicatorDecorator(GetIndicator(OutcomeMeasureTypes.RightAbduction,rightAbductionData[GetBestWeek(rightAbductionData,3)].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] "+(lowerFlexionData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={lowerFlexion } onChange={()=>{setLowerFlexion(!lowerFlexion)}} disabled={lowerFlexionData.length>0?false:true} /> Lower Back Flexion (Estimate)
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    lowerFlexionData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{lowerFlexionData[GetBestWeek(lowerFlexionData,3)].value.toFixed(0).toString()+"°"}{lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week!==selectedWeek?WeekDecorator(lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week):""}{lowerFlexionData.length>1&&!(lowerFlexionData[0].week===lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week&&lowerFlexionData[0].value===lowerFlexionData[GetBestWeek(lowerFlexionData,3)].value)?changeDecorator(lowerFlexionImp,lowerFlexionData[0].week,true):""}                                    {lowerFlexionData.length>0?IndicatorDecorator(GetIndicator(OutcomeMeasureTypes.LowerFlexion,lowerFlexionData[GetBestWeek(lowerFlexionData,3)].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                       
                        
                        {/** */}


                    </div>
                </div>
                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Behavioral</div>
                                </div>
                            
                        </div>
                        {/** */}
                       
                       
                        
                        
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(confidenceData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={confidence } onChange={()=>{setConfidence(!confidence)}} disabled={confidenceData.length>0?false:true} /> Confidence
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    confidenceData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{confidenceData.slice(-1)[0].value.toString()}{confidenceData.slice(-1)[0].week!==selectedWeek?WeekDecorator(confidenceData.slice(-1)[0].week):""}{confidenceData.length>1?changeDecorator(confidenceImp,confidenceData[0].week):""}{confidenceData.length>0?IndicatorDecorator(ConfidenceIndicator( confidenceData.slice(-1)[0].value)):""}
</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(FABQActivityData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={FABQActivity } onChange={()=>{setFABQActivity(!FABQActivity)}} disabled={FABQActivityData.length>0?false:true} /> FABQ Activity
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.FABQActivity))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    FABQActivityData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{FABQActivityData.slice(-1)[0].value.toString()}{FABQActivityData.slice(-1)[0].week!==selectedWeek?WeekDecorator(FABQActivityData.slice(-1)[0].week):""}{FABQActivityData.length>1?changeDecorator(FABQActivityImp,FABQActivityData[0].week,false,true):""}{FABQActivityData.length>0?IndicatorDecorator(FABQActivityIndicator( FABQActivityData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(FABQWorkData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={FABQWork } onChange={()=>{setFABQWork(!FABQWork)}} disabled={FABQWorkData.length>0?false:true} /> FABQ Work
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.FABQWork))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    FABQWorkData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{FABQWorkData.slice(-1)[0].value.toString()}{FABQWorkData.slice(-1)[0].week!==selectedWeek?WeekDecorator(FABQWorkData.slice(-1)[0].week):""}{FABQWorkData.length>1?changeDecorator(FABQWorkImp,FABQWorkData[0].week,false,true):""}{FABQWorkData.length>0?IndicatorDecorator(FABQWorkIndicator( FABQWorkData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(FABQTotalData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={FABQTotal } onChange={()=>{setFABQTotal(!FABQTotal)}} disabled={FABQTotalData.length>0?false:true} /> FABQ Total
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.FABQTotal))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    FABQTotalData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{FABQTotalData.slice(-1)[0].value.toString()}{FABQTotalData.slice(-1)[0].week!==selectedWeek?WeekDecorator(FABQTotalData.slice(-1)[0].week):""}{FABQTotalData.length>1?changeDecorator(FABQTotalImp,FABQTotalData[0].week,false,true):""}{FABQTotalData.length>0?IndicatorDecorator(FABQTotalIndicator( FABQTotalData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(GAD7Data.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={GAD7 } onChange={()=>{setGAD7(!GAD7)}} disabled={GAD7Data.length>0?false:true} /> GAD-7
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.GAD7))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    GAD7Data.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{GAD7Data.slice(-1)[0].value.toString()}{GAD7Data.slice(-1)[0].week!==selectedWeek?WeekDecorator(GAD7Data.slice(-1)[0].week):""}{GAD7Data.length>1?changeDecorator(GAD7Imp,GAD7Data[0].week,false,true):""}{GAD7Data.length>0?IndicatorDecorator(GAD7Indicator( GAD7Data.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(moodData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={mood } onChange={()=>{setMood(!mood)}} disabled={moodData.length>0?false:true} /> Mood
                                </label>
                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    moodData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{moodData.slice(-1)[0].value.toString()}{moodData.slice(-1)[0].week!==selectedWeek?WeekDecorator(moodData.slice(-1)[0].week):""}{moodData.length>1?changeDecorator(moodImp,moodData[0].week):""}{moodData.length>0?IndicatorDecorator(MoodIndicator( moodData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(PCSData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={PCS } onChange={()=>{setPCS(!PCS)}} disabled={PCSData.length>0?false:true} /> PCS
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.PCS))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    PCSData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{PCSData.slice(-1)[0].value.toString()}{PCSData.slice(-1)[0].week!==selectedWeek?WeekDecorator(PCSData.slice(-1)[0].week):""}{PCSData.length>1?changeDecorator(PCSImp,PCSData[0].week,false,true):""}{PCSData.length>0?IndicatorDecorator(PCSIndicator( PCSData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(PHQ9Data.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={PHQ9 } onChange={()=>{setPHQ9(!PHQ9)}} disabled={PHQ9Data.length>0?false:true} /> PHQ-9
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.PHQ9))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    PHQ9Data.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{PHQ9Data.slice(-1)[0].value.toString()}{PHQ9Data.slice(-1)[0].week!==selectedWeek?WeekDecorator(PHQ9Data.slice(-1)[0].week):""}{PHQ9Data.length>1?changeDecorator(PHQ9Imp,PHQ9Data[0].week,false,true):""}{PHQ9Data.length>0?IndicatorDecorator(PHQ9Indicator( PHQ9Data.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>
                        <div className='w-full inline-flex items-baseline '>
                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] justify-start items-start pb-4 gap-2 inline-flex">
                                <label className={"justify-start items-center flex  gap-x-2  text-base font-normal leading-normal font-['Inter'] " +(PSEQData.length>0?"text-gray-600":"text-gray-400")}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex'
                                                type="checkbox"  checked={PSEQ } onChange={()=>{setPSEQ(!PSEQ)}} disabled={PSEQData.length>0?false:true} /> PSEQ
                                </label>
                                {MetricTooltip(GetTooltipText(OutcomeMeasureTypes.PSEQ))}

                            </div>
                                <div className="w-fit text-base  font-normal text-gray-600 leading-normal font-['Inter'] inline-flex justify-start items-center">
                               
                                {
                                    PSEQData.length>0? <span className="text-base font-normal text-gray-600 leading-normal font-['Inter']">{PSEQData.slice(-1)[0].value.toString()}{PSEQData.slice(-1)[0].week!==selectedWeek?WeekDecorator(PSEQData.slice(-1)[0].week):""}{PSEQData.length>1?changeDecorator(PSEQImp,PSEQData[0].week):""}{PSEQData.length>0? IndicatorDecorator(PSEQIndicator(PSEQData.slice(-1)[0].value)):""}</span>:<span className="gap-x-10 text-base font-normal text-gray-600 leading-normal font-['Inter']">- {NoDataDecorator()}</span>
                                }
                                </div>
                        </div>

                       
                        
                       
                        
                        {/** */}


                    </div>
                </div>
                </SectionDivider>
                <SectionDivider label={"Assessment"}>
                <div className=" pb-2 justify-center items-start gap-3  content-center inline-flex	">
                    <img className="w-[18px] h-[18px] translate-y-0.5 relative bg-gray-50 rounded-full" src="/icons/info-circle.svg" />
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex text-wrap  pb-5	">
                        <div className="self-stretch text-wrap	 text-gray-600 text-base max-[1200px]:text-sm font-normal font-['Inter'] leading-normal">The Assessment section was automatically generated to include statements for improving or declining Outcome Measures that were enabled during report creation. Before approving the report, ensure you are satisfied with the Outcome Measures enabled in the Objective Outcomes section, and be sure to proof-read the Assessment sections to ensure the generated statements remain accurate.</div>
                    </div>
                </div> 
                <div className="  [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6  ">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex ">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                <label className={"justify-start items-center flex text-gray-900 gap-x-2  text-lg font-semibold leading-7 font-['Inter'] "}>
                                    <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                    type="checkbox"  checked={physical } onChange={()=>{setPhysical(!physical)}} /> Physical Assessment
                                </label>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">Write a concise objective summary of improvements in physical function. Refer to Outcome Measures and/or patient reported milestones (ROM, Activity, Disability, Function, Sleep, Pain, Work, Medication.)</div>
                                </div>
                            </div>
                        </div>
                        <textarea className={'w-full resize-none h-[15vh] px-3.5 py-2.5  rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex '+(physical?"bg-white":"bg-gray-200")} disabled={!physical} placeholder="Enter text" onChange={e => setPhysicalText(e.target.value)} value={physicalText}>
    
                        </textarea>
                        <span className='self-stretch flex-col justify-start items-start  flex'>
                        <span className='text-right w-full'>
                        <span className={(physicalText.length<=900?"text-gray-600":"text-error-600")+" font-['Inter']  text-sm float-right "}>{physicalText.length}/ 900</span>
                        </span>
                        {/*<label className={"justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal font-['Inter'] leading-normal "}>
                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox"  checked={revertPhysical }
                             onChange={()=>{setRevertPhysical(!revertPhysical)}} /> Restore Unedited Physical Assessment
                        </label>*/}
                        </span>
                    </div>
                </div>

                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                <label className={"justify-start items-center flex text-gray-900 gap-x-2  text-lg font-semibold leading-7 font-['Inter'] "}>
                                <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                    type="checkbox"  checked={behavioral } onChange={()=>{setBehavioral(!behavioral)}} /> Behavioral Assessment
                                </label>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">Write a concise objective summary of improvements in behavioral and cognitive function. Refer to behavioral Outcome Measures and/or patient-reported milestones (Mood, Confidence, PSEQ, PCS, FABQ, Positive Behaviors, Stress Reduction, Engagement/Education.)</div>
                                </div>
                            </div>
                        </div>
                        <textarea className={'w-full resize-none h-[15vh] px-3.5 py-2.5  rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex '+(behavioral?"bg-white":"bg-gray-200")} disabled={!behavioral} placeholder="Enter text" onChange={e => setBehavioralText(e.target.value)} value={behavioralText}></textarea>
                        <span className='self-stretch flex-col justify-start items-start  flex'>

                        <span className='text-right w-full'>
                        <span className={(behavioralText.length<=900?"text-gray-600":"text-error-600")+" font-['Inter']  text-sm float-right "}>{behavioralText.length}/ 900</span>
                        </span>
                        {/*<label className={"justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal font-['Inter'] leading-normal "}>
                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox"  checked={revertBehavioral }
                             onChange={()=>{setRevertBehavioral(!revertBehavioral)}} /> Restore Unedited Behavioral Assessment
                        </label>*/}
                        </span>

                    </div>
                </div>


                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                <label className={"justify-start items-center flex text-gray-900 gap-x-2  text-lg font-semibold leading-7 font-['Inter'] "}>
                                <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                    type="checkbox"  checked={social } onChange={()=>{setSocial(!social)}} /> Social Assessment
                            </label>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">Write a concise objective summary of improvements in behavioral and cognitive function. Refer to behavioral Outcome Measures and/or patient-reported milestones (Mood, Confidence, PSEQ, PCS, FABQ, Positive Behaviors, Stress Reduction, Engagement/Education.)</div>
                                </div>
                            </div>
                        </div>
                        <textarea className={'w-full resize-none h-[15vh] px-3.5 py-2.5  rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex '+(social?"bg-white":"bg-gray-200")} disabled={!social} placeholder="Enter text" onChange={e => setSocialText(e.target.value)} value={socialText}></textarea>
                        
                        <span className='self-stretch flex-col justify-start items-start  flex'>

                        <span className='text-right w-full'>
                        <span className={(socialText.length<=600?"text-gray-600":"text-error-600")+" font-['Inter']  text-sm float-right "}>{socialText.length}/ 600</span>
                        </span>
                        <label className={"justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal font-['Inter'] leading-normal "}>
                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox"  checked={revertSocial }
                             onChange={()=>{setRevertSocial(!revertSocial)}} /> Restore Unedited Social Assessment
                        </label>
                        </span>


                    </div>
                </div>

                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                <label className={"justify-start items-center flex text-gray-900 gap-x-2  text-lg font-semibold leading-7 font-['Inter'] "}>
                                <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                    type="checkbox"  checked={continuedNeed } onChange={()=>{setContinuedNeed(!continuedNeed)}} /> Continued Need
                            </label>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">Write a concise summary of any aspects of the patient's life which require ongoing focus and attention. Refer to objective evidence such as declining or stagnating Outcome Measures.</div>
                                </div>
                            </div>
                        </div>
                        <textarea className={'w-full resize-none h-[15vh] px-3.5 py-2.5  rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex '+(continuedNeed?"bg-white":"bg-gray-200")} disabled={!continuedNeed} placeholder="Enter text" onChange={e => setContinuedNeedText(e.target.value)} value={continuedNeedText}></textarea>
                        <span className='text-right w-full'>
                        <span className={(continuedNeedText.length<=900?"text-gray-600":"text-error-600")+" font-['Inter']  text-sm float-right "}>{continuedNeedText.length}/ 900</span>
                        </span>
                    </div>
                </div>
                </SectionDivider>

                <SectionDivider label={"Plan"}>
                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Templates</div>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">Choose any pre-written templates first, then edit the final plan below. Modifying the templates will overwrite any custom changes made to the final plan.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full '>
                    <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                        <label className={"justify-start items-center flex text-gray-600 gap-x-2  text-base font-normal leading-normal font-['Inter'] "}>
                                    <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                        type="checkbox"  checked={continueProgram } onChange={()=>{setContinueProgram(!continueProgram)}} /> Continue Program
                        </label>
                    </div>
                </div>
                <div className='w-full pt-2 '>
                    <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                        <label className={"justify-start items-center flex text-gray-600 gap-x-2  text-base font-normal leading-normal font-['Inter'] "}>
                                    <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                        type="checkbox"  checked={planEnd } onChange={()=>{setPlanEnd(!planEnd)}} /> Completed 12-Week Program
                        </label>
                    </div>
                </div>
                <div className='w-full  inline-flex pt-2'>
                    <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                        <label className={"justify-start items-center flex text-gray-600 gap-x-2  text-base font-normal leading-normal font-['Inter'] "}>
                                    <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                        type="checkbox"  checked={notification } onChange={()=>{setNotification(!notification)}} /> Physician Notification
                        </label>
                    </div>
                        <div className='w-fit  '>
                            <CustomDropDown locked={!notification} options={notifications} onchange={UpdateNotificationType} defaultValue={templateNotification} right={false} fitSpace={true} />

                        </div>
                    
                </div>
                <br/>
                <div className='w-full  inline-flex pt-2'>
                    <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                        <label className={"justify-start items-center flex text-gray-600 gap-x-2  text-base font-normal leading-normal font-['Inter'] "}>
                                    <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                        type="checkbox"  checked={extension } onChange={()=>{setExtension(!extension)}} /> Recommend Program Extension
                        </label>
                    </div>
                    <div className='w-fit  '>
                            <CustomDropDown locked={!extension} options={templateWeekOptions} onchange={UpdateTemplateWeekType} defaultValue={templateWeeks} right={false} fitSpace={true} />

                        </div>
                </div>
                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                    <div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">Final Plan</div>
                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal font-['Inter'] leading-tight">The Plan section should provide a concise overview of the recommended next steps in the patient's care. Keep in mind that physicians and payers often refer to this section first for a quick understanding of the care direction.</div>
                                </div>
                            </div>
                        </div>
                        <textarea className='w-full resize-none h-[15vh] px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex' placeholder="Enter text" onChange={e => setPlan(e.target.value)} value={plan}></textarea>
                    </div>
                </div>
                </SectionDivider>

                <div className="self-stretch w-1/2 [@media(min-width:1600px)]:w-[35%] h-11 flex-col justify-start items-start gap-4 flex">
								<button onClick={()=>{HandleUpload();}} className="self-stretch px-4 py-2.5 bg-success-600 hover:bg-success-700 rounded-lg shadow justify-center items-center gap-1.5 inline-flex">
									<div className="px-0.5 justify-center gap-x-1 items-center inline-flex">
                                        <img className="w-5 h-5 pl-[3.33px] justify-center items-center inline-flex" src="/icons/file-check-02.svg" />
										<div className="text-white text-base font-semibold  leading-normal">{"Approve Report"}</div>
									</div>
								</button>
				</div>
                <div className="self-stretch w-1/2 pt-2 [@media(min-width:1600px)]:w-[35%] h-11 flex-col justify-start items-start gap-4 flex">
								<button onClick={()=>{DeleteWarning();}} className="self-stretch px-4 py-2.5 bg-error-600 hover:bg-error-700 rounded-lg shadow justify-center items-center gap-1.5 inline-flex">
									<div className="px-0.5 justify-center gap-x-1 items-center inline-flex">
                                        <img className="w-5 h-5 pl-[3.33px] justify-center items-center inline-flex" src="/icons/delete.svg" />
										<div className="text-white text-base font-semibold  leading-normal">{"Delete Report"}</div>
									</div>
								</button>
				</div>
            </div>
            :
            <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
	<TailSpin	
				height="100"
				width="100"
				color='grey'
				ariaLabel='loading'
			  />
			  <br /> <br />
			  <div/>
		  <h4 className="font-['Inter']">Loading Outcome data...</h4>
	</div>
            }

    </Page>

	);

}



export default withRouter(PatientOutcomeVerificationPage);
