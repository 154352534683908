import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import { TailSpin	 } from  'react-loader-spinner'
import cookie from 'react-cookies';
import moment from 'moment';
import DropdownInput from '../input/DropdownInput';
import { getDurationComplete } from '../../utility/Visit';
import '../../styles/Graphs.scss'
import CustomDropDown from './CustomDropDown';
import { useHistory } from "react-router-dom";



import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { values } from 'lodash';


const VisitsPanel = ( {visits=null, current=0, name=null, rawData=null, patientId}) => {
  const [currentWeek,setCurrentWeek]= useState(current);
  const[weekOptions,setWeekOptions]= useState(null);
  const [dataset, setDataset] = useState([]);
	let history = useHistory();


  useEffect(() => {
  if(dataset.length===0 && rawData &&rawData.length>0)
      {

          CreateSessionData();
      }
    
      
  
});

  useEffect(() => {
    if(visits===null){return;}
    let options=[];
    Object.keys(visits).forEach(e=>{
     options.push({
      id: e,
      name: "Week "+e.toString(),
      value: e      
      })
    })
    if(!Object.keys(visits).includes(currentWeek.toString())){
      options.push(
        {
          id: currentWeek,
          name: "Week "+currentWeek.toString(),
          value: null
        }
        
      )
    }
    options.reverse();
    setWeekOptions(options);
	},[visits]);

  const download = () =>{
    const pdf = new jsPDF();
    autoTable(pdf,{
        head: [['Session','Date','Total Duration','Activities']],
        body:dataset
    })
    pdf.save(`${name}-${formatDate(new Date() )}.pdf`)

   }

 

 function padTo2Digits(num) {
     return num.toString().padStart(2, '0');
   }

 const formatDate = (date) =>{
     return [
     padTo2Digits(date.getMonth() + 1),
       padTo2Digits(date.getDate()),
       date.getFullYear(),
     ].join('/');
   }

   const formatActivities = (activities) =>{
       var acts =``;
       activities.length>0 &&activities.map((element)=>(
                 
         acts+=`•${element.name} (${element.time})\n`

     ))

     return acts
   }
   const camelCaseParse=(text)=>{
       if(text.includes(" ")){
           return "  "+text;
       }
       else{
     return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
       }
   }


 const CreateSessionData = () => {
     let datasetHolder = [];
 let filteredData =  [...rawData];
 let today = new Date();
     let timeKey="start_time"

     var index=0;
 for (var i = rawData.length; i >= 0 ; i--) {
         var acts=[]
   let date = new Date();
   date.setDate(today.getDate() - i);

   let dataPerDate = filteredData.filter(data => new Date(data[timeKey]).getDate() == date.getDate()
     && new Date(data[timeKey]).getMonth() == date.getMonth());


       let timeSpent=0
     dataPerDate.forEach(element => {
                 var time=getDurationComplete(element)
       timeSpent+= time;
                 var timeString= time> 0 ? `${moment.duration(time).minutes()?moment.duration(time).minutes()+" minutes ":""}${moment.duration(time).seconds()>0?moment.duration(time).seconds()+" seconds":""}` : `There is no duration data for this exercise.`;
                 acts.push({name: camelCaseParse( element.activity_name), time: timeString })
     });
     let dataPoint = [
                  0,
       formatDate(date),
                 timeSpent> 0 ? `${moment.duration(timeSpent).hours() > 0 ? (moment.duration(timeSpent).hours()+ " hours and ") : ""}${moment.duration(timeSpent).minutes()?moment.duration(timeSpent).minutes()+" minutes ":""}${moment.duration(timeSpent).seconds()>0?moment.duration(timeSpent).seconds()+" seconds":""}` : `There is no duration data for this session.`,
                 formatActivities(acts), 
                 new moment(date)
             ]
             if(dataPoint[3]!==""){
                 dataPoint[0]=++index
     datasetHolder.push(dataPoint);
             }

   
   

 }

 if(datasetHolder.length > 0){
   setDataset(datasetHolder);
 }
   
}

const GetBadgeImage=(badge)=>{
  if(badge===undefined){return "/icons/badges/Session_None.png"}

  if(badge.includes("1x")){return "/icons/badges/Session_Bronze.png"}
  if(badge.includes("2x")){return "/icons/badges/Session_Silver.png"}
  if(badge.includes("3x")){return "/icons/badges/Session_Gold.png"}


}

  const RenderTable=(week)=>{

    var table=[];
    Object.keys(visits[week]).forEach(e=>{
      console.log(visits[week][e])
      visits[week][e].time=visits[week][e].motion_time
      
      table.push(
        <React.Fragment>
        <tr className=' border-y-2 border-x-2	  even:bg-white odd:bg-white hover:bg-gray-100 hover:cursor-pointer' onClick={()=>{history.push('/patient/'+patientId+'/day/'+moment(e).format("MM-DD-YYYY"))}} >
        <td className="	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{ moment(e).format("MMMM Do YYYY")}</td>
        <td className="	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{visits[week][e].activity_completions!==undefined?visits[week][e].activity_completions:0 }</td>
        <td className="	 border-x-0  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{visits[week][e].time>60?((moment.duration(visits[week][e].time*1000).hours()>0? moment.duration(visits[week][e].time*1000).hours()+"h ":"")+( moment.duration(visits[week][e].time*1000).minutes()>0? moment.duration(visits[week][e].time*1000).minutes()+"m ":"")):moment.duration(visits[week][e].time*1000).seconds()+"s" }</td>
        <td className="	 border-x-0  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "><img className='w-[30px] h-[37px]"' src={GetBadgeImage(visits[week][e].session_badge)}/></td>

      </tr>
      </React.Fragment>
      )
    })

    return table;
  }

  return (
    <React.Fragment>
      


    { visits===null||name===null||rawData===null?
        (<div className=" w-full h-full justify-start items-center inline-flex flex-col 	">
        <TailSpin	
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                  />
                  <br /> <br />
                  <div/>
              <h4 className="font-['Inter']">Loading...</h4>
        </div>):(
        Object.keys( visits).length>0?(
            <div className=" w-full h-[90%] justify-start items-center inline-flex flex-col 	">
              <div className="w-full h-[10%]   gap-5 inline-flex justify-start items-center ">
                    <div className="grow shrink basis-0 text-gray-900 text-xl font-semibold font-['Inter'] ml-[26px]">Session History</div>
                    
                    <div className=" flex-col justify-start items-start inline-flex mr-[26px]">
                      {
                        weekOptions!==null&&<CustomDropDown options={weekOptions} onchange={setCurrentWeek}  />
                      }

                    
                    </div>
              </div>
              <div className="w-[calc(100%-48px)]  grid-col   gap-5 justify-start  items-center overflow-y-auto mt-5  ">
          
              {visits[currentWeek]!==undefined&&Object.keys( visits[currentWeek]).length>0 ?<table className='w-full h-[100%] divide-gray-700 dark:divide-gray-700 rounded-[10px]'>
                    <thead className='	whitespace-nowrap w-full border-x-2'>
                            <tr className='shadow-[inset_0_1px_0_#d3d3d3,inset_0_-1px_0_#d3d3d3] bg-gray-50 whitespace-nowrap	sticky top-0 z-[2]'>
                            <th  scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-l-2	border-r-0  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">Session Date</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Activities Completed
                              <div class=' align-text-bottom	has-tooltip float-bottom text-center	content-center	items-center	 pl-2 object-bottom whitespace-nowrap  inline-flex 	'>
          <span class='tooltip rounded shadow-lg p-1 mt-12 -ml-72'><p>Activities completed in this session in</p><p>the selected coaching plan week.</p><p>Sessions may contain more activities</p><p>completed on different coaching plan weeks.</p></span><img className='h-4 w-4 hs-tooltip ' src ={"/icons/help-circle.svg"} ></img>
        </div>
        </th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Motion Time</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Session Badge</th>

                            </tr>
                        </thead>
                        <tbody className=' whitespace-nowrap'>
                            <React.Fragment>
                        
                           {visits[currentWeek]!==undefined&& RenderTable(currentWeek)}
                         
                        
                        </React.Fragment>
                        </tbody>
              </table >:<div className=" w-full justify-center items-center h-full content-center pt-5	 text-center	inline-flex flex-col align-middle	"> No sessions in current week</div>}
                    
              </div>

               
                
            </div>
          ):(<div className=" w-full h-[90%] justify-start items-center inline-flex flex-col 	"> No Data Found</div>)
        )
    }
    </React.Fragment>
  )
}

export default VisitsPanel